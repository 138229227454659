import React from 'react';
import TooltipMaterial from '@mui/material/Tooltip'

const Tooltip = (props) => {
    const {children, ...other} = props
    return (
        <TooltipMaterial {...other}>
            {children}
        </TooltipMaterial>
    );
};

export default Tooltip;