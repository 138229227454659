import React from 'react';
import PropTypes from 'prop-types';
import EmployeeRedactor from "../EmployeeRedactor";
import Box from "../../../../element/Box";
import Divider from "@mui/material/Divider";

const ViewEmployees = props => {
    const {data = []} = props

    return (
        <Box sx={{
            display:"flex",
            flexDirection:"column",
            gap:"1rem",
            margin:"0.5rem",
        }}>
            {data && data.map((employee, index) => {
                return <>
                    <EmployeeRedactor key={employee.id} employee={employee}/>
                    <Divider/>
                </>
            })}
        </Box>
    );
};

ViewEmployees.propTypes = {
    data:PropTypes.array
};

export default ViewEmployees;