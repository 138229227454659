import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import Table from "../../../../../elements/Table";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Typography from "../../../../../element/Typography";
import ClientOrderTotalMoney from "../../ClientOrderTotalMoney";

const ClientOrderActionByOrderAndType = props => {
    const {actions, onChange} = props
    const [checkedAddPercentPrice, setCheckedAddPercentPrice] = React.useState(false);
    const [isContainsAddPercentPrice, setIsContainsAddPercentPrice] = useState(false)

    const handleChangeAddPercentPrice = (event) => {
        setCheckedAddPercentPrice(event.target.checked);
    };

    useEffect(() => {
        setIsContainsAddPercentPrice(actions.findIndex(action=>action.addPercentPrice && action.addPercentPrice !== 0) > -1)
    }, [actions])

    return (
        <div>
            <div>
                {actions && actions.length > 0 && actions[0].type ? <Typography variant={"h5"}>{actions[0].type}</Typography> : null}
                <FormControlLabel control={<Checkbox value={checkedAddPercentPrice} onChange={handleChangeAddPercentPrice} />} label={"Добавить наценку"} />
            </div>
            <Table
                header={{
                    cells:[
                        {
                            flexGrow:"2",
                            value:"Наименование",
                            name:"name",
                        },

                        {
                            flexGrow:"2",
                            value:"Количество",
                            name:"count",
                            type:""
                        },
                        {
                            flexGrow:"2",
                            value:"Цена",
                            name:"price",
                        },
                        ...checkedAddPercentPrice ? [{
                            flexGrow:"2",
                            value:"Наценка в %",
                            name:"addPercentPrice",
                            type:"Percent",
                            step:5
                        }] : isContainsAddPercentPrice ? [{
                            flexGrow:"2",
                            value:"Наценка в %",
                            name:"addPercentPrice",
                            format:"${value}%",
                        }] : [],
                        {
                            flexGrow:"1",
                            value:"Сумма",
                            name:"sum"
                        },
                        {
                            flexGrow:"1.5",
                            value:"Изменен",
                            name:"",
                            type:"ChangeDateChip",
                            format:"HH:mm:ss DD.MM.y"
                        },
                    ]
                }}
                data={actions}
                componentProps={{
                    isFullScreen:false
                }}
                onChange={(indexRow, nameProperty, value) => {
                    onChange && (onChange({...actions[indexRow], [nameProperty]: value}))
                }}
                />
                <ClientOrderTotalMoney actions={actions}/>
        </div>
    );
};

ClientOrderActionByOrderAndType.propTypes = {
    actions: PropTypes.array
};

export default ClientOrderActionByOrderAndType;