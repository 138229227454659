import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import Box from "../../../element/Box";
import {getAllRootCategory} from "../../../Rest/RestFunction";
import ShoppingCartViewCategory from "./ShoppingCartViewCategory";
import ListItem from "../../../element/List/ListItem";

const ShoppingCart = props => {
    const {sx = {}, transportId, onChangeShopData, clientOrderActionList, renderItem} = props
    const [categoryService, setCategoryService] = useState()

    useEffect(() => {
        init()
    }, [])

    const init = async () => {
        let responseCategoryService = await getAllRootCategory("service")
        setCategoryService(responseCategoryService)
    }

    return (
        <Box sx={{...sx}}>
            {categoryService && categoryService.map(category => {
                return <ShoppingCartViewCategory
                    renderItem={renderItem}
                    category={category}
                    transportId={transportId}
                    onChangeShopData={onChangeShopData}
                    clientOrderActionList={clientOrderActionList}
                />
            })}

        </Box>
    );
};

ShoppingCart.propTypes = {
    transportId:PropTypes.string,
};

export default ShoppingCart;