import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from "react-redux";
import {store} from "./store/configureStore";
import Main from './components/Main';
import * as serviceWorker from './serviceWorker';
import { CookiesProvider } from 'react-cookie';
import './index.css';
import {BrowserRouter} from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <CookiesProvider>
        <BrowserRouter>
            <Provider store={store}>
                <Main />
            </Provider>
        </BrowserRouter>
    </CookiesProvider>
);

serviceWorker.unregister();
