import React, {useEffect, useState} from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import _ from "lodash";
import {Typography} from "@mui/material";
import Box from "@material-ui/core/Box";

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
        fontSize: 16,
    },
    body: {
        fontSize: 14,
        color: theme.palette.common.white,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);

const useStyles = makeStyles({
    table: {
        minWidth: 400,
        width:935
    },
});

export default function TableForPrimenimost(props) {
    const classes = useStyles();
    const rows = props.children.items

    const [renderColumn, setRenderColumn] = useState({})

    useEffect(() => {
        let {items = []} = props.children
        let newRenderColumn = {}
        items && items.forEach(item => {
            for (let nameParam in item) {
                let param = item[nameParam]
                if (!_.isEmpty(param)) {
                    newRenderColumn[nameParam] = true
                }
            }
        })
        setRenderColumn(newRenderColumn)
    }, [props.children.items])

    return (
        <TableContainer component={Paper}>
            {rows && rows.length > 0 ?
            <Table className={classes.table} aria-label="customized table">
                <TableHead>
                    <TableRow>
                        <StyledTableCell align="center">Модель</StyledTableCell>
                        {renderColumn["description"] === true ? <StyledTableCell align="center">Описание</StyledTableCell> : null}
                        <StyledTableCell align="center">Период выпуска</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows && rows.map((row) => (
                        <StyledTableRow key={row.name}>

                            <StyledTableCell align="center">{props.children.parentName} {row.model} {row.name}</StyledTableCell>
                            {renderColumn["description"] === true ? <StyledTableCell align="center">{row.description}</StyledTableCell> : null}
                            <StyledTableCell align="center">{row.period}</StyledTableCell>
                        </StyledTableRow>
                    ))}
                </TableBody>
            </Table> : <Box sx={{
                    display:"flex",
                    justifyContent:"center",
                    padding:"1rem"
                }}>
                    <Typography variant={"h5"}>
                        Все модели
                    </Typography>
                </Box>}
        </TableContainer>
    );
}