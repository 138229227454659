import * as types from "./actionTypes";

const initState = {
    signalMap:{

    }
};

export default function reduce(state = initState, action = {}) {

    switch (action.type) {
        case types.ADD_SIGNAL:
            return {
                ...state,
                signalMap: {
                    ...state.signalMap,
                    [action.id] : {
                        time: Date.now()
                    }
                }
            };
        default:
            return state;
    }
}