
import React from 'react';
import Button from '@material-ui/core/Button';
import {Link} from "react-router-dom";
import './style.css';
import {useDispatch} from "react-redux";
import * as PageActions from "../../store/page/action";


export default function ElemMenu(props) {
    const {mobile,  //true/false елефонный стиль
        link,
        name} = props
    const dispatch = useDispatch()

    const changePage = (pageName) => () => {
        dispatch(PageActions.changePage(pageName))
    }

    return (
        <div className={mobile ? "styleButtonElemMenuMobile" : "styleButtonElemMenu"}>
            <Link to={link} onClick={changePage(link)} >
                <Button
                    aria-controls="customized-menu"
                    aria-haspopup="true"
                    variant="contained"
                    style={{padding: "8px",fontSize:"inherit"}}
                >
                    {name}
                </Button>
            </Link>
        </div>
    );
}