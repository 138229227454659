import React, {useEffect, useState} from 'react';
import ViewClient from "./ViewClient";
import ClientPanel from "./ClientPanel";
import Divider from "@mui/material/Divider";
import {searchClient} from "../../../Rest/RestFunction";
import {useSelector} from "react-redux";
import Pagination from "../../../elements/Pagination";

const Clients = (props) => {
    const user = useSelector((state) => state.auth.user);

    const [clientParams, setClientParams] = useState({})
    const [page, setPage] = useState({})
    const [pagination, setPagination] = useState({
        size:50,
        page:0
    })

    useEffect(() => {
        search()
    }, [pagination])

    useEffect(() => {
        search()
    }, [])

    const search = async () => {
        let response = await searchClient({
            tokenAuth: user.tokenAuth,
            searchParams : {
                page:pagination.page,
                size:pagination.size
            }
        })
        if (response) {
            if (response.page) {
                setPage(response.page)
            }
        }
    }

    return (
        <div>
            <ClientPanel
                params = {clientParams}
                setParams = {setClientParams}
            />
            <Divider/>
            <ViewClient
                data={page.content}
            />
            <Pagination
                size={pagination.size}
                page={pagination.page}
                setPagination={setPagination}
                countElements={page.totalElements}
            />
        </div>
    );
};

export default Clients;