import * as types from "./actionTypes";

const initState = {
    site: {
        update: false,
        dragOnDrop: false,
        showUpdateElements: false,
    },
    product: {
        showUpdateElements: false
    },
    catalog: {
        showUpdateElements: false
    }
};

export default function reduce(state = initState, action = {}) {
    switch (action.type) {
        case types.CHANGE_CONTROL_PANEL_SITE:
            return {
                ...state,
                site: {
                    ...action.site
                }
            };
        case types.CHANGE_CONTROL_PANEL_PRODUCT:
            return {
                ...state,
                product: {
                    ...action.product
                }
            };
        case types.CHANGE_CONTROL_PANEL_CATALOG:
            return {
                ...state,
                catalog: {
                    ...action.catalog
                }
            };
        default:
            return state;
    }
}

export {};