import React, {useEffect, useState} from 'react';
import Box from "../Box";
import "./style.css"

const BoxEasyOpen = (props) => {
    const {open, children, ...other} = props
    const [status, setStatus] = useState()
    const [openBox, setOpenBox] = useState(false)
    useEffect(() => {
        if (open) {
            setStatus("init_open")
            setOpenBox(true)
        } else {
            setStatus("init_close")
            setOpenBox(false)
        }
    }, [])

    useEffect(() => {
        if (openBox === true && open === false) {
            setStatus("close")
        }
        if (open === true && openBox === false) {
            setStatus("open")
        }
        setOpenBox(open)
    }, [open])

    return (
        <Box
            className={status ? `box_easy_open__${status}` : open === true ? "box_easy_open__init_open" : "box_easy_open__init_close"}
        >
            <Box {...other}>
                {children}
            </Box>
        </Box>
    );
};

export default BoxEasyOpen;