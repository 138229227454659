
import React, { useState, useEffect } from 'react';

export function useAccessUser(access, user) {
    const [isAccess, setIsAccess] = useState(false);

    useEffect(() => {
        let find = false;
        if (user && user.accesses) {
            if (user.accesses instanceof Array) {
                if (user.accesses.indexOf(access) >= 0) {
                    find = true
                }
            }
        }
        if (isAccess !== find) {
            setIsAccess(find)
        }
    }, [user]);

    return isAccess;
}
  