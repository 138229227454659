import React, {useEffect, useState} from 'react';
import {getCategoryById, searchProduct, updateAllProduct} from "../../../../Rest/RestFunction";
import useStatePagination from "../../../../hooks/useStatePagination";
import Table from "../../../../elements/Table";
import Modal from "../../../../element/Modal";
import _ from "lodash"
import {useSelector} from "react-redux";
import Switch from "../../../../element/Switch";

const ProductViewTablePriceForClass = (props) => {
    const {categoryId} = props
    const [category, setCategory] = useState()
    const [paginationProduct, setPaginationProduct] = useState({size: 1000, page: 0})
    const [productPage, setProductPage] = useState()
    const [arrayProductWithClass, setArrayProductWithClass] = useState()
    const [headerList, setHeaderList] = useState([])
    const user = useSelector((state) => state.auth.user);
    const [dragOnDrop, setDragOnDrop] = useState(false)
    const [open, setOpen] = useState(false)

    useEffect(() => {
        if (open) {
            init()
        }
    }, [open])

    const init = async () => {
        const [categoryResponse, productResponse] = await Promise.all([
            getCategoryById(categoryId),
            searchProduct({
                categoryId: categoryId,
                page: paginationProduct.page,
                size: paginationProduct.size,
                sorts:[
                    {
                        typeSort:"ASC",
                        name:"index"
                    }
                ]
            })])
        setCategory(categoryResponse)
        setProductPage(productResponse)

        const arrayResult = [...productResponse.content]
        arrayResult.map(product => {
            if (!product['priceMap']) {
                product['priceMap'] = {}
            }
            if (categoryResponse.classTransportList) {
                categoryResponse.classTransportList.forEach(classTransport => {
                    if (!product.priceMap[classTransport.id]) {
                        product.priceMap[classTransport.id] = {}
                    }
                    product.priceMap[classTransport.id]["name"] = `${classTransport.name}`
                    // product.priceMap[classTransport.id]["nameByClient"] = classTransport.nameByClient
                })
            }
        })
        setArrayProductWithClass(arrayResult)

        const header = [{
            flexGrow:"2",
            value:"Наименование",
            name:"name"
        }]

        if (categoryResponse.classTransportList) {
            categoryResponse.classTransportList.forEach(classTransport => {
                header.push({
                    flexGrow: "1",
                    fullValue:`${classTransport.group} -> ${classTransport.name}`,
                    value:`${classTransport.name}`,
                    type:"TextField",
                    name:`priceMap.${classTransport.id}.priceCurrency`
                })
            })
        }

        setHeaderList(header)
    }

    const onChangeProductArray = (indexRow, name, newValue) => {
        const newArray = [...arrayProductWithClass]
        _.set(newArray[indexRow], name, newValue)
        setArrayProductWithClass(newArray)
    }

    const saveProductList = async () => {
        await updateAllProduct(arrayProductWithClass, user.tokenAuth)
    }

    return (
        <Modal
            label={"Цены по классам"}
            fullScreen={true}
            onSubmit={saveProductList}
            open={open}
            setOpen={setOpen}
        >
            <Switch label={"Перемещение"} value={dragOnDrop} onChange={(e) => setDragOnDrop(e.target.checked)}/>
            <Table
                header={{
                    cells:headerList
                }}
                data={arrayProductWithClass}
                onChange={onChangeProductArray}
                onChangeData={(newData) => setArrayProductWithClass(newData)}
                dragOnDrop={dragOnDrop}
            />

        </Modal>
    );
};

export default ProductViewTablePriceForClass;