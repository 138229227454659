import React, {Fragment} from 'react';
import {getTextTransport} from "../../../../helpers/TransportHelper";

const TransportChip = (props) => {
    const {transport} = props

    return (
        <Fragment>
            {getTextTransport(transport)}
        </Fragment>
    );
};

export default TransportChip;