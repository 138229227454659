import React from 'react';
import PropTypes from 'prop-types';
import ButtonSub from "../../Button/ButtonSub";
import TextField from "../index";
import ButtonPlus from "../../Button/ButtonPlus";
import Box from "../../Box";

const TextFieldPercent = props => {
    const {
        min = -100,
        sx = {},
        value,
        onChange,
        isRenderButtonSub = (value) => true,
        isRenderTextField = (value) => true,
        step = 5,
        ...other
    } = props
    return (
        <Box
            sx={{
                display: "flex",
                alignItems: "center",
            }}
        >
            {isRenderButtonSub(value) ? <ButtonSub
                size={"medium"}
                onClick={(e) => {
                    let res = Number.parseFloat(value) - step
                    if (res >= min) {
                        onChange(Number.parseFloat(value) - step)
                    }
                }}
            /> : null}
            {isRenderTextField(value) ? <TextField
                value={value}
                onChange={(e) => {
                    let res = Number.parseFloat(e.target.value)
                    if (Number.isNaN(res)) {
                        res = 0
                    }
                    if (res >= min) {
                        onChange && onChange(res)
                    }
                }}
                sx={{
                    width: "100px",
                    ...sx
                }}
                {...other}
            /> : null
            }
            <ButtonPlus
                size={"medium"}
                onClick={(e) => onChange(Number.parseFloat(value) + step)}
            />
        </Box>
    );
};

TextFieldPercent.propTypes = {
    min: PropTypes.number,
    value: PropTypes.object,
    onChange: PropTypes.func
};

export default TextFieldPercent;