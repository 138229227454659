import React from 'react';
import PropTypes from 'prop-types';
import Box from "../../element/Box";
import TypographyDate from "../../elements/TypographyDate";
import Typography from "../../element/Typography";
import Divider from "@mui/material/Divider";

const ListTransaction = props => {
    const {list = []} = props
    return (
        <Box sx={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem"
        }}>
            {list.map(elem => {
                return (
                    <>
                    <Box
                    key={elem.id}
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        gap: "1rem"
                    }}
                >
                    <TypographyDate
                        sx={{
                            width: "150px"
                        }}
                        value={elem.createDate}/>
                    <Typography sx={{
                        width: "150px"
                    }}>
                        {`Из ${elem.typeFrom}`}
                    </Typography>
                    <Typography sx={{
                        width: "150px"
                    }}>
                        {`В ${elem.typeTo}`}
                    </Typography>
                    <Typography sx={{
                        width: "200px"
                    }}>
                        {`${elem.money} Р`}
                    </Typography>
                </Box>
                        <Divider/>
                    </>
                    )
            })}
        </Box>
    );
};

ListTransaction.propTypes = {
    list:PropTypes.array
};

export default ListTransaction;