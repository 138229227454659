import React, {Fragment, useState} from 'react';
import './style.css'
import {getCatalogsListByIds, getCategoryById, getFile} from "../../../../Rest/RestFunction";
import Typography from "@mui/material/Typography";
import GridImage from "../../../../elements/GridImage";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import {withStyles} from "@mui/styles";
import MuiDialogContent from "@mui/material/DialogContent";
import SwipeableViews from 'react-swipeable-views';
import makeStyles from "@mui/styles/makeStyles";
import * as PropTypes from "expect";
import Box from "@mui/material/Box";
import TableForPrimenimost from "../../../TableForPrimenimost";

const TabStyled = withStyles((theme) => ({
    root: {
        textTransform: "none",
        fontSize: "1.275rem",

    },
}))(Tab);

function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3} style={{padding: "0"}}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        width: 500,
    },
}));

export default function ProductModalView(props) {

    const product = props.product

    const [tab, setTab] = useState()
    const [value, setValue] = React.useState(0);
    const [category, setCategory] = useState()
    const [productPrimenimostCatalogList, setProductPrimenimostCatalogList] = useState()
    //Список родителей, например: Mazda, BMW
    const [parentPrimenimostCatalogList, setParentPrimenimostCatalogList] = useState()

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const initCategory = async (categoryId) => {
        if (categoryId) {
            let cat = await getCategoryById(categoryId)
            setCategory(cat)
        } else {
            setCategory(null)
        }
    }

    const initProductPrimenimostCatalogList = async (catalogList) => {
        if (catalogList) {
            let fullCatalogList = await getCatalogsListByIds(catalogList)

            let parentCatalogs = []
            fullCatalogList.forEach((catalog) => {
                let indexParent = -1
                parentCatalogs.forEach((parent, index) => {
                    if (parent.id === catalog.parentId) {
                        indexParent = index
                    }
                })
                if (indexParent !== -1) {
                    parentCatalogs[indexParent].children.push(catalog)
                } else {
                    parentCatalogs.push({
                        id: catalog.parentId,
                        name: catalog.parentName,
                        children: [{...catalog}]
                    })
                }
            })
            setParentPrimenimostCatalogList(parentCatalogs)


            setProductPrimenimostCatalogList(fullCatalogList)
        }
    }

    React.useEffect(() => {
        initCategory(product.categoryId)
        initProductPrimenimostCatalogList(product.catalogList)
    }, []);

    const renderParam = (name, value) => {
        return (
            <div style={{display: "flex"}}>
                <div className={"product_modal_view_param_element_name"}>
                    <article>{name}</article>
                    <div className={"product_modal_view_param_element_dots"}></div>
                </div>
                <article>{value}</article>
            </div>
        )
    }

    const renderDopParams = (product) => {
        if (product.dopParams) {
            return category && category.dopParams && category.dopParams.map((param) => {
                if (product.dopParams[param.name]) {
                    return renderParam(param.displayName ? param.displayName : param.name, product.dopParams[param.name])
                }
            })
        }
    }

    const [valueParent, setValueParent] = React.useState(0);
    const handleChangeParent = (event, newValue) => {
        setValueParent(newValue);
        setValueChildren(0)
    };

    const renderPrimenimost = () => {
        return parentPrimenimostCatalogList && parentPrimenimostCatalogList.length > 1 ?
            <article>
                <Tabs
                    value={valueParent}
                    indicatorColor=""
                    textColor="primary"
                    onChange={handleChangeParent}
                >
                    {
                        parentPrimenimostCatalogList && parentPrimenimostCatalogList.map((parent, index) => {
                            return <TabStyled style={{minWidth: "fit-content", marginRight: "20px", padding: "0"}}
                                              className={`${valueParent === index && "selected_Elem"}`}
                                              label={parent.name}
                                              {...a11yProps(index)}
                            />
                        })
                    }
                </Tabs>
                <SwipeableViews
                    index={valueParent}
                >
                    {
                        parentPrimenimostCatalogList && parentPrimenimostCatalogList.map((parent, index) => {
                            return <TabPanel value={valueParent} index={index}>
                                {renderPrimenimostChildren(parent)}
                            </TabPanel>
                        })
                    }

                </SwipeableViews>

            </article> :
            // показывать если только один родитель
            parentPrimenimostCatalogList && parentPrimenimostCatalogList.map((parent, index) => {
                return renderPrimenimostChildren(parent)
            })

    }

    const [valueChildren, setValueChildren] = React.useState(0);
    const handleChangeChildren = (event, newValue) => {
        setValueChildren(newValue);
    };

    const renderPrimenimostChildren = (parent) => {
        return <Fragment >
            <Tabs
                value={valueChildren}
                indicatorColor=""
                textColor="primary"
                onChange={handleChangeChildren}
            >
                {
                    parent && parent.children && parent.children.map((child, index) => {
                        let plusNameParentName = child.parentName + " " + child.name
                        return <TabStyled style={{minWidth: "fit-content", marginRight: "20px", padding: "0",fontSize: "15px"}}
                                          className={`${valueChildren === index && "selected_Elem"}`}
                                          label={plusNameParentName}
                                          {...a11yProps(index)}
                        />
                    })
                }
            </Tabs>
            <SwipeableViews className="body_Style_Param"
                            index={valueChildren}
            >
                {
                    parent && parent.children && parent.children.map((children, index) => {
                        return <TabPanel value={valueChildren} index={index}>

                            <div className={"product_modal_view__table"} style={{paddingLeft:"0px"}}>

                                    <TableForPrimenimost children={children}/>

                            </div>


                        </TabPanel>
                    })
                }

            </SwipeableViews>
        </Fragment>
    }


    return (
        <div className={"product_modal_view"}>
            <div>
                <article style={{marginBottom: "25px"}}>
                    <h3> {product.brand} {product.article}</h3>
                    <h5>{product.name}</h5>
                </article>
                <GridImage images={product.images}/>
            </div>

            <div  style={{marginTop: "30px"}}>
                <article style={{backgroundColor:"rgba(30,30,30,0.95)",borderRadius:"5px",padding:"5px"}}>
                <Tabs
                    value={value}
                    indicatorColor=""
                    textColor="primary"
                    onChange={handleChange}
                >

                    <TabStyled style={{minWidth: "fit-content", marginRight: "20px", padding: "0"}}
                               className={`${value === 0 && "selected_Elem"}`} label="Параметры"  {...a11yProps(0)}/>
                    <TabStyled style={{minWidth: "fit-content", padding: "0"}}
                               className={`${value === 1 && "selected_Elem"}`} label="Применимость"  {...a11yProps(1)}/>
                </Tabs>
                </article>
                <article style={{backgroundColor:"rgba(30,30,30,0.95)",borderRadius:"5px",padding:"5px",marginTop:"10px",minHeight:"310px"}} >
                <SwipeableViews
                                index={value}
                >
                    <TabPanel className="body_Style_Param" value={value} index={0}>
                        {product.country && renderParam("Производитель", product.brand)}
                        {product.description && renderParam("Описание", product.description)}
                        {product.country && renderParam("Страна производства", product.country)}
                        {renderDopParams(product)}
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        {renderPrimenimost()}
                    </TabPanel>
                </SwipeableViews>
                </article>
            </div>

            <div>
                {tab}
            </div>

        </div>
    );
}