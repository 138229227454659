import * as React from 'react';
import TablePagination from '@mui/material/TablePagination';
import "./style.css"

export default function Pagination(props) {
    const {page, size, countElements, setPagination} = props

    const handleChangePage = (event, newPage) => {
        setPagination({
            page:newPage,
            size:size
        })
    };

    const handleChangeRowsPerPage = (event) => {
        setPagination({
            size:parseInt(event.target.value, 10),
            page:0
        })
    };

    const defaultLabelDisplayedRows = ({ from, to, count }) => {
        return `${from}–${to} из ${count !== -1 ? count : `больше чем ${to}`}`;
    }

    return (
        <TablePagination
            component="div"
            className={"material_pagination"}
            count={countElements}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={size}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage={'Количество записей на странице:'}
            labelDisplayedRows={defaultLabelDisplayedRows}
        />
    );
}