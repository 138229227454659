import React, {Fragment, lazy, Suspense} from "react";
import {Route, Routes} from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import Box from "@material-ui/core/Box";
import NavigationMenu from "./NavigationMenu";
import "./style.css"
import CurrencyRedactor from "../PrivateOffice/CurrencyRedactor";
import ClientOrders from "../PrivateOffice/ClientOrders";
import Clients from "../PrivateOffice/Clients";
import {useSelector} from "react-redux";
import {useAccessUser} from "../../service/employees/useAccessUser";
import {
    ADD_EMPLOYEE,
    GET_ALL_CLIENT,
    GET_ALL_CLIENT_ORDER,
    GET_MY_CLIENT_ORDER,
    GET_SMALL_ALL_CLIENT_ORDER, UPDATE_CLIENT, UPDATE_CLIENT_ORDER,
    UPDATE_CURRENCY
} from "../../constants/accesses";
import ClientOrderRedactor from "../PrivateOffice/ClientOrders/ClientOrderRedactor";
import ClientOrderRedactorWithPathId
    from "../PrivateOffice/ClientOrders/ClientOrderRedactor/ClientOrderRedactorWithPathId";
import ClientRedactor from "../PrivateOffice/Clients/ClientRedactor";
import ClientRedactorWithPathId from "../PrivateOffice/Clients/ClientRedactor/ClientRedactorWithPathId";
import ClientWithPathId from "../PrivateOffice/Clients/Client/ClientWithPathId";
import ClientOrderWithPathId from "../PrivateOffice/ClientOrders/ClientOrder/ClientOrderWithPathId";
import CatalogMerchandise from "../PrivateOffice/CatalogMerchandise";
import CatalogService from "../PrivateOffice/CatalogService";
import CatalogClassTransport from "../PrivateOffice/CatalogClassTransport";
import Employee from "../PrivateOffice/Employee";

const PrivateOffice = lazy(() => import("../PrivateOffice"))

export default function PrivateOfficeRouter(props) {

    const user = useSelector((state) => state.auth.user);
    const isUpdateCurrency = useAccessUser(UPDATE_CURRENCY, user);

    const isGetAllClientOrder = useAccessUser(GET_ALL_CLIENT_ORDER, user)
    const isGetSmallAllClientOrder = useAccessUser(GET_SMALL_ALL_CLIENT_ORDER, user)
    const isGetMyClientOrder = useAccessUser(GET_MY_CLIENT_ORDER, user)

    const isUpdateClientOrder = useAccessUser(UPDATE_CLIENT_ORDER, user);
    const isUpdateClient = useAccessUser(UPDATE_CLIENT, user)

    const isGetAllClient = useAccessUser(GET_ALL_CLIENT, user)

    const isAddEmployee = useAccessUser(ADD_EMPLOYEE, user);

    return <Fragment>
            <div className='white-fon'></div>

            <NavigationMenu>
                <Box className='office_wrapper'
                >
                    <Suspense fallback={<div style={{margin: "100px auto 100px auto"}}><CircularProgress/></div>}>
                        <Routes>
                            <Route exact path="" element={<PrivateOffice/>}/>
                            {isUpdateCurrency && <Route exact path="/currency" element={<CurrencyRedactor/>}/>}
                            {(isGetAllClientOrder || isGetSmallAllClientOrder || isGetMyClientOrder) &&
                            <Route exact path="/clientOrders" element={<ClientOrders/>}/>}
                            {(isGetAllClientOrder || isGetSmallAllClientOrder || isGetMyClientOrder) &&
                            <Route exact path="/clientOrder/:id" element={<ClientOrderWithPathId/>}/>}
                            {isUpdateClientOrder &&
                            <Route exact path="/clientOrders/add" element={<ClientOrderRedactor/>}/>}
                            {isUpdateClientOrder &&
                            <Route exact path="/clientOrder/redactor/:id" element={<ClientOrderRedactorWithPathId/>}/>}
                            {isGetAllClient && <Route exact path="/clients" element={<Clients/>}/>}
                            {isGetAllClient && <Route exact path="/client/:id" element={<ClientWithPathId/>}/>}
                            {isUpdateClient && <Route exact path="/client/add" element={<ClientRedactor/>}/>}
                            {isUpdateClient &&
                            <Route exact path="/client/redactor/:id" element={<ClientRedactorWithPathId/>}/>}
                            <Route exact path="/catalog/merchandise" element={<CatalogMerchandise/>}/>
                            <Route exact path="/catalog/service" element={<CatalogService/>}/>
                            <Route exact path="/catalog/classTransport" element={<CatalogClassTransport/>}/>
                            {isAddEmployee && <Route exact path="/Employee" element={<Employee/>}/>}
                        </Routes>
                    </Suspense>
                </Box>
            </NavigationMenu>
    </Fragment>

}