import React from 'react';
import Box from "../../../../element/Box";
import ClassTransportRedactor from "../ClassTransportRedactor";
import ButtonSettings from "../../../../element/Button/ButtonSettings";
import DeleteButton from "../../../../element/Button/ButtonDelete"
import "./style.css"
import {useSelector} from "react-redux";
import {deleteClassTransport} from "../../../../Rest/RestFunction";
import Modal from "../../../../element/Modal";
import useSignal from "../../../../hooks/useSignal";

const ClassTransportView = (props) => {
    const {classTransport} = props
    const user = useSelector((state) => state.auth.user);
    const [signalGetAllClassTransport, sendSignalGetAllClassTransport] = useSignal("classTransport.listAll.update")

    const deleteClassTransportID = async ()=>{
         await deleteClassTransport(classTransport.id, user.tokenAuth)
         sendSignalGetAllClassTransport()
    }

    return (
        <Box sx={{
            display:"flex",
            gap:"1rem",
            alignItems:"center"
        }}>
            <div>
                {classTransport.name}
            </div>
            <div>
                {classTransport.nameByClient}
            </div>
            {/*<div>*/}
            {/*    {classTransport.transportList}*/}
            {/*</div>*/}
            <div style={{display:"flex", alignItems:"center"}}>
                <div className={"ButtonRedactorClassTransportView"}>
                <ClassTransportRedactor
                    id={classTransport.id}
                    startComponent={<ButtonSettings/>}
                    label={"Редактирование класса компонента"}
                />
                </div>
                <Modal
                    startComponent={<div className={"ButtonDeleteClassTransportView"} >
                        <DeleteButton/>
                    </div>}
                    onSubmit={deleteClassTransportID}
                >
                    Вы точно хотите удалить класс?
                </Modal>

            </div>
        </Box>
    );
};

export default ClassTransportView;