import * as authSelectors from "./reducer";
import * as types from "./actionTypes";
import * as NotificationAction from "../notifications/actions"
import {shallowEqual, useSelector} from "react-redux";
import * as AuthSelectors from "./reducer";
import {checkLogPass} from "../../Rest/RestFunction";
import {store} from "../configureStore";
import CryptoAES from 'crypto-js/aes';
import CryptoENC from 'crypto-js/enc-utf8';

export const checkAuth = () => async (dispatch) => {
    let authResponseCrypt = localStorage.getItem("auth")
    if (authResponseCrypt) {
        let employee = JSON.parse(CryptoAES.decrypt(authResponseCrypt, 'local').toString(CryptoENC))
        console.log('employee = ',employee)
        const timeTokenAuth = new Date(employee.timeTokenAuth)
        let getUser = false;
        if (timeTokenAuth - new Date() > 0) {
            console.log("Запршаиваем по текущему токену")
            let authResponseNew = await checkLogPass({
                tokenAuth:employee.tokenAuth
            })
            console.log('auth=',authResponseNew)
            if (authResponseNew && authResponseNew.status === 200) {
                employee = authResponseNew.employee
                getUser = true
            }
        }

        if (getUser === false) {
            const timeTokenNextAuth = new Date(employee.timeTokenNextAuth)
            if (timeTokenNextAuth - new Date() > 0) {
                console.log("Запршаиваем по следующему токену")
                let authResponseNew = await checkLogPass({
                    tokenAuth:employee.tokenNextAuth
                })
                if (authResponseNew && authResponseNew.status === 200) {
                    employee = authResponseNew.employee
                    getUser = true
                }
            }
        }

        if (getUser === false) {
            logOut()(dispatch)
        }

        if (getUser === true) {
            saveLocalStorage(employee)
            dispatch({
                type: types.CHANGE_USER,
                user: employee
            });
        }
    }
}

export const updateToken = async (dispatch) => {
    let employee = store.getState().auth.user
    const timeTokenAuth = new Date(employee.timeTokenAuth)
    let getUser = false;
    if (timeTokenAuth - new Date() > 0) {
        console.log("Запршаиваем по текущему токену")
        let authResponseNew = await checkLogPass({
            tokenAuth:employee.tokenAuth
        })
        if (authResponseNew.status === 200) {
            employee = authResponseNew.employee
            getUser = true
        }
    }

    if (getUser === false) {
        const timeTokenNextAuth = new Date(employee.timeTokenNextAuth)
        if (timeTokenNextAuth - new Date() > 0) {
            console.log("Запршаиваем по следующему токену")
            let authResponseNew = await checkLogPass({
                tokenAuth:employee.tokenNextAuth
            })
            if (authResponseNew.status === 200) {
                employee = authResponseNew.employee
                getUser = true
            }
        }
    }

    if (getUser === false) {
        console.log("logout")
        logOut()(dispatch)
    }

    if (getUser === true) {
        console.log("Выставляем юзера", employee)
        saveLocalStorage(employee)
        dispatch({
            type: types.CHANGE_USER,
            user: employee
        });
    }
}

export const saveLocalStorage = (employee) => {
    let crypt = CryptoAES.encrypt(JSON.stringify(employee), 'local').toString();
    localStorage.setItem("auth", crypt)
}

export const checkPassword = (login, password, redirect, history) => async (dispatch) => {
    let authResponse = await checkLogPass({
        login:login,
        password:password
    })
    if (authResponse) {
        if (authResponse.status === 200) {
            saveLocalStorage(authResponse.employee)
            dispatch({
                type: types.CHANGE_USER,
                user: authResponse.employee
            });
            if (redirect && history) {
                history(redirect)
            }
        } else {
            dispatch(NotificationAction.showNotification("error", "Неправильный логин или пароль"))
        }
    } else {
        dispatch(NotificationAction.showNotification("error", "Сервер не доступен"))
    }
};

export const changeUser = (user) => async (dispatch) => {
    dispatch({
        type: types.CHANGE_USER,
        user:user
    });
};

export const logOut = () => (dispatch) => {
    //localStorage.clear()
    localStorage.removeItem("auth")
    dispatch({
        type: types.CHANGE_USER,
        user:{
            auth: null,
            role: {
                name:""
            }
        }
    });
}

export const deleteAuthInformation = (cookies) => (dispatch) => {
    cookies.remove("idActiveUser")
    cookies.remove("auth")
   // dispatch({ type: types.DELETE_AUTH_INFORMATION });
};
