import React, {useState} from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import {getFile} from "../../Rest/RestFunction"; // This only needs to be imported once in your app

export default function ImagesBox(props) {

    const {images, index, children} = props

    const [photoIndex, setPhotoIndex] = useState(index)
    const [isOpen, setIsOpen] = useState(false)

    const getImage = (metadata) => {
        return getFile(metadata.url);
    }

    return (
        <div>
            <div onClick={()=> setIsOpen(true)}>
                {children}
            </div>
            {isOpen && (
                <Lightbox
                    mainSrc={getImage(images[photoIndex])}
                    nextSrc={getImage(images[(photoIndex + 1) % images.length])}
                    prevSrc={getImage(images[(photoIndex + images.length - 1) % images.length])}
                    onCloseRequest={() => setIsOpen(false)}
                    onMovePrevRequest={() =>
                        setPhotoIndex((photoIndex + images.length - 1) % images.length)
                    }
                    onMoveNextRequest={() =>
                        setPhotoIndex( (photoIndex + 1) % images.length)
                    }
                />
            )}
        </div>
    );

}