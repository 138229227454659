import React, {useEffect, useState} from 'react';
import {Box} from "@mui/material";
import {useSelector} from "react-redux";
import Currency from "./Currency";
import {useAccessUser} from "../../../service/employees/useAccessUser";
import {UPDATE_CURRENCY} from "../../../constants/accesses";
import {getAllCurrency} from "../../../Rest/RestFunction";

const CurrencyRedactor = () => {
    const user = useSelector((state) => state.auth.user);
    const isUpdateCurrency = useAccessUser(UPDATE_CURRENCY, user);

    const [currencyList, setCurrencyList] = useState([])

    useEffect(() => {
        if (user.tokenAuth) {
            updateAllCurrency()
        }
    }, [user.tokenAuth])

    const updateAllCurrency = async () => {
        const currencyListResponse = await getAllCurrency({
            tokenAuth: user.tokenAuth
        })
        setCurrencyList(currencyListResponse)
    }

    if (isUpdateCurrency) {
        return (
            <Box>
                {currencyList && currencyList.map(currency => {
                    return <Currency currency={currency} key={currency.id} updateAll={updateAllCurrency}/>
                })}
            </Box>
        )
    } else {
        return null;
    }
};

export default CurrencyRedactor;