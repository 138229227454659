import React, {useLayoutEffect, useState} from 'react';
import PropTypes from 'prop-types';
import Box from "../../../../element/Box";
import Typography from "../../../../element/Typography";
import TextField from "../../../../element/TextField";
import Button from "../../../../element/Button";
import {useDispatch, useSelector} from "react-redux";
import {updateEmployee} from "../../../../Rest/RestFunction";
import Modal from "../../../../element/Modal";
import ListField from "../../../../elements/ListField";
import AccessRedactor from "../AccessesRedactor";
import Switch from "../../../../element/Switch";
import useSignal from "../../../../hooks/useSignal";

const EmployeeRedactor = props => {
    const {employee = {}} = props
    const user = useSelector((state) => state.auth.user)
    const [employeeState, setEmployeeState] = useState({})
    const [signal, sendSignal] = useSignal("employee.list.reload")
    const dispatch = useDispatch()

    useLayoutEffect(() => {
        setEmployeeState(employee)
    }, [employee])

    const onChange = (name, value) => {
        setEmployeeState(prevState => {
            return {
                ...prevState,
                [name]: value
            }
        })
    }

    const save = async () => {
        await updateEmployee({
            tokenAuth: user.tokenAuth,
            employee:employeeState
        }, dispatch)
        sendSignal()
    }

    const {login, password, firstName, lastName, secondName, accesses = [], isLeave, active} = employeeState

    return (
        <Box sx={{
            display: "flex",
            gap: "0.5rem",
            flexDirection:"column"
        }}>
            <Box sx={{
                display:"flex",
                gap: "0.5rem"
            }}>
            <TextField
                label="Логин"
                value={login}
                onChange={(e) => onChange("login", e.target.value)}
            />
            <TextField
                label="Пароль"
                value={password}
                onChange={(e) => onChange("password", e.target.value)}
            />
            <TextField
                label="Фамилия"
                value={lastName}
                onChange={(e) => onChange("lastName", e.target.value)}
            />
            <TextField
                label="Имя"
                value={firstName}
                onChange={(e) => onChange("firstName", e.target.value)}
            />
            <TextField
                label="Отчество"
                value={secondName}
                onChange={(e) => onChange("secondName", e.target.value)}
            />
            </Box>
            <Box sx={{
                display:"flex",
                gap: "0.5rem"
            }}>
                <Switch label={"Уволен"} value={isLeave} onChange={(e) => onChange("isLeave", e.target.checked)}/>
                <Switch label={"На смене"} value={active} onChange={(e) => onChange("active", e.target.checked)}/>
                <Modal
                    label="Права доступа"
                >
                    <AccessRedactor accessEmployee={accesses} onChange={(newValue) => onChange("accesses", newValue)}/>
                </Modal>
                <Button onClick={save}>
                    Сохранить
                </Button>
            </Box>

        </Box>
    );
};

EmployeeRedactor.propTypes = {
    employee: PropTypes.object
};

export default EmployeeRedactor;