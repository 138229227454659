import * as React from 'react';
import {Box} from "@mui/system";
import moment from "moment";
import {useLayoutEffect, useState} from "react";
import './style.css'
import Button from "../../element/Button";
import DateField from "../../element/DateField";

export default function FiltersClientOrders(props) {
    const {filters = [], setFilters, categoryRoot} = props
    const [indexActive, setIndexActive] = useState(0)
    const [fromValue, setFromValue] = React.useState();
    const [toValue, setToValue] = React.useState();

    useLayoutEffect(() => {
        initFiltersDate()
    }, [])

    const initFiltersDate = () => {
        let index = filters.findIndex(a => a.name === "FromDate")
        if (index !== -1) {
            filters.splice(index, 1)
        }
        let fromDate = moment(Date.now())
        fromDate.set({
            'hour': 0,
            'minute': 0,
            'second': 0,
            'millisecond': 0
        })
        fromDate.add(-1000*60*60*24*90)
        filters.push({
            name: "FromDate",
            nameProperty: "createDate",
            typeData: "Date",
            operator: "gte",
            value: fromDate
        })
        setFromValue(fromDate)

        index = filters.findIndex(a => a.name === "ToDate")
        if (index !== -1) {
            filters.splice(index, 1)
        }
        let toDate = moment(Date.now())
        toDate.set({
            'hour': 23,
            'minute': 59,
            'second': 59,
            'millisecond': 999
        })
        filters.push({
            name: "ToDate",
            nameProperty: "createDate",
            typeData: "Date",
            operator: "lte",
            value: toDate
        })
        setToValue(toDate)
        setFilters([...filters])
    }

    const handleChangeFrom = (newValue) => {

        let index = filters.findIndex(a => a.name === "FromDate")
        if (index !== -1) {
            filters.splice(index, 1)
        }

        let date = moment(newValue)
        date.set({
            'hour': 0,
            'minute': 0,
            'second': 0,
            'millisecond': 0
        })
        filters.push({
            name: "FromDate",
            nameProperty: "createDate",
            typeData: "Date",
            operator: "gte",
            value: date
        })
        setFromValue(date);

        let from = +new Date(date)
        let to = +new Date(toValue)

        if (from > to) {
            let index = filters.findIndex(a => a.name === "ToDate")
            if (index !== -1) {
                filters.splice(index, 1)
            }
            let date = moment(newValue)
            date.set({
                'hour': 23,
                'minute': 59,
                'second': 59,
                'millisecond': 999
            })
            setToValue(date)

            filters.push({
                name: "ToDate",
                nameProperty: "createDate",
                typeData: "Date",
                operator: "lte",
                value: date
            })
        }
    };

    const handleChangeTo = (newValue) => {

        let index = filters.findIndex(a => a.name === "ToDate")
        if (index !== -1) {
            filters.splice(index, 1)
        }

        let date = moment(newValue)
        date.set({
            'hour': 23,
            'minute': 59,
            'second': 59,
            'millisecond': 999
        })
        filters.push({
            name: "ToDate",
            nameProperty: "createDate",
            typeData: "Date",
            operator: "lte",
            value: date
        })
        setToValue(date);

        let from = +new Date(fromValue)
        let to = +new Date(date)

        if (from > to) {
            let index = filters.findIndex(a => a.name === "FromDate")
            if (index !== -1) {
                filters.splice(index, 1)
            }
            let date = moment(newValue)
            date.set({
                'hour': 0,
                'minute': 0,
                'second': 0,
                'millisecond': 0
            })
            setFromValue(date)

            filters.push({
                name: "FromDate",
                nameProperty: "createDate",
                typeData: "Date",
                operator: "gte",
                value: date
            })
        }
    };
    const renderCategoryButtonFilter = (I, index) => {
        return <div className={"servicesMenu"} style={{
            background: `${!(indexActive === index)? "rgba(149,184,238,0.2)" : "rgba(149,184,238,0.55)"}`,
            padding: "5px 26px",
            borderRadius: "20px",
            color: `${!(indexActive === index)? "" : ""}`,
            fontWeight: `${!(indexActive === index)? "" : ""}`,
            textDecorationLine: `${!(indexActive === index)? "" : "underline"}`
        }} onClick={()=>{
            setIndexActive(index)

            let ind = filters.findIndex(a => a.name === "services")
            if (ind !== -1) {
                filters.splice(ind, 1)
            }
            filters.push({
                name:"services",
                nameProperty:"categoryIdList",
                typeData:"ObjectId",
                operator:"is",
                value: I.id,
            })

            setFilters([...filters])
        }
        }>
            {I.name}</div>
    }

    return (
        <div style={{
            display:"flex",
            flexDirection:"column",
            gap:"20px",
            margin:"10px 10px 10px 17px"
        }}>
            <div style={{display: "flex", gap:"10px"}}>
                <Box>
                    <DateField
                        label="от:"
                        name="FromDate"
                        value={fromValue}
                        onChange={handleChangeFrom}
                    />
                </Box>
                <Box>
                    <DateField
                        label="до:"
                        name="ToDate"
                        value={toValue}
                        onChange={handleChangeTo}
                    />
                </Box>
                <Button onClick={() => {
                    setFilters([...filters])
                }}
                > Поиск
                </Button>
            </div>
            <div style={{display:"flex", flexWrap:"wrap", gap:"0.5rem", justifyContent: "space-around"}}>
                <div className={"servicesMenu"} style={{backgroundColor: `${!(indexActive === 0)? "rgba(149,184,238,0.2)" : "rgba(149,184,238,0.55)"}`,
                    padding: "5px 26px",
                    borderRadius: "20px",
                    color: `${!(indexActive === 0)? "" : ""}`,
                    fontWeight: `${!(indexActive === 0)? "" : ""}`,
                    textDecorationLine: `${!(indexActive === 0)? "" : "underline"}`
                }}

                     onClick={()=>{
                         setIndexActive(0)
                         let ind = filters.findIndex(a => a.name === "services")
                         if (ind !== -1) {
                             filters.splice(ind, 1)}

                         setFilters(filters.concat([]))}}
                >Все</div>

                {categoryRoot && categoryRoot.map((I, index)=> {
                    return renderCategoryButtonFilter(I, index +1)
                })}
            </div>
        </div>
    );
}