import React, {Fragment} from 'react';
import {renderFilesListWithName} from "../../utils/commonUtils";
import IconButton from "@material-ui/core/IconButton";
import ClearIcon from "@material-ui/icons/Clear";
import {downloadFile, getFile} from "../../Rest/RestFunction";
import Word from "../../static/typeFiles/Word.png";
import Excel from "../../static/typeFiles/Excel.jpg";
import JPG from "../../static/typeFiles/JPG.jpg";
import PDF from "../../static/typeFiles/PDF.jpg";

export default function FileListWithDeletedButton(props) {
    const deleteFileFunction = props.deleteFileFunction;
    const files = props.files;

    const getIconByType = (type) => {
        switch (type) {
            case "application/vnd.openxmlformats-officedocument.wordprocessingml.document": return Word
            case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" : return Excel
            case "image/png" : return JPG
            case "application/pdf" : return PDF
        }
        return JPG
    }

    const renderShortName = (name) => {
        if (name != null) {
            let indexDot = name.lastIndexOf(".")
            if (indexDot > 0) {
                name = name.substring(0, indexDot)
            }
            if (name.length > 15) {
                name = name.substring(0, 12) + "..."
            }
        }
        return name
    }

    const getImage = (guid) => {
        return getFile(guid);
    }

    const renderElement = (file) => {
        let icon = getIconByType(file.type)
            if (icon === JPG) {
                return <img src={getImage(file.url)} style={{height:"60px", margin:"0"}}/>
            }
            else {
                return <img src={icon} style={{height:"60px", margin:"0"}} />
            }
    }

    return (
            <div style={{display:"flex", flexWrap:"wrap"}}>
                {files && files.map((file, index)=>{
                    return <div key={index} style={{ width:"120px", display:"flex", flexDirection:"column", alignItems:"center", margin:"6px 4px 6px 4px"}}>
                        <div style={{position:"relative", height:"60px"}}>
                            <IconButton aria-label="delete" style={{position:"absolute", top:"-10px", right:"-10px", padding:"0"}} onClick={deleteFileFunction(index)}>
                                <ClearIcon fontSize="small" />
                            </IconButton>
                            { file.guid != null ?
                                <IconButton aria-label="download" style={{margin:"0 12px 0 0", padding:"0"}} onClick={downloadFile(file.guid, file.name)} >
                                    {renderElement(file)}
                                </IconButton> :
                                renderElement(file)}
                        </div>
                        {renderShortName(file.name)}
                    </div>
                })}
            </div>
    );
}