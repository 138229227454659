import React from 'react';
import {useParams} from "react-router-dom"
import Client from "../index";

const ClientWithPathId = () => {
    const { id } = useParams()
    return (
        <Client id={id} isLoad={true}/>
    );
};

export default ClientWithPathId;