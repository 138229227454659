import * as React from 'react';
import './style.css'

import Divider from "@material-ui/core/Divider";
import Box from "@material-ui/core/Box";
import {useDispatch, useSelector} from "react-redux";
import {useAccessUser} from "../../../../service/employees/useAccessUser";
import {
    ADD_CLIENT,
    ADD_CLIENT_ORDER, GET_ALL_CLIENT,
    GET_ALL_CLIENT_ORDER,
    GET_MY_CLIENT_ORDER, GET_SMALL_ALL_CLIENT_ORDER, UPDATE_CATALOG, UPDATE_CLIENT,
    UPDATE_CLIENT_ORDER,
    UPDATE_CURRENCY, UPDATE_PRODUCT
} from "../../../../constants/accesses";
import MenuButton from "../../../../elements/MenuButton";
import * as ControlActions from "../../../../store/controlPanel/action";
import * as NotificationsActions from "../../../../store/notifications/actions";
import Typography from "@material-ui/core/Typography";
import {CustomSwitch} from "../../../../elements/CustomSwitch";
import AccordionDetails from "@material-ui/core/AccordionDetails";


export default function LeftMenu(props) {
    const user = useSelector((state) => state.auth.user);
    const isUpdateCurrency = useAccessUser(UPDATE_CURRENCY, user);
    const isGetAllClientOrder = useAccessUser(GET_ALL_CLIENT_ORDER, user)
    const isGetSmallAllClientOrder = useAccessUser(GET_SMALL_ALL_CLIENT_ORDER, user)
    const isGetMyClientOrder = useAccessUser(GET_MY_CLIENT_ORDER, user)
    const isUpdateProduct = useAccessUser(UPDATE_PRODUCT, user);
    const isUpdateCatalog = useAccessUser(UPDATE_CATALOG, user);

    const isGetAllClient = useAccessUser(GET_ALL_CLIENT, user)

    const controlPanelProduct = useSelector((state) => state.controlPanel.product);
    const controlPanelCatalog = useSelector((state) => state.controlPanel.catalog);
    const dispatch = useDispatch()
    const setTextAlert = (text) => {
        dispatch(NotificationsActions.showNotification("info", text))
    }
    const handleUpdatePanelProduct = (name) => (event) => {
        controlPanelProduct[name] = event.target.checked
        dispatch(ControlActions.changeControlPanelProduct(controlPanelProduct))
    };

    const handleUpdatePanelCatalog = (name) => (event) => {
        controlPanelCatalog[name] = event.target.checked
        dispatch(ControlActions.changeControlPanelCatalog(controlPanelCatalog))
    };


    return (
        <div>
            <Box
                sx={{
                    display: {xs: 'block', md: 'none'}
                }}>
            </Box>
            {isUpdateCurrency ? <MenuButton
                name={'Курсы валют'}
                link={"/privateOffice/currency"}
            /> : null}
            {(isGetAllClientOrder || isGetSmallAllClientOrder || isGetMyClientOrder) ? <MenuButton
                name={'Заказ-наряды'}
                link={"/privateOffice/clientOrders"}
            /> : null}
            {isGetAllClient ? <MenuButton
                name={'Клиенты'}
                link={"/privateOffice/clients"}
            /> : null}
            <MenuButton
                name={'Каталог классов транспорта'}
                link={"/privateOffice/catalog/classTransport"}
            />
            <MenuButton
                name={'Каталог услуг'}
                link={"/privateOffice/catalog/service"}
            />
            <MenuButton
                name={'Каталог товаров'}
                link={"/privateOffice/catalog/merchandise"}
            />
            <MenuButton
                name={'Сотрудники'}
                link={"/privateOffice/Employee"}
            />
            <Divider/>
            {isUpdateProduct ?
            <div style={{display:"flex", justifyContent:"center", alignItems:"center", minHeight: "48px", width:"97%", paddingLeft: "0.5rem"}}>
                <Typography>
                Редактирование товаров
                </Typography>

            <CustomSwitch
                onChange={handleUpdatePanelProduct("showUpdateElements")}
                checked={controlPanelProduct.showUpdateElements}
                color="secondary"
                inputProps={{'aria-label': 'checkbox with default color'}}
            />
            </div>:null}
            {isUpdateCatalog ?
            <div style={{display:"flex", justifyContent:"center", alignItems:"center", minHeight: "48px", width:"97%", paddingLeft: "0.5rem"}}>
            <Typography>
                Редактирование каталогов
            </Typography>
            <CustomSwitch
                onChange={handleUpdatePanelCatalog("showUpdateElements")}
                checked={controlPanelCatalog.showUpdateElements}
                color="secondary"
                inputProps={{'aria-label': 'checkbox with default color'}}
            />
            </div>:null}
        </div>
    );
}
