import React, {Fragment, useState} from 'react';
import Table from "../../../../elements/Table";
import ClientOrder from "../ClientOrder";

const ViewClientOrders = (props) => {
    const {typeView, data} = props

    switch (typeView) {

        default:
            return (
                <div>
                    <Table
                        header={{
                            cells:[
                                {
                                    flexGrow:"0.5",
                                    value:"Статус",
                                    name:"g"
                                },
                                {
                                    flexGrow:"1.5",
                                    value:"Дата создания",
                                    name:"createDate",
                                    type:"date",
                                    format:"DD.MM.yyyy[\n]HH:mm:ss"
                                },
                                {
                                    flexGrow:"2",
                                    value:"Тип (мойка / серв / запч / проч)",
                                    name:"categoryList",
                                    type:"CategoryChip"
                                },
                                {
                                    flexGrow:"2",
                                    value:"Клиент",
                                    name:"client",
                                    type:"ClientChip"
                                },
                                // {
                                //     flexGrow:"2",
                                //     value:"Транспорт",
                                //     name:"client.transportList",
                                //     type:"TransportListChip"
                                // },
                                {
                                    flexGrow:"2",
                                    value:"Марка",
                                    name:"transport.marka",
                                    //type:"TransportListChipMarka"
                                },
                                {
                                    flexGrow:"2",
                                    value:"Модель",
                                    name:"transport.model",
                                    //type:"TransportListChip"
                                },
                                {
                                    flexGrow:"2",
                                    value:"Номер",
                                    name:"transport.number",
                                    type:""
                                },
                                {
                                    flexGrow:"1",
                                    value:"Сумма",
                                    name:"g"
                                },
                                {
                                    flexGrow:"4",
                                    value:"Комментарии",
                                    name:"comments",
                                    type:"CommentChip"
                                },
                                {
                                    flexGrow:"1",
                                    value:"Время",
                                    name:"g",
                                    type:""
                                },

                            ]
                        }}
                        data={data}
                        component={"ClientOrder"}
                        componentProps={{
                            isFullScreen:false
                        }}
                    />
                </div>
            );
    }

};

export default ViewClientOrders;