export const getTextTransport = (transport) => {
    let result = [];
    if (transport.transportCatalog) {
        if (transport.transportCatalog.brand) {
            result.push(transport.transportCatalog.brand)
        }
        if (transport.transportCatalog.model) {
            result.push(transport.transportCatalog.model)
        }
    }
    if (transport.number) {
        result.push(transport.number)
    }
    return result.join(" ")
}