import React from 'react';
import Table from "../../../../elements/Table";
import ButtonLaunch from "../../../../element/Button/ButtonLaunch";
import ButtonEdit from "../../../../element/Button/ButtonEdit";
import AccordionDetails from "@mui/material/AccordionDetails";
import {useNavigate} from "react-router-dom";

const ViewTransport = (props) => {
    const {typeView, data, client} = props
    const navigate = useNavigate()

    const goToRedactor = () => {
        navigate(`/privateOffice/client/redactor/${client.id}`)
    }

    const goToFullScreen = () => {
        navigate(`/privateOffice/client/${client.id}`)
    }

    switch (typeView) {
        default:
            return (
                <div style={{borderTop:"1px dashed rgba(0,0,0,0.2)", marginTop:"20px"}}>
                    <p  style={{marginTop:"20px"}}>
                        Заказ наряд открытый, если есть</p>
                    <ul>
                        <li>История заказ нарядов</li>
                        <li>История баланса</li>
                        <li>Данные по авто</li>
                        <li>Другие документы</li>
                    </ul>
                    {/*<div style={{display: "flex", justifyContent: "end"}}>*/}
                    {/*    <ButtonLaunch*/}
                    {/*        color={"primary"}*/}
                    {/*        size={"medium"}*/}
                    {/*        onClick={goToFullScreen}*/}
                    {/*    />*/}
                    {/*    <ButtonEdit*/}
                    {/*        color={"primary"}*/}
                    {/*        size={"medium"}*/}
                    {/*        onClick={goToRedactor}*/}
                    {/*    />*/}
                    {/*</div>*/}
                </div>

                //-----------------------------------------------------------
                // <div>
                //     <Table
                //         header={{
                //             cells:[
                //                 {
                //                     flexGrow:"2",
                //                     value:"Марка",
                //                     name:"marka"
                //                 },
                //                 {
                //                     flexGrow:"2",
                //                     value:"Модель",
                //                     name:"model"
                //                 },
                //                 {
                //                     flexGrow:"2",
                //                     value:"Номер",
                //                     name:"number"
                //                 },
                                // todo Скидки авто
                                // {
                                //     flexGrow:"2",
                                //     value:"Дата создания",
                                //     name:"createDate"
                                // },
                //             ]
                //         }}
                //         data={data}
                //         component={"Client"}
                //     />
                // </div>
            );
    }
};

export default ViewTransport;