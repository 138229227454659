import React, {useState} from 'react';
import Box from "../../../../element/Box";
import Typography from "../../../../element/Typography";
import BoxEasyOpen from "../../../../element/BoxEasyOpen";
import ClassTransportView from "../ClassTransportView";

const GroupTransportView = (props) => {
    const {classTransportList, groupName} = props
    const [open, setOpen] = useState(false)
    return (
        <Box>
            <Box
                sx={{
                    p:"1rem"
                }}
                onClick={(e) => setOpen(!open)}>
                <Typography>
                    {groupName}
                </Typography>
            </Box>
            <BoxEasyOpen
                sx={{
                    p:"1rem",
                    paddingLeft:"2rem"
                }}
                open={open}>
                {classTransportList && Array.isArray(classTransportList) && classTransportList.map(classTransport => {
                    return <ClassTransportView classTransport={classTransport} />
                })}
            </BoxEasyOpen>
        </Box>
    )
};

export default GroupTransportView;