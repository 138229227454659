
import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import auth from "./auth/reducer"
import page from "./page/reducer"
import catalog from "./catalog/reducer"
import category from "./category/reducer"
import product from  "./product/reducer"
import controlPanel from  "./controlPanel/reducer"
import notifications from "./notifications/reducer"
import errors from "./errors/reducer"
import signal from "./signal/reducer"
import cache from "./cache/reducer"

const reducers = {
    auth, page, catalog, category, product, controlPanel, notifications, errors, signal, cache
};

export default (history) => combineReducers({
    router: connectRouter(history),
    ...reducers
});