import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from "../../Tooltip";
import TypographyDate from "../../../elements/TypographyDate";
import Box from "@material-ui/core/Box";

const ChangeDateChip = props => {
    const {changeDate, createDate, format} = props
    return (
        <Tooltip title={<>
        Дата создания:
        <TypographyDate value={createDate} format={format}/>
        </>
        }>
            <Box
                sx={{
                    fontWeight:changeDate ? "700" : "400"
                }}
            >
                <TypographyDate value={changeDate ? changeDate : createDate} format={format}/>
            </Box>
        </Tooltip>

    );
};

ChangeDateChip.propTypes = {

};

export default ChangeDateChip;