import * as types from "./actionTypes";
import {checkLogPass, getPage, updatePage} from "../../Rest/RestFunction";
import {useSelector} from "react-redux";

const initState = {
    page:{

    },

};

export default function reduce(state = initState, action = {}) {

    switch (action.type) {
        case types.CHANGE_CONTENTS:
            return {
                ...state,
                page: {
                    ...state.page,
                    content: action.newContents
                }
            };
        case types.CHANGE_CONTENT:
            const content = state.page.content
            content[action.index]=action.newContent
            return {
                ...state,
                page: {
                    ...state.page,
                    content: content
                }
            };
        case types.ADD_CONTENT_TO_PAGE:
            const page = state.page

            if (!page.content) {
                page.content = []
            }
            let newContent = page.content.slice(0,action.index)
            newContent.push(action.addContent)
            newContent = newContent.concat(page.content.slice(action.index, page.content.length))

            return {
                ...state,
                page: {
                    ...state.page,
                    content: newContent
                }
            };
        case types.DELETE_CONTENT_TO_PAGE:
            let content1 = state.page.content
            content1.splice(action.index,1)
            return {
                ...state,
                page: {
                    ...state.page,
                    content: content1
                }
            };
        case types.CHANGE_PAGE:
            return {
                ...state,
                page: action.page
            };
        case types.UPDATE_PAGE:
            return {
                ...state,
                page: action.updatedPage
            };
        case types.SAVE_PAGE:

            updatePage(state.page.name, state.page, action.tokenAuth)
            return {
                ...state
            };
        default:
            return state;
    }
}

const getStatePage = (state) =>{
    return state.page.page;
}



export {
    getStatePage
};