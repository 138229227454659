import React from 'react';
import Cell from "../Accordion/Cell";
import Box from "@mui/material/Box";

const TableRow = (props) => {
    const {header, index, row, onChange, byShop = false, onChangeShopData} = props
    return (
        <Box sx={{
            display:"flex",
            width:"100%",
            gap:"10px"
        }}>
            {header && header.cells && header.cells.map(cell => {
                return <Cell
                    {...cell}
                    indexRow={index}
                    row={row}
                    onChange={onChange}
                    byShop={cell.byShop !== undefined ? cell.byShop : byShop}
                    onChangeShopData={onChangeShopData}
                />
            })}
        </Box>
    );
};

export default TableRow;