import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import Typography from "../../../../element/Typography";
import Box from "../../../../element/Box";

const ClientOrderTotalMoney = props => {
    const {actions} = props
    const [result, setResult] = useState({})

    useEffect(() => {
        if (actions && actions.length > 0) {
            setResult(prev => {
                let result = {...prev}
                result.total = actions.reduce((res, current) => {
                    res = res + current.sum
                    return res
                }, 0)
                return result
            })
        } else {
            setResult({})
        }
    }, [actions])

    return (
        <Box sx={{
            display:"flex",
            justifyContent:"flex-end",
            gap:"0.5rem"
        }}>
            {result.total ? <Typography variant={"h5"}>
                {`Итого: ${result.total} рублей`}
            </Typography> : null}
        </Box>
    );
};

ClientOrderTotalMoney.propTypes = {
    actions: PropTypes.array,
};

export default ClientOrderTotalMoney;