import {setErrorCommon} from "../store/errors/action";

// export const server = 'http://localhost'; //- Когда разрабатываем локально без докера
// export const urlFrontend = server+':3000'; //- Когда разрабатываем локально без докера
// export const urlBackend = server+':8090'; //- Когда разрабатываем локально без докера

// export const server = 'http://192.168.1.74'; //- Когда разрабатываем локально без докера
// export const urlFrontend = server+':3000'; //- Когда разрабатываем локально без докера
// export const urlBackend = server+':8090'; //- Когда разрабатываем локально без докера
// const url = 'http://192.168.1.69:8090'; //- Когда разрабатываем локально без докера

// const url = 'http://localhost'; // - Тестирование работы локально на компьютере с помощью докера. ЭТо путь до nginx, который перенаправит нас по url на бекенд

export const server = 'http://stof1.ru'; //- Продуктивная среда на серваке по домену.
export const urlFrontend = server; //- Продуктивная среда на серваке по домену.
export const urlBackend = server; //- Продуктивная среда на серваке по домену.



export async function getRequest(action) {
    const URL = `${urlBackend}/rest/${action}`;
    const response = await fetch(URL, {
        headers: {
            'Accept': 'application/json; charset=utf-8',
            'Content-Type': 'application/json; charset=utf-8'
        },
        method: 'GET'
    })
        .then((response) => response.json())
        .then(function (response) {
            return response;
        })
        .catch(function (error) {
            console.log(action + ' error');
        });
    return response;

};

export async function postRequest(action, obj) {
    const URL = `${urlBackend}/rest/${action}`;
    const response = await fetch(URL, {
        headers: {
            'Accept': 'application/json; charset=utf-8',
            'Content-Type': 'application/json; charset=utf-8'
        },
        method: 'POST',
        body: obj ? JSON.stringify(obj) : "{}"
    })
        .then((response) => response.json())
        .then(function (response) {
            return response;
        })
        .catch(function (error) {
            console.log(action + ' error');
        });
    return response;
};

export async function putRequest(action, obj, dispatch) {
    const URL = `${urlBackend}/rest/${action}`;
    const response = await fetch(URL, {
        headers: {
            'Accept': 'application/json; charset=utf-8',
            'Content-Type': 'application/json; charset=utf-8'
        },
        method: 'PUT',
        body: JSON.stringify(obj)
    })
        .then(async response => {
            return [await response.json(), response]
        })
        .then(([result, response]) => {
                if (!response.ok) {
                    try {
                        dispatch && dispatch(setErrorCommon(result.message ? result.message : action))
                    } catch (e) {
                        dispatch && dispatch(setErrorCommon(action))
                    }
                    console.log(action + ' error');
                }
                return result
            }
        )
        .catch(function (error) {
            console.log(action + ' error');
        });
    return response;
};


export async function deleteRequest(action, obj) {
    const URL = `${urlBackend}/rest/${action}`;
    const response = await fetch(URL, {
        headers: {
            'Accept': 'application/json; charset=utf-8',
            'Content-Type': 'application/json; charset=utf-8'
        },
        method: 'DELETE',
        body: JSON.stringify(obj)
    })
        .then((response) => response.json())
        .then(function (response) {
            return response;
        })
        .catch(function (error) {
            console.log(action + ' error');
        });
    return response
};


// export const getPage = async (name) => {
//     return await getRequest(`page/${name}`)
// };

export const getPage = async (name) => {
    return await postRequest(`page/get`, {name:name})
};

// export const updatePage = async (name, page, tokenAuth) => {
//     return await putRequest(`page/${name}`, {
//         page:page,
//         tokenAuth: tokenAuth
//     })
// };

export const updatePage = async (name, page, tokenAuth) => {
    return await putRequest(`page/update`, {
        name:name,
        page:page,
        tokenAuth: tokenAuth
    })
};


export const addRest = async (obj) => {
    return await postRequest('addHibernate', obj)
    //await putRequest("add",obj)

};
export const updateAuto = async (obj, idUser) => {
    return await putRequest('update?idUser=' + idUser, obj)
    //await putRequest("add",obj)

};
export const addUser = async (obj) => {
    return await postRequest('user/addUser', obj)
};
export const getAllUsers = async () => {
    return await getRequest('user/allUsers')

};
export const getUser = async (id) => {
    return await getRequest('user/findByUserId' + id)

};

export const updateUser = async (obj, idUser) => {
    return await putRequest('user/update?idRedactor=' + idUser, obj)
    //await putRequest("add",obj)

};

export const getAuto = async (id) => {
    return await getRequest('findById' + id)

};
export const checkLogPass = async (obj) => {
    return await postRequest('employee/auth', obj)

};
export const findGeneral = async (obj) => {
    return await postRequest('findGeneral', obj)

};

export const getLineDate = async (obj) => {
    return await postRequest('findLine', obj)

};

export const findDate = async (date) => {
    return await postRequest('findDate', date)

};

export const findNomer = async (nomer) => {
    return await postRequest('findNomer?nomer=' + nomer)

};

export const delRest = async (id) => {
    await deleteRequest('del?id=' + id, {})
};

export const deleteByIdList = async (idList) => {
    await deleteRequest('deleteByIdList', idList)
}
export const deleteUsersList = async (usersList) => {
    await deleteRequest('user/deleteUsersList', usersList)
}

export const addFiles = async (files) => {

    if (!files.length) {
        throw new Error('Нет файлов для загрузки');
    }

    const attachmentFiles = new FormData();

    files.forEach(item => {
        attachmentFiles.append('files', item)
    });

    const response = await fetch(`${urlBackend}/rest/file/upload`, {
        method: 'post',
        body: attachmentFiles
    });

    if (!response.ok) {
        throw new Error(`Ошибка загрузки файлов, HTTP статус ${response.status}`);
    }

    const filesUpload = await response.json();

    if (!filesUpload || filesUpload.length === 0) {
        throw new Error('Нет загруженных файлов');
    }

    return filesUpload;
}

export const getFile = (guid) => {
    return `${urlBackend}/rest/file/${guid}/download`
}

export const downloadFile = (guid, fileName) => async () => {
    const response = await fetch(`${urlBackend}/rest/file/${guid}/download`, {
        method: 'get',
        responseType: 'blob'
    })

    if (!response.ok) {
        throw new Error(`Ошибка получения файла, HTTP статус ${response.status}`);
    }

    const file = await response.blob();


    if (file) {
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(new Blob([file]));
        link.setAttribute('download', `${fileName}`);
        if (document.body) {
            document.body.appendChild(link);
        }
        link.click();
        document.body.removeChild(link);
    }
}

export const searchPage = async (search) => {
    return await getRequest('page/search?search=' + search)
};

export const deletePageByName = async (name) => {
    return await deleteRequest(`page/${name}`, {})
};


/// Catalogs ---------------------------------------------------------------
export const getCatalogByName = async (name) => {
    return await getRequest(`catalog/${name}`)
};

export const getCatalogById = async (id) => {
    return await getRequest(`catalog/${id}`)
};

export const getCatalogsListByIds = async (catalogList) => {
    return await postRequest(`catalog/byIds`, {catalogList: catalogList})
};

export const getAllRootCatalogs = async () => {
    return await getRequest(`catalog/root`)
};

export const addCatalog = async (catalog, tokenAuth) => {
    catalog.id = null
    catalog.children = null
    return await postRequest(`catalog/add`, {
        catalog:catalog,
        tokenAuth: tokenAuth
    })
};

export const saveCatalogById = async (catalog, tokenAuth) => {
    return await putRequest(`catalog/byId/${catalog.id}`, {
        catalog:catalog,
        tokenAuth: tokenAuth
    })

};

export const deleteCatalogById = async (id, tokenAuth) => {
        return await deleteRequest(`catalog/byId/${id}`, {
            tokenAuth: tokenAuth
        })
};

export const deleteCatalog = async (catalog, tokenAuth) => {
    if (window.confirm("Удалить каталог с именем "+ catalog.name)) {
        return await deleteRequest(`catalog/byId/${catalog.id}`, {
            tokenAuth: tokenAuth
        })
    }
};

/// End Catalogs ---------------------------------------------------------------

/// CategoryForOffice ---------------------------------------------------------------

export const getAllRootCategory = async (type = "merchandise") => {
    return await getRequest(`category/root/${type}`)
};

export const getCategoryById = async (id) => {
    return await getRequest(`category/${id}`)
};

export const updateCategory = async (category, tokenAuth) => {
    return await putRequest(`category/update`, {tokenAuth:tokenAuth, category:category})
};

export const deleteCategoryById = async (id, tokenAuth) => {
    return await deleteRequest(`category/${id}`, {tokenAuth:tokenAuth})
};

/// End CategoryForOffice ---------------------------------------------------------------

/// ProductForOffice -------------------------------

export const findProductById = async (id) => {
    return await getRequest(`product/byId/${id}` )
};

export const getChangeCountProductEvents = async () => {
    return await getRequest(`product/changeCountProduct/events`)
};


export const getProductFilters = async (searchFilters) => {
    return await postRequest(`product/filters/get`, searchFilters)
};

export const updateProduct = async (product, tokenAuth) => {
    return await putRequest(`product/update`, {tokenAuth:tokenAuth, product:product})
};

export const checkProduct = async (product, tokenAuth) => {
    return await postRequest(`product/check`, {tokenAuth:tokenAuth, product:product})
};

export const updateAllProduct = async (productList, tokenAuth) => {
    return await putRequest(`product/updateAll`, {tokenAuth:tokenAuth, productList:productList})
};

export const changeCountProduct = async (obj, tokenAuth) => {
    return await putRequest(`product/changeCountProduct`, {tokenAuth:tokenAuth, ...obj})
};

export const searchProduct = async (searchParams) => {
    searchParams.page--
    return await postRequest(`product/search`, searchParams)
};



export const deleteProductById = async (id, tokenAuth) => {
    return await deleteRequest(`product/${id}`, {tokenAuth:tokenAuth} )
};

/** Статистика по продуктам*/
export const searchStatisticsProduct = async (searchParams) => {
    searchParams.page--
    return await postRequest(`product/statistics/search`, searchParams)
};

export const getFiltersProductStatistics = async (body) => {
    return await postRequest(`product/statistics/filters`, body)
};


/** */


/** Валюты */

export const getAllCurrency = async (body) => {
    return await postRequest(`currency`, body)
};

export const saveCurrency = async (body) => {
    return await putRequest(`currency`, body)
};

/** Заказы клиентов */

export const getClientOrderById = async (body) => {
    return await postRequest(`clientOrder`, body)
};

export const searchClientOrders = async (body) => {
    return await postRequest(`clientOrder/search`, body)
};

export const updateClientOrder = async (body) => {
    return await putRequest(`clientOrder`, body)
};

/** Услуги по заказу клиента */

export const getClientOrderActionByClientOrderId = async (body) => {
    return await postRequest(`clientOrderAction/byClientOrder`, body)
};

export const updateClientOrderAction = async (body) => {
    return await putRequest(`clientOrderAction`, body)
};

export const updateClientOrderActionMass = async (body) => {
    return await putRequest(`clientOrderAction/mass`, body)
};

/** Клиенты */

export const getClientById = async (body) => {
    return await postRequest(`client`, body)
};

export const searchClient = async (body) => {
    return await postRequest(`client/search`, body)
};

export const updateClient = async (body) => {
    return await putRequest(`client`, body)
};

export const addClientWithTransport = async (body) => {
    return await postRequest(`client/save/withTransport`, body)
};

/** Транспорт */

export const getTransportById = async (body) => {
    return await postRequest(`transport`, body)
};

export const searchTransport = async (body) => {
    return await postRequest(`transport/search`, body)
};

export const updateTransport = async (body) => {
    return await putRequest(`transport`, body)
};

/** Comment */

export const addComment = async (body) => {
    return await postRequest(`comment`, body)
};



/** Классы транспорта */

export const getClassTransportById = async (body) => {
    return await postRequest(`classTransport`, body)
};

export const searchClassTransport = async (body) => {
    return await postRequest(`classTransport/search`, body)
};

export const updateClassTransport = async (body) => {
    return await putRequest(`classTransport`, body)
};

export const getClassTransportAll = async (body) => {
    return await postRequest(`classTransport/all`, body)
};
export const deleteClassTransport = async (id, tokenAuth) => {
    return await deleteRequest(`classTransport/delete/${id}`, {tokenAuth:tokenAuth})
};

/** Каталог транспорта*/

export const searchTransportCatalog = async (body) => {
    return await postRequest(`transportCatalog/search`, body)
};

/** Транзакции */
export const sendClientOrderTransaction = async (body) => {
    return await postRequest(`transaction/clientOrder/send`, body)
}

export const searchClientOrderTransaction = async (body) => {
    return await postRequest(`transaction/clientOrder/search`, body)
}
/** Работники*/
export const searchEmployee = async (body) => {
    return await postRequest(`employee/search`, body)
};

export const addEmployee = async (body) => {
    return await postRequest(`employee/add`, body)
};

export const updateEmployee = async (body, dispatch) => {
    return await putRequest(`employee/update`, body, dispatch)
};

/** Права доступа */
export const getAllAccess = async (body) => {
    return await postRequest(`access/all`, body)
};
export const getAllAccessName = async (body) => {
    return await postRequest(`access/allName`, body)
};