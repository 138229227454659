import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";
import AccordionDetails from "@mui/material/AccordionDetails";
import React, {Fragment} from "react";
import SelectAutocomplete from "../../../../elements/SelectAutocomplete";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import moment from "moment";
import ru from "date-fns/locale/ru";
import AutocompleteSearchProduct from "../../../../elements/AutocompleteSearchProduct";
import TextField from "@mui/material/TextField";
import CloseIcon from "@mui/icons-material/Close";

export default function ProductFilters(props) {
    const {showFilters, filters, selectedFilters, productFiltersAccordionOpen, setProductFiltersAccordionOpen, onChangeSelectedFilters, direction="column"} = props

    const getFilter = (name) => {
        return selectedFilters.find(filter => {
            if (filter.name === name) {
                return filter
            }
        })
    }

    const clearFilter = (name) => {

        let newFilters = selectedFilters.map(filter => {
            if (filter.name !== name) {
                return filter
            }
        }).filter(filter => filter != null)
        onChangeSelectedFilters && onChangeSelectedFilters(newFilters)
    }

    const onChangeFilterTextField = ({name, nameProperty, nameDisplay, operator, value, typeData}) => {
        let isFind = false;
        let newFilters
        if (value && value.length > 0) {
            newFilters = selectedFilters.map(filter => {
                if (filter.name === name) {
                    isFind = true
                    filter.value = value
                }
                return filter
            })
            if (!isFind) {
                newFilters.push({
                    name:name,
                    nameProperty: nameProperty,
                    nameDisplay: nameDisplay,
                    operator: operator,
                    value: value,
                    typeData: typeData
                })
            }
        } else {
            newFilters = selectedFilters.map(filter => {
                if (filter.name !== name) {
                    return filter
                }
            }).filter(filter => filter != null)
        }
        onChangeSelectedFilters && onChangeSelectedFilters(newFilters)
    }

    const onChangeFilter = ({name, nameProperty, nameDisplay, operator, value, typeData}) => {
        let isFind = false;
        let newFilters
        if (value && value.length > 0) {
            newFilters = selectedFilters.map(filter => {
                if (filter.name === name) {
                    isFind = true
                    filter.values = value
                }
                return filter
            })
            if (!isFind) {
                newFilters.push({
                    name:name,
                    nameProperty: nameProperty,
                    nameDisplay: nameDisplay,
                    operator: operator,
                    values: value,
                    typeData: typeData
                })
            }
        } else {
            newFilters = selectedFilters.map(filter => {
                if (filter.name !== name) {
                    return filter
                }
            }).filter(filter => filter != null)
        }
        onChangeSelectedFilters && onChangeSelectedFilters(newFilters)
    }

    const getValueFilter = (name) => {
        let find = selectedFilters.find(filter => {
            if (filter.name === name) {
                return filter
            }
        })
        return find ? find.value : null
    }

    const onChangeFilterDateFrom = (name, nameProperty, nameDisplay, operator, typeData) => (date) => {
        let isFind = false;
        let newFilters
        date = moment(date)
        date.set({
            'hour': 0,
            'minute': 0,
            'second': 0,
            'millisecond': 0
        })
        if (date.isValid()) {
            newFilters = selectedFilters.map(filter => {
                if (filter.name === name) {
                    isFind = true
                    filter.value= date
                }
                return filter
            })
            if (!isFind) {
                newFilters.push({
                    name: name,
                    nameProperty: nameProperty,
                    nameDisplay: nameDisplay,
                    operator: operator,
                    value: date,
                    typeData
                })
            }
            onChangeSelectedFilters && onChangeSelectedFilters(newFilters)
        } else {
            clearFilter(name)
        }
    }

    const onChangeFilterDateTo = (name, nameProperty, nameDisplay, operator, typeData) => (date) => {
        let isFind = false;
        let newFilters
        date = moment(date)
        date.set({
            'hour': 23,
            'minute': 59,
            'second': 59,
            'millisecond': 999
        })
        if (date.isValid()) {
            newFilters = selectedFilters.map(filter => {
                if (filter.name === name) {
                    isFind = true
                    filter.value= date
                }
                return filter
            })
            if (!isFind) {
                newFilters.push({
                    name: name,
                    nameProperty: nameProperty,
                    nameDisplay: nameDisplay,
                    operator: operator,
                    value: date,
                    typeData
                })
            }
            onChangeSelectedFilters && onChangeSelectedFilters(newFilters)
        } else {
            clearFilter(name)
        }

    }

    const renderFilter = (filter) => {
        console.log(filter)
        if (filter.typeField === "TextField") {
            let searchFilter = getFilter(filter.name)
            return <TextField
                label={filter.nameDisplay ? filter.nameDisplay : filter.name}
                onChange={(event) => onChangeFilterTextField({
                    name: filter.name,
                    nameProperty:filter.nameProperty,
                    nameDisplay:filter.nameDisplay,
                    value:event.target.value,
                    typeData:filter.typeData,
                    operator:filter.operator,
                })}
                value={searchFilter ? searchFilter.value : []}
            />
        }

        if (filter.typeField === "Select") {
            let searchFilter = getFilter(filter.name)
            return <SelectAutocomplete
                className={"product_statistic_select_type"}
                multiple
                disableCloseOnSelect={true}
                style={{minWidth:"200px", maxWidth:"400px"}}
                label={filter.nameDisplay ? filter.nameDisplay : filter.name}
                options={filter.values}
                onChange={onChangeFilter}
                onChangeParam={{
                    name: filter.name,
                    nameProperty: filter.nameProperty,
                    nameDisplay: filter.nameDisplay,
                    operator: filter.operator,
                    typeData: filter.typeData
                }}
                value={searchFilter ? searchFilter.values : []}
            />
        }

        // if (filter.typeField === "SelectAccordion") {
        //     return <Accordion>
        //         <AccordionSummary
        //             expandIcon={<ExpandMoreIcon/>}
        //             aria-controls="panel1a-content"
        //             className={"white_svg"}
        //         >
        //             <FormControlLabel
        //                 aria-label="Acknowledge"
        //                 control={
        //                     <div>
        //                         <Typography style={{margin: "auto 0"}}>
        //                             {filter.nameDisplay ? filter.nameDisplay : filter.name}
        //                         </Typography>
        //                     </div>
        //                 }
        //             />
        //         </AccordionSummary>
        //         <AccordionDetails>
        //             <div>
        //                 {filter.values && filter.values.map(value => {
        //                     return <div className={"flex"}>
        //                         <Checkbox
        //                             onChange={onChangeFilter(filter.name, filter.nameProperty, filter.nameDisplay, filter.operator, value)}
        //                             checked={isChecked(filter.name, value)}
        //                         />
        //                         <Typography style={{margin: "auto 0"}}>
        //                             {value}
        //                         </Typography>
        //                     </div>
        //                 })}
        //             </div>
        //         </AccordionDetails>
        //     </Accordion>
        // }
        if (filter.typeField === "DateFrom") {
            return <div className={"flex product_statistic_for_calendar"}>
                <MuiPickersUtilsProvider
                    utils={DateFnsUtils}
                    locale={ru}
                >
                    <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        label={filter.nameDisplay ? filter.nameDisplay : filter.name}
                        format={"dd/MM/yyyy"}
                        value={getValueFilter(filter.name)}
                        onChange={onChangeFilterDateFrom(filter.name, filter.nameProperty, filter.nameDisplay, filter.operator, filter.typeData)}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}

                        InputProps={{
                            startAdornment: (
                                    <CloseIcon onClick={() => clearFilter(filter.name)}/>
                            )
                        }}
                    />
                </MuiPickersUtilsProvider>
            </div>
        }
        if (filter.typeField === "DateTo") {
            return <div className={"flex product_statistic_for_calendar"}>
                <MuiPickersUtilsProvider
                    utils={DateFnsUtils}
                    locale={ru}
                    >
                        <KeyboardDatePicker
                            disableToolbar
                            variant="inline"
                            label={filter.nameDisplay ? filter.nameDisplay : filter.name}
                            format={"dd/MM/yyyy"}
                            value={getValueFilter(filter.name)}
                            onChange={onChangeFilterDateTo(filter.name, filter.nameProperty, filter.nameDisplay, filter.operator, filter.typeData)}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}

                            InputProps={{
                                startAdornment: (
                                        <CloseIcon onClick={() => clearFilter(filter.name)}/>
                                )
                            }}
                        />
                </MuiPickersUtilsProvider>
            </div>
        }
        if (filter.typeField === "SelectSearchProduct") {
            let searchFilter = getFilter(filter.name)

            return <AutocompleteSearchProduct
                className={"product_statistic_search"}
                disableCloseOnSelect={true}
                style={{width:"33vw"}}
                label={filter.nameDisplay ? filter.nameDisplay : filter.name}
                onChange={(obj) => {
                    if (obj.value) {
                        obj.value = obj.value.map((val) => val.id)
                    }
                    onChangeFilter(obj)
                }}
                onChangeParam={{
                    name: filter.name,
                    nameProperty: filter.nameProperty,
                    nameDisplay: filter.nameDisplay,
                    operator: filter.operator,
                    typeData: filter.typeData
                }}
            />
        }
    }

    const renderFilters = () => {
        return <div style={{
            display:"flex",
            flexDirection:direction
        }} className={"flex product_statistic_conteiner_for_filters"}>
                    {Array.isArray(filters) && filters.map(filter => {
                        return <Fragment>
                            {renderFilter(filter)}

                        </Fragment>
                    })}
                </div>
    }

    return (<Fragment>
            {showFilters !== false && filters && filters.length > 0 &&
            <Accordion className={"products_filters"}
                       style={{flexGrow: 1, width: "min-content", marginTop: "4px", marginRight: "3px"}}
                       expanded={productFiltersAccordionOpen}
                       onChange={(event, newExpanded) => {
                           setProductFiltersAccordionOpen(newExpanded ? true : false);
                       }}
            >
                <div className={"product_filter"}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon/>}
                        aria-controls="panel1a-content"
                        className={"white_svg"}
                    >
                        <div className={"flex"}>
                            <Typography style={{fontSize: "50px"}}>
                                Фильтры:
                            </Typography>
                        </div>
                    </AccordionSummary>
                </div>
                <AccordionDetails>
                    <div className={"products_filters_menu"}>
                        <div className="divider"/>
                        {renderFilters()}
                    </div>
                </AccordionDetails>
            </Accordion>
            }
        </Fragment>
    )
}