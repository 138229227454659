import React from 'react';
import AccordionMaterial from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Box from "@mui/material/Box";
import Cell from "./Cell";
import Factory from "../../../components/Factory";
import "./style.css";
import ButtonLaunch from "../../../element/Button/ButtonLaunch";
import ButtonEdit from "../../../element/Button/ButtonEdit";
import TableRow from "../TableRow";

const Accordion = (props) => {
    const {header, row, component, componentProps, index, onChange} = props

    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };


    const renderDetails = (component, expended) => {
        return <Factory
            component={component}
            componentProps={componentProps}
            isLoad={expended}
            {...row}
        />
    }

    return (
        <AccordionMaterial className={
                               index ? index % 2 === 1 ? "Accordion_In_Table_Dark" : "Accordion_In_Table_Light" : "Accordion_In_Table_Light"
                           }
            expanded={expanded === true}
            onChange={handleChange(true)}
        >
            <AccordionSummary

                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <TableRow
                    header={header}
                    index={index}
                    row={row}
                    onChange={onChange}
                />
                {/*<Box sx={{*/}
                {/*    display:"flex",*/}
                {/*    width:"100%",*/}
                {/*    gap:"10px"*/}
                {/*}}>*/}
                {/*    {header && header.cells && header.cells.map(cell => {*/}
                {/*        // console.log(cell, "= Cell")*/}
                {/*        return <Cell*/}
                {/*            {...cell}*/}
                {/*            indexRow={index}*/}
                {/*            row={row}*/}
                {/*            onChange={onChange}*/}
                {/*        />*/}
                {/*    })}*/}
                {/*</Box>*/}
            </AccordionSummary>
            <AccordionDetails>
                {renderDetails(component, expanded)}
                {/*<div style={{borderTop:"1px dashed black", marginTop:"20px"}}>*/}
                {/*    <p  style={{marginTop:"20px"}}>*/}
                {/*        Заказ наряд открытый, если есть</p>*/}
                {/*<ul>*/}
                {/*    <li>История заказ нарядов</li>*/}
                {/*    <li>История баланса</li>*/}
                {/*    <li>Данные по авто</li>*/}
                {/*    <li>Другие документы</li>*/}
                {/*</ul>*/}
                {/*    <div style={{display: "flex", justifyContent: "end"}}>*/}
                {/*         <ButtonLaunch*/}
                {/*            color={"primary"}*/}
                {/*            size={"medium"}*/}
                {/*            // onClick={goToFullScreen}*/}
                {/*        />*/}
                {/*        <ButtonEdit*/}
                {/*            color={"primary"}*/}
                {/*            size={"medium"}*/}
                {/*            // onClick={goToRedactor}*/}
                {/*        />*/}
                {/*    </div>*/}
                {/*</div>*/}
            </AccordionDetails>
        </AccordionMaterial>
    );
};

export default Accordion;