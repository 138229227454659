import React from 'react';
import PropTypes from 'prop-types';
import Box from "../../../../element/Box";
import TextFieldNumber from "../../../../element/TextField/TextFieldNumber";

const ShopComponent = props => {
    const {cell, shopData = {}, onChangeShopData} = props
    const {value, count = 0, id} = shopData

    const onChangeCount = (newCount) => {
        onChangeShopData && onChangeShopData(shopData, cell, newCount)
    }

    return (
        <Box>
           <TextFieldNumber value={count}
                            sx={{
                                width:"60px"
                            }}
                            onChange={onChangeCount}
                            isRenderButtonSub={(value) => value > 0}
                            isRenderTextField={(value) => value > 0}
           />
        </Box>
    );
};

ShopComponent.propTypes = {

};

export default ShopComponent;