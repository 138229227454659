import React from 'react';
import {Box} from "@mui/material";
import Typography from "@mui/material/Typography";
import Tooltip from "../../../../element/Tooltip";

const HeaderCell = (props) => {
    const {width, flexGrow, fullValue ,value, type} = props

    const renderCell = (type, value, fullValue) => {
        switch (type) {
            default:
                if (fullValue) {
                    return <Tooltip title={fullValue}>
                        <Typography>
                            {value}
                        </Typography>
                    </Tooltip>
                } else {
                    return <Typography>
                        {value}
                    </Typography>
                }
        }
    }

    return (
        <Box sx={{
            width: width ? width : "",
            flexGrow: flexGrow ? flexGrow : "1",
            display:"flex",
            justifyContent:"center",
            alignItems:"center"
        }}>
            {renderCell(type, value, fullValue)}
        </Box>
    );
};

export default HeaderCell;