import React from 'react';
import Header from "./Header";
import Divider from "@material-ui/core/Divider";
import "./style.css"
import TableRow from "./TableRow";

const TableClient = (props) => {
    const {
        header, data, onChange,
    } = props

    const renderRow = (objectData, index) => {
        return <TableRow
                index={index}
                row={objectData}
                header={header}
                onChange={onChange}
            />
    }

    return (
        <div>
            <Header header={header}/>
            {data && data.map((objectData, index) => {
                    return <div>
                        {
                            renderRow(objectData, index)
                        }
                        <Divider style={{background: "rgba(255,255,255,0.6)"}}/>
                    </div>
                })
            }
        </div>
    );
}

export default TableClient;