import React from 'react';
import ClientOrder from "../PrivateOffice/ClientOrders/ClientOrder";
import Typography from "@mui/material/Typography";
import TextField from "../../elements/TextField";
import Client from "../PrivateOffice/Clients/Client";
import ClientOrderActionByOrderAndCategoryId
    from "../PrivateOffice/ClientOrders/ClientOrderActionByOrder/ClientOrderActionByOrderAndType";

const Factory = (props) => {
    const {component, componentProps, ...other}= props
    switch (component) {
        case "ClientOrder":
            return <ClientOrder
                {...other}
                {...componentProps}
            />
        case "Client":
            return <Client
                {...other}
                {...componentProps}
                />
        case "TextField":
            return <TextField
                {...other}
                {...componentProps}
            />
        case "ClientOrderActionByOrderAndCategoryId":
            return <ClientOrderActionByOrderAndCategoryId
                {...other}
                {...componentProps}
            />
        default:
            return <Typography
                {...other}
                {...componentProps}
            >
                {props.children}
            </Typography>

    }
};

export default Factory;