export const ADD_EMPLOYEE = 'ADD_EMPLOYEE'
export const UPDATE_EMPLOYEE = 'UPDATE_EMPLOYEE'
export const UPDATE_SITE = 'UPDATE_SITE'
export const UPDATE_CATALOG = 'UPDATE_CATALOG'
export const UPDATE_CATEGORY = 'UPDATE_CATEGORY'
export const UPDATE_PRODUCT = 'UPDATE_PRODUCT'
export const UPDATE_MENU = 'UPDATE_MENU'
export const GET_STATISTICS = 'GET_STATISTICS'
export const UPDATE_CURRENCY = 'UPDATE_CURRENCY'

export const GET_ALL_CLIENT_ORDER = 'GET_ALL_CLIENT_ORDER'
export const GET_SMALL_ALL_CLIENT_ORDER = 'GET_SMALL_ALL_CLIENT_ORDER'
export const GET_MY_CLIENT_ORDER = 'GET_MY_CLIENT_ORDER'
export const ADD_CLIENT_ORDER = 'ADD_CLIENT_ORDER'
export const UPDATE_CLIENT_ORDER = 'UPDATE_CLIENT_ORDER'


export const GET_ALL_CLIENT = 'GET_ALL_CLIENT'
export const ADD_CLIENT = 'ADD_CLIENT'
export const UPDATE_CLIENT = 'UPDATE_CLIENT'

export const PRODUCT_VIEW_START_BUE_PRICE = 'PRODUCT_VIEW_START_BUE_PRICE'
export const PRODUCT_VIEW_PLACE= 'PRODUCT_VIEW_PLACE'
