import React, {useState} from 'react';
import PropTypes from 'prop-types';
import Modal from "../../../../element/Modal";
import TextField from "../../../../element/TextField";
import AccessRedactor from "../AccessesRedactor";
import Box from "../../../../element/Box";
import {addEmployee, updateEmployee} from "../../../../Rest/RestFunction";
import {useSelector} from "react-redux";
import useSignal from "../../../../hooks/useSignal";

const EmployeeCreator = props => {
    const user = useSelector((state) => state.auth.user)
    const [employee, setEmployee] = useState({})
    const [signal, sendSignal] = useSignal("employee.list.reload")

    const onChange = (name, value) => {
        setEmployee(prevState => {
            return {
                ...prevState,
                [name]: value
            }
        })
    }

    const onClickAddEmployee = async () => {
        await addEmployee({
            tokenAuth: user.tokenAuth,
            employee:employee
        })
        setEmployee({})
        sendSignal()
    }

    const {login, password, firstName, lastName, secondName, accesses = []} = employee

    return (
        <Box sx={{
            display:"flex"
        }}>
        <Modal
            label={"Новый работник"}
            onSubmit={onClickAddEmployee}
        >
            <Box sx={{
                display:"flex",
                flexDirection:"column",
                gap:"1rem",
            }}>
                <TextField
                    label="Логин"
                    value={login}
                    onChange={(e) => onChange("login", e.target.value)}
                />
                <TextField
                    label="Пароль"
                    value={password}
                    onChange={(e) => onChange("password", e.target.value)}
                />
                <TextField
                    label="Имя"
                    value={firstName}
                    onChange={(e) => onChange("firstName", e.target.value)}
                />
                <TextField
                    label="Фамилия"
                    value={lastName}
                    onChange={(e) => onChange("lastName", e.target.value)}
                />
                <TextField
                    label="Отчество"
                    value={secondName}
                    onChange={(e) => onChange("secondName", e.target.value)}
                />
                <Modal
                    label="Права доступа"
                >
                    <AccessRedactor accessEmployee={accesses} onChange={(newValue) => onChange("accesses", newValue)}/>
                </Modal>
            </Box>
        </Modal>
        </Box>
    );
};

EmployeeCreator.propTypes = {

};

export default EmployeeCreator;