import React, {Fragment} from 'react';
import AppBar from "@material-ui/core/AppBar";
import TabsMaterial from "@material-ui/core/Tabs";
import SwipeableViews from "react-swipeable-views";
import {withStyles} from "@material-ui/core/styles";
import TabMaterial from "@material-ui/core/Tab";
import Divider from "@mui/material/Divider";

const TabStyled = withStyles((theme) => ({
    root: {
        textTransform: "none",
        fontSize: "1.275rem",
    },
}))(TabMaterial);

const TabsOffice = (props) => {
    const {
        header, //Верхие элементы для выбора таба. Обязательно передавать
        children, //Сами табы, контент внутри вкладок.
        value,
        setValue
    } = props

    function a11yProps(index) {
        return {
            id: `scrollable-auto-tab-${index}`,
            'aria-controls': `scrollable-auto-tabpanel-${index}`,
        };
    }

    return (
        <Fragment>
            <AppBar position="static" color="default" style={{borderRadius:"5px",padding:"5px"}}>
                <TabsMaterial
                    value={value}
                    indicatorColor=""
                    textColor="primary"
                    onChange={(event, newValue) => {
                        setValue(newValue)
                    }}
                    scrollButtons="auto"
                    variant="scrollable"
                >
                    {header.map((element, index) => {
                        return <TabStyled style={{minWidth: "fit-content", marginRight: "20px", padding: "0"}}
                                          // className={`${value === index && "selected_Elem"}`}
                                          label={element.label}
                                          {...a11yProps(index)}
                        />
                    })}
                </TabsMaterial>
            </AppBar>
            <Divider/>
            <article style={{
                borderRadius: "5px",
                padding: "5px",
                marginTop: "10px",
                minHeight: "310px"
            }}>
                <SwipeableViews
                    index={value}
                    onChangeIndex={(indexTab)=>{setValue(indexTab)}}
                >
                    {children}
                </SwipeableViews>
            </article>
        </Fragment>
    );
};

export default TabsOffice;