import React, {Fragment} from 'react';
import {withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import "./styleModalWindow.css"

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
        minWidth:"400px",
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
        margin: "-15px -5px",
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const {children, classes, onClose, ...other} = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon/>
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        width: "100%",
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

export default function ModalWindowSizeFree(props) {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Fragment>
            {props.startComponent && <div onClick={handleClickOpen}>
                {props.startComponent}
            </div>
            }
            <Dialog className={"modal_window"}  onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} maxWidth="90vw"
                    fullScreen={false}>

                <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                </DialogTitle>
                <div className="dialogWindow">
                    <DialogContent dividers>
                        {props.children}
                    </DialogContent>
                    {props.actions &&
                    <DialogActions>
                        {props.actions.map(element => {
                            return (<div onClick={handleClose}>
                                {element}
                            </div>)
                        })}
                    </DialogActions>
                    }
                </div>
            </Dialog>
        </Fragment>
    );
}