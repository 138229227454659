import React, {Fragment, useState} from 'react';
import './style.css'
import {getCatalogsListByIds, getCategoryById, getFile} from "../../../Rest/RestFunction";
import ModalWindowSizeFree from "../../../elements/ModalWindowSizeFree";
import Typography from "@material-ui/core/Typography";
import GridImage from "../../../elements/GridImage";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import {withStyles} from "@material-ui/core/styles";
import MuiDialogContent from "@material-ui/core/DialogContent";
import SwipeableViews from 'react-swipeable-views';
import makeStyles from "@material-ui/core/styles/makeStyles";
import * as PropTypes from "expect";
import Box from "@material-ui/core/Box";
import TableForPrimenimost from "../../TableForPrimenimost";
import TypographyDate from "../../../elements/TypographyDate";
import {useSelector} from "react-redux";
import {useAccessUser} from "../../../service/employees/useAccessUser";
import {PRODUCT_VIEW_PLACE, PRODUCT_VIEW_START_BUE_PRICE, UPDATE_CLIENT} from "../../../constants/accesses";
import ProductPriceHistory from "./ProductPriceHistory";
import Modal from "../../../element/Modal";
import {getMoneyString} from "../../../utils/commonUtils";
import {createTheme, ThemeProvider} from "@mui/material/styles";

const TabStyled = withStyles((theme) => ({
    root: {
        textTransform: "none",
        fontSize: "1.275rem",

    },
}))(Tab);

function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <Box
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3} style={{padding: "0"}} {...other}>
                    {children}
                </Box>
            )}
        </Box>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        width: 500,
    },
}));

export default function ProductModalView(props) {

    const product = props.product

    const [tab, setTab] = useState()
    const [value, setValue] = React.useState(0);
    const [category, setCategory] = useState()
    const [productPrimenimostCatalogList, setProductPrimenimostCatalogList] = useState()
    //Список родителей, например: Mazda, BMW
    const [parentPrimenimostCatalogList, setParentPrimenimostCatalogList] = useState()
    const user = useSelector((state) => state.auth.user);
    const isProductViewStartBue = useAccessUser(PRODUCT_VIEW_START_BUE_PRICE, user)
    const isProductViewPlace = useAccessUser(PRODUCT_VIEW_PLACE, user)

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const initCategory = async (categoryId) => {
        if (categoryId) {
            let cat = await getCategoryById(categoryId)
            setCategory(cat)
        } else {
            setCategory(null)
        }
    }

    const initProductPrimenimostCatalogList = async (catalogList) => {
        if (catalogList) {
            let fullCatalogList = await getCatalogsListByIds(catalogList)

            let parentCatalogs = []
            fullCatalogList.forEach((catalog) => {
                let indexParent = -1
                parentCatalogs.forEach((parent, index) => {
                    if ((!catalog.level && parent.id === catalog.parentId) ||
                        (catalog.level >= 2 && parent.id === catalog.parentId) ||
                        (catalog.level === 1 && parent.id === catalog.id)) {
                        indexParent = index
                    }
                })
                if (indexParent !== -1) {
                    parentCatalogs[indexParent].children.push(catalog)
                } else {
                    if (!catalog.level || (catalog.level && catalog.level >= 2)) {
                        parentCatalogs.push({
                            id: catalog.parentId,
                            name: catalog.parentName,
                            children: [{...catalog}]
                        })
                    } else {
                        parentCatalogs.push({
                            id: catalog.id,
                            name: catalog.name,
                            children: []
                        })
                    }
                }
            })
            setParentPrimenimostCatalogList(parentCatalogs)


            setProductPrimenimostCatalogList(fullCatalogList)
        }
    }

    React.useEffect(() => {
        initCategory(product.categoryId)
        initProductPrimenimostCatalogList(product.catalogList)
    }, []);

    const renderParam = (name, value) => {
        return (
            <div style={{display: "flex"}}>
                <div className={"product_modal_view_param_element_name"}>
                    <article>{name}</article>
                    <div className={"product_modal_view_param_element_dots"}></div>
                </div>
                <article>{value}</article>
            </div>
        )
    }
    const renderPriceStart = (productCountInfoList) => {
        if (productCountInfoList) {
            let activePrice = [];
            productCountInfoList.map((el) => {
                if (el.endCount > 0) {
                    activePrice.push(el)
                }
            })
            if (activePrice.length > 0) {
                return (
                    <div style={{display: "flex"}}>
                        <div className={"product_modal_view_param_element_name"}>
                            <article>Закупочная цена</article>
                            <div className={"product_modal_view_param_element_dots"}></div>
                        </div>
                        <article>{`${getMoneyString(activePrice[0].priceCurrency)} (${activePrice[0].endCount} шт.)`}</article>
                    </div>
                )
            }
        }
    }

    const renderParamArray = (name, value = []) => {
        return (
            <div style={{display: "flex"}}>
                <div className={"product_modal_view_param_element_name"}>
                    <article>{name}</article>
                    <div className={"product_modal_view_param_element_dots"}></div>
                </div>
                <article>{value.join(', ')}</article>
            </div>
        )
    }

    const renderDopParams = (product) => {
        if (product.dopParams) {
            return category && category.dopParams && category.dopParams.map((param) => {
                if (product.dopParams[param.name]) {
                    return renderParam(param.displayName ? param.displayName : param.name, product.dopParams[param.name])
                }
            })
        }
    }

    const [valueParent, setValueParent] = React.useState(0);
    const handleChangeParent = (event, newValue) => {
        setValueParent(newValue);
        setValueChildren(0)
    };

    const renderPrimenimost = () => {
        return parentPrimenimostCatalogList && parentPrimenimostCatalogList.length > 1 ?
            <article>
                <Tabs
                    value={valueParent}
                    indicatorColor=""
                    textColor="primary"
                    onChange={handleChangeParent}
                >
                    {
                        parentPrimenimostCatalogList && parentPrimenimostCatalogList.map((parent, index) => {
                            return <TabStyled style={{minWidth: "fit-content", marginRight: "20px", padding: "0"}}
                                              className={`${valueParent === index && "selected_Elem"}`}
                                              label={parent.name}
                                              {...a11yProps(index)}
                            />
                        })
                    }
                </Tabs>
                <SwipeableViews
                    index={valueParent}
                >
                    {
                        parentPrimenimostCatalogList && parentPrimenimostCatalogList.map((parent, index) => {
                            return <TabPanel value={valueParent} index={index}>
                                {renderPrimenimostChildren(parent)}
                            </TabPanel>
                        })
                    }

                </SwipeableViews>

            </article> :
            // показывать если только один родитель
            parentPrimenimostCatalogList && parentPrimenimostCatalogList.map((parent, index) => {
                return renderPrimenimostChildren(parent)
            })

    }

    const [valueChildren, setValueChildren] = React.useState(0);
    const handleChangeChildren = (event, newValue) => {
        setValueChildren(newValue);
    };

    const renderPrimenimostChildren = (parent) => {
        if (parent.children && parent.children.length > 0) {
            return <Fragment>
                <Tabs
                    value={valueChildren}
                    indicatorColor=""
                    textColor="primary"
                    onChange={handleChangeChildren}
                >
                    {
                        parent && parent.children && parent.children.map((child, index) => {
                            let plusNameParentName = child.parentName + " " + child.name
                            return <TabStyled
                                style={{minWidth: "fit-content", marginRight: "20px", padding: "0", fontSize: "15px"}}
                                className={`${valueChildren === index && "selected_Elem"}`}
                                label={plusNameParentName}
                                {...a11yProps(index)}
                            />
                        })
                    }
                </Tabs>
                <SwipeableViews className="body_Style_Param"
                                index={valueChildren}
                >
                    {
                        parent && parent.children && parent.children.map((children, index) => {
                            return <TabPanel value={valueChildren} index={index}>

                                <div className={"product_modal_view__table"} style={{paddingLeft: "0px"}}>

                                    <TableForPrimenimost children={children}/>

                                </div>


                            </TabPanel>
                        })
                    }

                </SwipeableViews>
            </Fragment>
        } else {
            return <Box sx={{
                display: "flex",
                justifyContent: "center",
                padding: "4rem 1rem"
            }}>
                <Typography variant={"h5"}>
                    Все модели
                </Typography>
            </Box>
        }
    }


    return (
        <Box sx={{
            width: "950px",
            height: "680px",
            color: "white",
            display: "flex",
            flexDirection:"column"
        }}>
            <div>
                <article style={{marginBottom: "25px"}}>
                    <h3> {product.brand} {product.article}</h3>
                    <h5>{product.name}</h5>
                </article>
                <GridImage images={product.images}/>
            </div>

            <Box
                sx={{
                    flex: "2 2 100px",
                    marginTop: "30px",
                    display:"flex",
                    flexDirection:"column",
                }}
                className={"Product_Modal_Info"}
            >
                <article style={{backgroundColor: "rgba(30,30,30,0.95)", borderRadius: "5px", padding: "5px"}}>
                    <Tabs
                        value={value}
                        indicatorColor=""
                        textColor="primary"
                        onChange={handleChange}
                    >

                        <TabStyled style={{minWidth: "fit-content", marginRight: "20px", padding: "0"}}
                                   className={`${value === 0 && "selected_Elem"}`}
                                   label="Параметры"  {...a11yProps(0)}/>
                        <TabStyled style={{minWidth: "fit-content", padding: "0"}}
                                   className={`${value === 1 && "selected_Elem"}`}
                                   label="Применимость"  {...a11yProps(1)}/>
                    </Tabs>
                </article>

                <SwipeableViews
                    index={value}
                    style={{
                        height:"100%"
                    }}
                    containerStyle={{
                        height:"100%"
                    }}
                    slideStyle={{
                        height:"100%",
                        display:"flex",
                    }}
                >
                    <TabPanel
                        value={value}
                        index={0}
                        sx={{
                            display:"flex",
                            flex: "2 2 100px",
                            flexDirection:"column"
                        }}
                    >
                        <article style={{
                            backgroundColor: "rgba(30,30,30,0.95)",
                            borderRadius: "5px",
                            padding: "5px",
                            marginTop:"10px",
                            //minHeight: "311px",
                            display:"flex",
                            flexDirection:"column",
                            justifyContent:"space-between",
                            flex:"2 2 100px"
                        }}>
                            <div>
                                {product.country && renderParam("Производитель", product.brand)}
                                {product.description && renderParam("Описание", product.description)}
                                {product.country && renderParam("Страна производства", product.country)}
                                {renderDopParams(product)}
                                {user.tokenAuth && product.productCountInfoList && renderPriceStart(product.productCountInfoList)}
                                {user.tokenAuth && isProductViewPlace && product.locations && product.locations.length > 0 ?
                                    renderParamArray("Местоположение", product.locations) : null}
                            </div>
                            <div>
                                {user.tokenAuth && isProductViewStartBue && product && product.productCountInfoList &&
                                Array.isArray(product.productCountInfoList) &&
                                <Modal
                                    label={"История цены"}
                                    theme={"dark"}
                                >
                                    <ProductPriceHistory productCountInfoList={product.productCountInfoList}/>
                                </Modal>
                                }
                            </div>
                        </article>
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <article style={{
                            backgroundColor: "rgba(30,30,30,0.95)",
                            borderRadius: "5px",
                            padding: "5px",
                            marginTop: "10px",
                            //minHeight: "311px",
                            flex:"2 2 100px",
                        }}>
                            {renderPrimenimost()}
                        </article>
                    </TabPanel>
                </SwipeableViews>
            </Box>

            <div>
                {tab}
            </div>

        </Box>
    );
}