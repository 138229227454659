import React from 'react';
import {useParams} from "react-router-dom"
import ClientOrder from "../index";

const ClientOrderWithPathId = () => {
    const { id } = useParams()
    return (
        <ClientOrder id={id} isLoad={true}/>
    );
};

export default ClientOrderWithPathId;