import React from 'react';
import AutocompleteMaterial from '@mui/material/Autocomplete';
import TextField from "../TextField";
import Box from "../Box";

export default function Autocomplete(props) {
    const {value, label, options = [], onChange, onChangeTextField, disableClearable, freeSolo, valueTextField,
        renderOption=(props, option) => (
            <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                {option}
            </Box>
        ),
        ...other} = props

    return (
        <AutocompleteMaterial
            id="autocomplete"
            getOptionLabel={(option) => option.name}
            sx={{width: 300}}
            disableClearable={disableClearable}
            freeSolo={freeSolo}
            onInputChange={onChangeTextField}
            inputValue={valueTextField}
            renderInput={(params) => {
                return <TextField
                    {...params}
                    label={label ? label : "Поиск"}
                />
            }}
            renderOption={renderOption}
            value={value}
            onChange={onChange}
            options={options}
            clearText={"Очистить"}
            {...other}
        />
    );
}