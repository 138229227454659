import React, {useState} from 'react';
import Box from "../../../element/Box";
import CommentList from "./CommentList";
import TextField from "../../../element/TextField";
import ButtonSend from "../../../element/Button/ButtonSend";
import {useDispatch, useSelector} from "react-redux";
import {addComment} from "../../../Rest/RestFunction";
import {addSignal} from "../../../store/signal/action";
import Divider from "@mui/material/Divider";
import useSignal from "../../../hooks/useSignal";

const Comments = (props) => {
    const {comments, typeEntity, id, } = props
    const [text, setText] = useState("")
    const user = useSelector((state) => state.auth.user);
    const [signalUpdateEntityWithComments, sendSignalUpdateEntityWithComments] = useSignal(id)

    const onSendMessage = async () => {
        await addComment({
            id:id,
            typeEntity:typeEntity,
            tokenAuth:user.tokenAuth,
            comment: {
                text:text
            }
        })
        sendSignalUpdateEntityWithComments(id)
        setText("")
    }

    return (
        <Box sx={{
            display:"flex",
            flexDirection:"column",
            minWidth:"300px",
            gap:"0.5rem",
            border:"1px solid rgba(70,70,70,0.2)",
            borderRadius:"10px",
            margin:"0.25rem",
            padding:"0.25rem"
        }}>
            <CommentList comments ={comments} />
            <Divider/>
            <Box sx={{
                display:"flex"
            }}>
            <TextField
                value={text}
                onChange={(e)=> setText(e.target.value)}
                label={"Комментарий"}
                sx={{
                    width:"100%"
                }}
            />
            <ButtonSend
                onClick={onSendMessage}
                size={"medium"}
            />
            </Box>
        </Box>
    );
};

export default Comments;