import React from 'react';
import ButtonMaterial from '@mui/material/Button';

const Button = (props) => {
    return (
        <ButtonMaterial
            variant="outlined"
            {...props}
        >
            {props.children}
        </ButtonMaterial>
    );
};

export default Button;