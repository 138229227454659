import React, {Fragment, useState} from 'react';
import {getFile} from "../../Rest/RestFunction";
import ModalWindowSizeFree from "../ModalWindowSizeFree";
import ProductModalView from "../../components/Product/ProductModalView";
import Fab from "@material-ui/core/Fab";
import EditIcon from "@material-ui/icons/Edit";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ModalImages from "../ModalImages";
import './style.css'
import noImage from './../../static/noImage.png'
import {Link} from "react-router-dom";
import ImagesBox from "../ImagesBox";

export default function GridImage(props) {
    const images = props.images

    const getImage = (guid) => {
        return getFile(guid);
    }

    return (
        <div className={"grid_image_body"}>
            {images && images.length > 0 ?
                    <ImagesBox images={images} index={0}>
                        <img className={"pointer"} src={getImage(images[0].url)} style={{ height: "186px"}}/>
                    </ImagesBox> :
                <img src={noImage} style={{width: "250px", height: "186px"}}/>
            }
            <div className={"grid_image_second"}>
                {images && images.map((image, index) => {
                        if (index >= 1)
                            return (
                                        <ImagesBox images={images} index={index}>
                                            <img src={getImage(image.url)} className={`grid_image_second_element pointer ${index % 2 === 1 ?
                                                "grid_image_second_top" : "grid_image_second_bottom" }`}/>
                                        </ImagesBox>
                            )
                    }
                )}
            </div>
        </div>

    );
}