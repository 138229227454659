import React from 'react';
import SettingsIcon from '@mui/icons-material/Settings';
import IconButton from "@mui/material/IconButton";

const ButtonSettings = (props) => {
    return (
        <IconButton
            aria-label="delete"
            size="small"
            {...props}
        >
            <SettingsIcon fontSize="inherit"/>
        </IconButton>
    );
};

export default ButtonSettings;