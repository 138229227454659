import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useSelector} from "react-redux";
import {getCategoryById, searchProduct, updateAllProduct} from "../../../Rest/RestFunction";
import _ from "lodash";
import Modal from "../../../element/Modal";
import Switch from "../../../element/Switch";
import Table from "../../../elements/Table";
import TableClient from "../../../elements/TableClient";

ProductTableView.propTypes = {
    categoryId: PropTypes.string
};

export default function ProductTableView(props) {
    const {categoryId, byClient = true, transportId, byShop= false, onChangeShopData, clientOrderActionList} = props
    const [category, setCategory] = useState()
    const [paginationProduct, setPaginationProduct] = useState({size: 1000, page: 0})
    const [productPage, setProductPage] = useState()
    const [arrayProductWithClass, setArrayProductWithClass] = useState([])
    const [headerList, setHeaderList] = useState([])

    useEffect(() => {
        init()
    }, [])

    const init = async () => {
        const [categoryResponse, productResponse] = await Promise.all([
            getCategoryById(categoryId),
            searchProduct({
                categoryId: categoryId,
                page: paginationProduct.page,
                size: paginationProduct.size,
                sorts:[
                    {
                        typeSort:"ASC",
                        name:"index"
                    }
                ]
            })])
        setCategory(categoryResponse)
        setProductPage(productResponse)

        let isFindTransportId = false
        let idFindClassTransportList = []
        if (transportId) {
            if (categoryResponse.classTransportList) {
                categoryResponse.classTransportList.forEach((classTransport) => {
                    classTransport.transportList.forEach(transportCatalogElement => {
                        if (transportCatalogElement.id === transportId) {
                            idFindClassTransportList.push(classTransport.id)
                            isFindTransportId = true
                        }
                    })
                })
            }
        }
        const arrayResult = [...productResponse.content]
        if (isFindTransportId === true) {
            arrayResult.forEach(product => {
                if (!product['priceMap']) {
                    product['priceMap'] = {}
                }
                if (categoryResponse.classTransportList) {
                    categoryResponse.classTransportList.filter((classTransport) => {
                        return idFindClassTransportList.includes(classTransport.id)
                    }).forEach(classTransport => {
                        if (!product.priceMap[classTransport.id]) {
                            product.priceMap[classTransport.id] = {}
                        }
                        product.priceMap[classTransport.id]["name"] = `${classTransport.name}`
                        product.priceMap[classTransport.id]["shopData"] = {
                            productId:product.id,
                            classTransportId: classTransport.id,
                        }

                    })
                }
            })
            setArrayProductWithClass(arrayResult)

            const header = [{
                flexGrow: "2",
                value: "Наименование",
                name: "name",
                justifyContentHeader: "center",
                justifyContentElement: "flex-start",
                byShop:false
            }]

            if (categoryResponse.classTransportList) {
                categoryResponse.classTransportList.filter((classTransport) => {
                        return idFindClassTransportList.includes(classTransport.id)
                    })
                    .forEach(classTransport => {
                    header.push({
                        flexGrow: "1",
                        fullValue: `${classTransport.group} -> ${classTransport.name}`,
                        value: `${classTransport.name}`,
                        name: `priceMap.${classTransport.id}.priceCurrency`
                    })
                })
            }

            setHeaderList(header)
        } else {
            arrayResult.forEach(product => {
                if (!product['priceMap']) {
                    product['priceMap'] = {}
                }
                if (categoryResponse.classTransportList) {
                    categoryResponse.classTransportList.forEach(classTransport => {
                        if (!product.priceMap[classTransport.id]) {
                            product.priceMap[classTransport.id] = {}
                        }
                        product.priceMap[classTransport.id]["name"] = `${classTransport.name}`
                        product.priceMap[classTransport.id]["shopData"] = {
                            productId:product.id,
                            classTransportId: classTransport.id,
                        }
                    })
                }
            })
            setArrayProductWithClass(arrayResult)

            const header = [{
                flexGrow: "2",
                value: "Наименование",
                name: "name",
                justifyContentHeader: "center",
                justifyContentElement: "flex-start",
                byShop:false
            }]

            if (categoryResponse.classTransportList) {
                categoryResponse.classTransportList.forEach(classTransport => {
                    header.push({
                        flexGrow: "1",
                        fullValue: `${classTransport.group} -> ${classTransport.name}`,
                        value: `${classTransport.name}`,
                        name: `priceMap.${classTransport.id}.priceCurrency`
                    })
                })
            }

            setHeaderList(header)
        }
        enrichTableByClientOrderActionList(arrayResult)
    }

    useEffect(() => {
        enrichTableByClientOrderActionList(arrayProductWithClass)
    },  [clientOrderActionList])

    const enrichTableByClientOrderActionList = (arrayProduct) => {
        console.log("Обогащение старт ", arrayProduct)
        let clientOrderActionMap = {}

        if (clientOrderActionList) {
            clientOrderActionList.forEach(clientOrderAction => {
                if (clientOrderAction.classTransportId) {
                    clientOrderActionMap[clientOrderAction.productId] = clientOrderActionMap[clientOrderAction.productId] ? clientOrderActionMap[clientOrderAction.productId] : {}
                    clientOrderActionMap[clientOrderAction.productId][clientOrderAction.classTransportId] = clientOrderAction
                } else {
                    clientOrderActionMap[clientOrderAction.productId] = clientOrderAction
                }
            })
        }

        console.log(clientOrderActionMap)

        const arrayResult = [...arrayProduct]
        arrayResult.forEach(product => {
            if (!product['priceMap']) {
                product['priceMap'] = {}
            }

            for (let priceKey in product.priceMap) {
                let shopData = product.priceMap[priceKey].shopData
                if (shopData) {
                    if (shopData.classTransportId) {
                        if (clientOrderActionMap[shopData.productId] && clientOrderActionMap[shopData.productId][shopData.classTransportId]) {
                            shopData.count = clientOrderActionMap[shopData.productId][shopData.classTransportId].count
                            shopData.id = clientOrderActionMap[shopData.productId][shopData.classTransportId].id
                        }
                    } else {
                        if (clientOrderActionMap[shopData.productId]) {
                            shopData.count = clientOrderActionMap[shopData.productId].count
                            shopData.id = clientOrderActionMap[shopData.productId].id
                        }
                    }
                }
            }
        })
        console.log("Обогащение финиш ", arrayResult)
        setArrayProductWithClass(arrayResult)
    }

    return (
            byClient === true ?
            <TableClient
                header={{
                    cells:headerList
                }}
                data={arrayProductWithClass}
            />
            :
            <Table
                header={{
                    cells:headerList
                }}
                data={arrayProductWithClass}
                byShop={byShop}
                onChangeShopData={onChangeShopData}
            />
    );
};