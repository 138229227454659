import React, {Fragment, useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {getClientOrderById, searchTransport, updateClientOrder} from "../../../../Rest/RestFunction";
import * as NotificationAction from "../../../../store/notifications/actions";
import Modal from "../../../../element/Modal";
import ClientRedactor from "../../Clients/ClientRedactor";
import {Button} from "@mui/material";
import {getFilterObjectIdIs} from "../../../../helpers/FilterHelper";
import ViewTransport from "../ViewTransport";
import Box from "../../../../element/Box";
import TransportRedactor from "../TransportRedactor";

const TransportClientRedactor = (props) => {
    const {clientId, client, setClient, isAddTransportWithClient} = props
    const user = useSelector((state) => state.auth.user);
    const [transportList, setTransportList] = useState([])
    const dispatch = useDispatch()

    useEffect(() => {
        loadTransportByClient(clientId)
    }, [])

    useEffect(() => {
        loadTransportByClient(clientId)
    }, [clientId])

    const loadTransportByClient = async (clientId) => {
        if (clientId) {
            let response = await searchTransport({
                tokenAuth: user.tokenAuth,
                searchParams: {
                    size: 100,
                    page: 0,
                    filters: [
                        getFilterObjectIdIs("clientId", clientId)
                    ]
                }
            })
            if (response) {
                if (response.page) {
                    setTransportList(response.page.content)
                } else {
                    if (response.message) {
                        dispatch(NotificationAction.showNotification("error", response.message))
                    }
                }
            }
        }
    }

    const reloadTransportByClient = () => {
        loadTransportByClient(clientId)
    }


    return (
        <Box>
            {transportList && transportList.length > 0 ?
                <Fragment>
                    <div>
                        Текущие машины клиента
                    </div>
                    <ViewTransport data={transportList}/>
                    <Modal label={"Добавить транспортное средство"}>
                        <TransportRedactor reload={reloadTransportByClient} clientId={clientId}/>
                    </Modal>
                </Fragment> :
                <TransportRedactor
                    clientId={clientId}
                    client={client}
                    setClient={setClient}
                    isAddTransportWithClient={isAddTransportWithClient}
                    reload={reloadTransportByClient}
                />
            }
        </Box>

    );
};

export default TransportClientRedactor;