import React, {useEffect, useState} from 'react';
import ViewClientOrders from "./ViewClientOrders";
import ClientOrdersPanel from "./ClientOrdersPanel";
import Divider from "@mui/material/Divider";
import {getAllRootCategory, searchClientOrders} from "../../../Rest/RestFunction";
import {useSelector} from "react-redux";
import Pagination from "../../../elements/Pagination";
import FiltersClientOrders from "../../../elements/FiltersClientOrders";

const ClientOrders = (props) => {
    const user = useSelector((state) => state.auth.user);
    const [categoryRoot, setCategoryRoot] = useState()
    const [type, setType] = useState("service")
    const [clientOrdersParams, setClientOrdersParams] = useState({})
    const [page, setPage] = useState({})
    const [pagination, setPagination] = useState({
        size: 50,
        page: 0
    })
    const [filters, setFilters] = useState()
    const signalMap = useSelector((state) => state.signal.signalMap);
    const [timeDownload, setTimeDownload] = useState()

    useEffect(() => {
        if (signalMap) {
            if (timeDownload) {
                if (page) {
                    if (page.content) {
                        let isUpdate = false;
                        for (let element of page.content) {
                            let signal = signalMap[element.id];
                            if (signal) {
                                if (timeDownload < signal.time) {
                                    isUpdate = true
                                    break;
                                }
                            }
                        }
                        if (isUpdate) {
                            search()
                        }
                    }
                }
            }
        }
    }, [signalMap])

    useEffect(() => {
        if (filters) {
            search()
        }
    }, [filters, pagination])

    const search = async () => {
        let response = await searchClientOrders({
            tokenAuth: user.tokenAuth,
            searchParams: {
                page: pagination.page,
                size: pagination.size,
                filters: filters
            }
        })
        let root = await getAllRootCategory(type)
        if (root) {
            setCategoryRoot(root)
        }

        if (response) {
            if (response.page) {
                setPage(response.page)
                setTimeDownload(Date.now())
            }
        }
    }

    return (
        <div>
            <ClientOrdersPanel
                params={clientOrdersParams}
                setParams={setClientOrdersParams}
            />
            <Divider/>
            <FiltersClientOrders
                filters={filters}
                setFilters={setFilters}
                categoryRoot={categoryRoot}/>
            <ViewClientOrders
                data={page.content}
                categoryRoot={categoryRoot}
            />
            <Pagination
                size={pagination.size}
                page={pagination.page}
                setPagination={setPagination}
                countElements={page.totalElements}
            />
        </div>
    );
};

export default ClientOrders;