import React from 'react';
import PropTypes from 'prop-types';
import Switch from "../../../../element/Switch";

const EmployeeSearchFilter = props => {
    const {filters = [], setFilters} = props
    return (
        <div>
            {filters && filters.map((filter, index) => {
                switch (filter.typeData) {
                    case "Boolean":
                        return <Switch label={filter.name}
                                       value={filter.value}
                                       onChange={(e) => {
                            setFilters((filters) => {
                                let result = [...filters]
                                result[index].value = e.target.checked
                                return result
                            })
                        }
                        }/>
                }

            })}
        </div>
    );
};

EmployeeSearchFilter.propTypes = {

};

export default EmployeeSearchFilter;