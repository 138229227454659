import React from 'react';
import {Box} from "@mui/material";
import Typography from "@mui/material/Typography";

const HeaderCell = (props) => {
    const {justifyContentHeader = "center", fontFamily="Times New Roman",
        fontSizeHeader="24px",

        width = "", flexGrow = "1", fullValue ,value, type} = props

    const renderCell = (type, value, fullValue) => {
        switch (type) {
            default:
                    return <Typography
                        sx={{
                            fontFamily:"inherit!important",
                            fontWeight:"inherit!important"
                        }}
                    >
                        {value}
                    </Typography>
        }
    }

    return (
        <Box sx={{
            width: width,
            flexGrow: flexGrow,
            display:"flex",
            justifyContent:justifyContentHeader,
            alignItems:"center",
            fontFamily:fontFamily,
            fontSize:fontSizeHeader,
            fontWeight:"900"
        }}>
            {renderCell(type, value, fullValue)}
        </Box>
    );
};

export default HeaderCell;