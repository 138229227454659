import SelectAutocomplete from "../../../../../elements/SelectAutocomplete";
import React, {useEffect, useState} from "react";
import {searchProduct} from "../../../../../Rest/RestFunction";
import Box from "@mui/material/Box";

export default function SearchProductSelectAutocomplete(props) {
    const {value, onChange, type} = props

    const [products, setProducts] = useState([])

    useEffect( () => {
         searchElementComposition("")
    }, [])

    const searchElementComposition = async (search) => {
        let productSearch = await searchProduct({
            page: 1,
            size: 1000,
            search: search,
            sorts: [{name:"name"}],
            isComposition: false,
            type:type
        })
        setProducts(productSearch.content)
        return productSearch.content;
    }

    return (<SelectAutocomplete
        label={"Наименование товара"}
        onChange={({value}) => {
            onChange(value)
        }}
        value={value}
        options={products}
        onInputChange={(value) => searchElementComposition(value.value)}
        getOptionLabel={(option) => option.name}
        renderOption={(props, option) => {
            return (<Box component="li" {...props}>
                {option.name}<span style={{color: "gray"}}> - {option.price} Руб </span>
            </Box>)
        }}
        filterOptions = {(options, state) => options}
    />)

}