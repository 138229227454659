import React from 'react';
import IconButton from "@mui/material/IconButton";
import CloseIcon from '@mui/icons-material/Close';

const ButtonClose = (props) => {
    return (
        <IconButton
            aria-label="delete"
            size="small"
            {...props}
        >
            <CloseIcon fontSize="inherit"/>
        </IconButton>
    );
};

export default ButtonClose;