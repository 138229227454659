import React from 'react';
import Box from "../../../../element/Box";
import Typography from "../../../../element/Typography";

const ClientChip = (props) => {
    const {client, size=1} = props
    return (
        <Box sx={{
            display:"flex",
            flexDirection:"column",
            gap:"0.5rem"
        }}>
            <Box>
                {client.name}
            </Box>

            {size > 1 && client.transportList ?
                <Box
                    sx={{
                        display:"flex",
                        flexWrap:"wrap",
                        gap:"0.5rem",
                    }}
                >
                    {/*<Typography sx={{*/}
                    {/*    margin:"auto 0"*/}
                    {/*}}>*/}
                    {/*    Транспорт:*/}
                    {/*</Typography>*/}
                {client.transportList.map(transport => {
                    return <Box sx={{
                        border:"1px solid rgba(60,60,60,0.7)",
                        borderRadius:"50px",
                        padding:"0 1rem",
                        display:"flex",
                        flexDirection:"column",
                        alignItems:"center"

                    }}>
                        <div>
                            {`${transport.marka} ${transport.model}`}
                        </div>
                        <div>
                            {transport.number}
                        </div>
                    </Box>
                })}
            </Box> : null}
        </Box>
    );
};

export default ClientChip;