import React, {Fragment} from 'react';
import Typography from "@mui/material/Typography";
import {Box} from "@mui/material";
import TypographyDate from "../../TypographyDate";
import ClientChip from "../../../components/PrivateOffice/Clients/ClientChip";
import _ from 'lodash'
import CommentChip from "../../../components/PrivateOffice/Comments/CommentChip";
import TransportListChipMarka from "../../../components/PrivateOffice/Transport/TransportListChipMarka";
import TextField from "../../../element/TextField";
import {fontFamily} from "@mui/system";

const Cell = (props) => {
    const {justifyContentElement = "center",
        fontFamily="Times New Roman!important",
        fontSizeElement="16px",
        width, flexGrow, value, name,  type, row, format, indexRow, onChange} = props
    const emptyCell = "- // -"

    const renderText = (value) => {
        if (!value && value !== 0) {
            return emptyCell
        }
        if (Array.isArray(value)) {
            return value.join(", ")
        }
        return value
    }

    const renderCell = (type, value, indexRow, name) => {
        switch (type) {
            case "TextField":
                return <TextField
                    value={value ? value : ""}
                    onChange={(e) => {
                        onChange && onChange(indexRow, name, e.target.value)
                    }}
                />
                break;
            case "column":
                return <div>
                    {(value && Array.isArray(value) && value.length > 0) ? value.map(element => {
                        return <div>{renderText(element)}</div>
                    }) : emptyCell}
                </div>
            case "date":
                return <TypographyDate value={value} format={format}/>
            case "ClientChip":
                if (value) {
                    return  <ClientChip client={value}/>
                }
                else {
                    return emptyCell
                }
            case "TransportListChipMarka":
                if (value) {
                    return  <TransportListChipMarka transportList={value}/>
                }
                else {
                    return emptyCell
                }
            case "CommentChip":
                if (value) {
                    return  <CommentChip comments={value}/>
                }
                else {
                    return emptyCell
                }
            default:
                return <Typography sx={{
                    fontFamily:"inherit!important",
                    fontWeight:"inherit!important"
                }}>
                    {renderText(value)}
                </Typography>

        }
    }

    return (
        <Box sx={{
            width: width ? width : "",
            flexGrow: flexGrow ? flexGrow : "1",
            display:"flex",
            justifyContent:justifyContentElement,
            fontFamily:fontFamily,
            alignItems:"center",
            fontSize:fontSizeElement,
        }}>
            {renderCell(type, _.get(row, name), indexRow, name)}
        </Box>
    );
};

export default Cell;