import React from 'react';
import PropTypes from 'prop-types';
import FactoryIcon from "../../../../element/FactoryIcon";
import Box from "../../../../element/Box";

const CategoryChip = props => {
    const {categoryList, isFullViewMerchandise = false} = props

    const renderCategoryList = (categoryList) => {
        let serviceCategory = []
        let merchandiseCategory = []
        if (categoryList) {
            categoryList.forEach(category => {
                if (category.type === "service") {
                    serviceCategory.push(category)
                } else {
                    if (category.type === "merchandise") {
                        merchandiseCategory.push(category)
                    }
                }
            })
        }
        let resultCategory = [...serviceCategory]
        if (isFullViewMerchandise) {
            resultCategory = [...resultCategory, ...merchandiseCategory]
        } else {
            if (merchandiseCategory.length > 0) {
                resultCategory = [...resultCategory, {name:"Товары"}]
            }
        }
        return resultCategory.map(category => {
            return <FactoryIcon name={category.name}/>
        })
    }

    return (
        <Box sx={{
            display:"flex",
            gap:"0.5rem",
            flexWrap:"wrap"
        }}>
            {renderCategoryList(categoryList)}
        </Box>
    );
};

CategoryChip.propTypes = {
    categoryList: PropTypes.array
};

export default CategoryChip;