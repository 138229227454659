import React, {useState} from 'react';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import DropzoneAreaAuto from "../DropzoneArea";

export default function AddFiles(props) {



    return (
        <div>
            <Typography>
                Файлы на добавление:
            </Typography>

            <DropzoneAreaAuto handleChangeFiles={props.handleChangeFiles} files={props.files}/>

            <Button autoFocus onClick={props.saveFilesToServer}  color="primary">
                Сохранить файлы
            </Button>

        </div>
    );
}