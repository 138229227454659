import * as types from "./actionTypes";

export const changeControlPanelSite = (site) => async (dispatch) => {
    dispatch({
        type: types.CHANGE_CONTROL_PANEL_SITE,
        site:site
    });
};

export const changeControlPanelProduct = (product) => async (dispatch) => {
    dispatch({
        type: types.CHANGE_CONTROL_PANEL_PRODUCT,
        product:product
    });
};

export const changeControlPanelCatalog = (catalog) => async (dispatch) => {
    dispatch({
        type: types.CHANGE_CONTROL_PANEL_CATALOG,
        catalog:catalog
    });
};