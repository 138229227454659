import * as types from "./actionTypes";


const initState = {
    productSearch:{

    },
    currentProduct:{

    }
};

export default function reduce(state = initState, action = {}) {
    switch (action.type) {
        case types.INIT_PRODUCT_SEARCH:
                return {
                    ...state,
                    productSearch: action.productSearch
                }
        default:
            return state;
    }
}
