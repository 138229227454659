import React from 'react';
import Header from "./Header";
import Accordion from "./Accordion";
import Divider from "@material-ui/core/Divider";
import "./style.css"
import TableRow from "./TableRow";
import DragOnDrop from "../../element/DragOnDrop";

const Table = (props) => {
    const {
        header, data, component, componentProps, onChange,
        dragOnDrop = false,
        onChangeData,
        byShop = false,
        onChangeShopData
    } = props

    const renderRow = (objectData, index) => {
        return component ?
            <Accordion
                index={index}
                header={header}
                row={objectData}
                component={component}
                componentProps={componentProps}
                onChange={onChange}
                byShop={byShop}
                onChangeShopData={onChangeShopData}
            /> :
            <TableRow
                index={index}
                row={objectData}
                header={header}
                onChange={onChange}
                byShop={byShop}
                onChangeShopData={onChangeShopData}
            />
    }

    return (
        <div>
            <Header header={header} isComponent={component !== undefined}/>

            {dragOnDrop === false ?
                data && data.map((objectData, index) => {
                    return <div>
                        {
                            renderRow(objectData, index)
                        }
                        <Divider style={{background: "rgba(255,255,255,0.6)"}}/>
                    </div>
                }) : <DragOnDrop
                    dataList={data}
                    renderItem={(item, index) => {
                        return <TableRow
                            index={index}
                            row={item}
                            header={header}
                            onChange={onChange}
                        />
                    }}
                    keyFieldName={"index"}
                    functionSetDataList={(newData) => onChangeData(newData)}
                />
                }

        </div>
    );
    ;
}

export default Table;