import RemoveOutlinedIcon from '@mui/icons-material/RemoveOutlined';
import React from 'react';
import IconButton from "@mui/material/IconButton";

const ButtonSub = (props) => {
    return (
        <IconButton
            aria-label="delete"
            size="small"
            {...props}
        >
            <RemoveOutlinedIcon fontSize="inherit"/>
        </IconButton>
    );
};

export default ButtonSub;