import React from "react";
import AddIcon from "@mui/icons-material/Add";
import SearchProductSelectAutocomplete from "./SearchProductSelectAutocompleteForOffice";
import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";

export default function ProductCompositionRedactor(props) {
    const {composition, changeComposition, type} = props

    const onChangeComposition = (newComposition) => {
        changeComposition && changeComposition(newComposition)
    }

    const renderElementMixProduct = (elementMixProduct, index) => {
        return (<Box sx={{
            display: "flex",
            position: "relative"
        }}>
            <Box sx={{width:"400px"}}>
                <SearchProductSelectAutocomplete
                    value={elementMixProduct.product ? elementMixProduct.product : null}
                    key={"searchProductSelectAutoComplete_"+index}
                    onChange={(product) => {
                        if (product) {
                            changeElementMixProduct({
                                ...elementMixProduct,
                                productId: product.id,
                                product: product
                            }, index)
                        } else {
                            changeElementMixProduct({
                                ...elementMixProduct,
                                productId: null,
                                product: null
                            }, index)
                        }
                    }}
                    type={type}
                />
            </Box>
            <TextField
                type="number"
                value={elementMixProduct.count ? elementMixProduct.count : ""}
                onChange={(event => {
                    changeElementMixProduct({
                        ...elementMixProduct,
                        count: event.target.value / 1.0
                    }, index)
                })}
                label={"Количество"}
            />
            <TextField
                disabled
                value={elementMixProduct.product ? elementMixProduct.product.price : ""}
                label={"Цена"}
            />

            <div style={{
                position: "absolute",
                top: "0px",
                right: "0px",
                display: "flex"
            }}>
                <CloseIcon onClick={(event) => deleteElementMixProduct(index)}/>
            </div>
        </Box>)
    }

    const changeElementMixProduct = (elementMixProduct, index) => {
        let currentComposition = composition
        currentComposition.mixProduct[index] = elementMixProduct
        onChangeComposition(currentComposition)
    }

    const addElementMixProduct = () => {
        let currentComposition = composition

        if (!currentComposition) {
            currentComposition = {}
        }

        if (!currentComposition.mixProduct) {
            currentComposition.mixProduct = []
        }

        currentComposition.mixProduct.push({})
        onChangeComposition(currentComposition);
    }

    const deleteElementMixProduct = (index) => {
        let currentComposition = composition
        currentComposition.mixProduct.splice(index, 1)
        onChangeComposition(currentComposition);
    }

    return <Box>
        {composition && composition.mixProduct && composition.mixProduct.map((elementMixProduct, index) => {
            return renderElementMixProduct(elementMixProduct, index)
        })

        }
        <AddIcon onClick={addElementMixProduct}/>
    </Box>

}