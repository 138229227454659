import * as React from 'react';
import {useSelector} from "react-redux";
import {searchEmployee} from "../../../Rest/RestFunction";
import {useEffect, useState} from "react";
import Pagination from "../../../elements/Pagination";
import ViewEmployees from "./ViewEmployees";
import EmployeeCreator from "./EmployeeCreator";
import useSignal from "../../../hooks/useSignal";
import EmployeeSearchFilter from "./EmployeeSearchFilter";
import Box from "../../../element/Box";
import Button from "../../../element/Button";

export default function Employee(props) {
    const user = useSelector((state) => state.auth.user)
    const [page, setPage] = useState({})
    const [filters, setFilters] = useState([
        {
            name: "Уволен",
            nameProperty: "isLeave",
            typeData: "Boolean",
            operator: "is",
            value: "false"
        }
    ])
    const [pagination, setPagination] = useState({
        size:50,
        page:0
    })
    useSignal("employee.list.reload", () => search())

    useEffect(() => {
        search()
    }, [pagination])

    const search = async () => {
        let response = await searchEmployee({
            tokenAuth: user.tokenAuth,
            searchParams : {
                page:pagination.page,
                size:pagination.size,
                filters:filters
            }
        })
        if (response) {
            if (response.page) {
                setPage(response.page)
            }
        }
    }

    return <div>
        <EmployeeCreator/>
        <Box sx={{
            display:"flex",
            gap:"0.5rem",
            justifyContent:"space-between"
        }}>
        <EmployeeSearchFilter filters={filters} setFilters={setFilters}/>
            <Button onClick={search}>Поиск</Button>
        </Box>
        {page && page.content ? <ViewEmployees data={page.content}/> : null}
        <Pagination
            size={pagination.size}
            page={pagination.page}
            setPagination={setPagination}
            countElements={page.totalElements}
        />
    </div>
};

