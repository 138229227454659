import React, {Fragment, useEffect, useState} from 'react';
import './style.css'
import {getFile} from "../../../../Rest/RestFunction";
import Fab from "@mui/material/Fab";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import EditIcon from "@mui/icons-material/Edit";
import ProductModalView from "../ProductModalViewForOffice";
import ModalWindowSizeFree from "../../../../elements/ModalWindowSizeFree";
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import {LightTooltip} from "../../../../elements/LightTooltip";
import {calcCoefficient, getMoneyString} from "../../../../utils/commonUtils";
import _ from 'lodash'
import Button from "@material-ui/core/Button";
import ProductViewTablePriceForClass from "../ProductViewTablePriceForClass";
import ProductTableView from "../../../Product/ProductTableView";


export default function ProductViewTable(props) {

    const {products, category, changeSort, sorts} = props

    const [renderColumn, setRenderColumn] = useState({})

    useEffect(() => {
        let products = props.products
        let newRenderColumn = {}
        products.forEach(product => {
            for (let nameParam in product) {
                let param = product[nameParam]
                if (!_.isEmpty(param)) {
                    if (_.isObject(param)) {
                        newRenderColumn[nameParam] = {}
                        for (let objectParam in param) {
                            let dopParam = param[objectParam]
                            if (!_.isEmpty(dopParam)) {
                                newRenderColumn[nameParam][objectParam] = true
                            }
                        }
                    } else {
                        newRenderColumn[nameParam] = true
                    }
                }
            }
        })
        setRenderColumn(newRenderColumn)
    }, [props.products])


    const checkSort = (name) => {
        return sorts && sorts.map((sort, index) => {
            if (sort.name === name) {
                if (sort.typeSort === "ASC") {
                    return (<Fragment>
                        <ArrowUpwardIcon/>
                        {sorts.length > 1 && index + 1}
                    </Fragment>)
                } else {
                    if (sort.typeSort === "DESC")
                        return (<Fragment>
                            <ArrowDownwardIcon/>
                            {sorts.length > 1 && index + 1}
                        </Fragment>)
                }
            }
        })
    }

    const checkRenderDopParam = (param) => {
        return param.showTable && renderColumn["dopParams"] && renderColumn["dopParams"][param.name]
    }

    const renderHeader = () => {
        const coefficient = calcCoefficient([17,
            (renderColumn["article"] === true) ? 18 : 0,
            20,
            (category && category.dopParams) ? category
                .dopParams
                .filter((param) => checkRenderDopParam(param))
                .length * 15 : 0,
            10,
            10,
            10])

        return <div className={"product_view_element_header"} style={{
            marginTop: "4px",
            marginBottom: "0px",
            // borderBottomColor: "rgba(255,255,255,0.7)",
            // backgroundColor: "rgba(0,0,0,1)"
        }}>
            <LightTooltip title="Наименование" placement="top">
                <div className={"product_view_element_name product_view_element_header_center pointer"}
                     style={{
                         minWidth: `${coefficient * 20}%`,
                         maxWidth: `${coefficient * 20}%`
                     }}
                     onClick={changeSort("name")}>
                    <Typography> Наименование </Typography>
                    {checkSort("name")}
                </div>
            </LightTooltip>
            {renderColumn["article"] === true &&
            <LightTooltip title="Артикул" placement="top">
                <div className={"product_view_element_article product_view_element_header_center pointer"}
                     style={{
                         minWidth: `${coefficient * 18}%`,
                         maxWidth: `${coefficient * 18}%`
                     }}
                     onClick={changeSort("article")}>
                    <Typography> Артикул</Typography>
                    {checkSort("article")}
                </div>
            </LightTooltip>}
            <LightTooltip title="Производитель" placement="top">
                <div className={"product_view_element_brand product_view_element_header_center pointer"}
                     style={{
                         minWidth: `${coefficient * 17}%`,
                         maxWidth: `${coefficient * 17}%`
                     }}
                     onClick={changeSort("brand")}>
                    <Typography> Производитель </Typography>
                    {checkSort("brand")}
                </div>
            </LightTooltip>

            {/*<LightTooltip title="Описание" placement="top">*/}
            {/*    <div className={"product_view_element_description product_view_element_header_center pointer"}*/}
            {/*         style={{minWidth:`${coefficient * 30}%`,*/}
            {/*             maxWidth:`${coefficient * 30}%`}}*/}
            {/*         onClick={changeSort("description")}>*/}
            {/*        <Typography> Описание </Typography>*/}
            {/*        {checkSort("description")}*/}
            {/*    </div>*/}
            {/*</LightTooltip>*/}

            {category && category.dopParams && category
                .dopParams
                .filter((param) => checkRenderDopParam(param))
                .map((param) => {
                    return <LightTooltip title={param.displayName ? param.displayName : param.name} placement="top">
                        <div className={"product_view_element_dop product_view_element_header_center pointer"}
                             style={{
                                 minWidth: `${coefficient * 15}%`,
                                 maxWidth: `${coefficient * 15}%`
                             }}
                             onClick={changeSort(`dopParams.${param.name}`)}>
                            <Typography> {param.displayName ? param.displayName : param.name} </Typography>
                            {checkSort(`dopParams.${param.name}`)}
                        </div>
                    </LightTooltip>
                })}
            <LightTooltip title="Состояние" placement="top">
                <div className={"product_view_element_isNew product_view_element_header_center pointer"}
                     style={{
                         minWidth: `${coefficient * 10}%`,
                         maxWidth: `${coefficient * 10}%`
                     }}
                     onClick={changeSort("isNew")}>
                    <Typography> Состояние </Typography>
                    {checkSort("isNew")}
                </div>
            </LightTooltip>
            <LightTooltip title="Кол-во" placement="top">
                <div className={"product_view_element_count product_view_element_header_center pointer"}
                     style={{
                         minWidth: `${coefficient * 10}%`,
                         maxWidth: `${coefficient * 10}%`
                     }}
                     onClick={changeSort("count")}>
                    <Typography> Кол-во </Typography>
                    {checkSort("count")}
                </div>
            </LightTooltip>
            <LightTooltip title="Цена" placement="top">
                <div className={"product_view_element_price product_view_element_header_center pointer"}
                     style={{
                         minWidth: `${coefficient * 10}%`,
                         maxWidth: `${coefficient * 10}%`
                     }}
                     onClick={changeSort("price")}>
                    <Typography> Цена </Typography>
                    {checkSort("price")}
                </div>
            </LightTooltip>
        </div>
    }

    const renderProduct = (product, index) => {
        const coefficient = calcCoefficient([17,
            (renderColumn["article"] === true) ? 18 : 0,
            20,
            (category && category.dopParams) ? category
                .dopParams
                .filter((param) => checkRenderDopParam(param))
                .length * 15 : 0,
            10,
            10,
            10])

        return <div className={`product_view_element ${index % 2 !== 1 && 'product_view_element_white'}`} style={{
            position:"relative"
        }}>
            <div className={"product_view_element_name"}
                 style={{
                     minWidth: `${coefficient * 20}%`,
                     maxWidth: `${coefficient * 20}%`
                 }}
            >
                <Typography> {product.name} </Typography>
            </div>
            {renderColumn["article"] === true &&
            <div className={"product_view_element_article"}
                 style={{
                     minWidth: `${coefficient * 18}%`,
                     maxWidth: `${coefficient * 18}%`
                 }}
            >
                <Typography> {product.article}</Typography>
            </div>}
            <div className={"product_view_element_brand"}
                 style={{
                     minWidth: `${coefficient * 17}%`,
                     maxWidth: `${coefficient * 17}%`
                 }}
            >
                <Typography>{product.brand} </Typography>
            </div>

            {/*<div className={"product_view_element_description"}*/}
            {/*     style={{minWidth:`${coefficient * 30}%`,*/}
            {/*         maxWidth:`${coefficient * 30}%`}}*/}
            {/*>*/}
            {/*    <Typography> {product.description}</Typography>*/}
            {/*</div>*/}

            {category && category.dopParams && category
                .dopParams
                .filter((param) => checkRenderDopParam(param))
                .map((param) => {
                    return <div className={"product_view_element_dop"}
                                style={{
                                    minWidth: `${coefficient * 15}%`,
                                    maxWidth: `${coefficient * 15}%`
                                }}
                    >
                        <Typography> {product.dopParams && product.dopParams[param.name] ? product.dopParams[param.name] : ""} </Typography>
                    </div>
                })}

            <div className={"product_view_element_isNew"}
                 style={{
                     minWidth: `${coefficient * 10}%`,
                     maxWidth: `${coefficient * 10}%`
                 }}
            >
                <Typography>{product.isNew ? "New" : "Б/у"}</Typography></div>

            <div className={"product_view_element_count"}
                 style={{
                     minWidth: `${coefficient * 10}%`,
                     maxWidth: `${coefficient * 10}%`
                 }}
            >
                <Typography> {product.count}</Typography>
            </div>
            <div className={"product_view_element_price"}
                 style={{
                     minWidth: `${coefficient * 10}%`,
                     maxWidth: `${coefficient * 10}%`
                 }}
            >
                <Typography> {getMoneyString(product.price)}</Typography>
            </div>

            <div style={{
                position:"absolute",
                top:"0px",
                right:"0px",
                display:"flex"
            }}>
            {props.showRedactor === true &&
            <ModalWindowSizeFree
                startComponent={
                        <EditIcon onClick={props.setProductToChange(product)}/>
                }
                actions={props.modalActions}
            >
                {props.modalAdd}
            </ModalWindowSizeFree>
            }
            {props.showRedactor === true &&
                <CloseIcon onClick={props.onDeleteProduct(product)}/>
            }
            </div>
        </div>
    }

    return (
        <div className={"product_view"}>

            <ProductTableView key={category.id} categoryId={category.id} deps={false}/>

            {/*{renderHeader()}*/}
            {/*{props.products && props.products.map((product, index) => {*/}
            {/*        return props.showRedactor ? renderProduct(product, index) :*/}
            {/*            <ModalWindowSizeFree*/}
            {/*                startComponent={renderProduct(product, index)}*/}
            {/*            >*/}
            {/*                {<ProductModalView product={product}/>}*/}
            {/*            </ModalWindowSizeFree>*/}
            {/*    }*/}
            {/*)}*/}
            <ProductViewTablePriceForClass categoryId={category.id}/>
            {props.hasMore === true ? <Button
                variant="contained"
                style={{
                    width:"40%",
                    alignSelf:"center",
                    margin:"5px 0px",
                    border:"2px solid white"
                }}
                onClick={props.nextPage}
            >
                Показать еще
            </Button>:null}
            {/*<InfiniteScroll*/}
            {/*    dataLength={props.products && props.products.length}*/}
            {/*    next={props.nextPage}*/}
            {/*    hasMore={props.hasMore}*/}
            {/*    scrollThreshold={"200px"}*/}
            {/*    loader={<h4>Loading...</h4>}*/}
            {/*>*/}
            {/*    {props.products && props.products.map((product, index) => {*/}
            {/*            return props.showRedactor ? renderProduct(product, index) :*/}
            {/*                <ModalWindowSizeFree*/}
            {/*                    startComponent={renderProduct(product, index)}*/}
            {/*                >*/}
            {/*                    {<ProductModalViewForOffice product={product}/>}*/}
            {/*                </ModalWindowSizeFree>*/}
            {/*        }*/}
            {/*    )}*/}
            {/*</InfiniteScroll>*/}
        </div>
    );
}