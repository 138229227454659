import * as authSelectors from "./reducer";
import * as types from "./actionTypes";
import {checkLogPass, deletePageByName, getPage, updatePage} from "../../Rest/RestFunction";
import * as AuthSelectors from "./reducer";


export const changeContents = (newContents) => (dispatch) => {
    dispatch({
        type: types.CHANGE_CONTENTS,
        newContents: newContents
    });
}

export const changeContent = (index, newContent) => (dispatch) => {
        dispatch({
            type: types.CHANGE_CONTENT,
            index:index,
            newContent: newContent
        });
}

export const updateInfoPage = (updatedPage) => (dispatch) => {
    dispatch({
        type: types.UPDATE_PAGE,
        updatedPage: updatedPage
    });
}

export const addContentToPage = (indexContent, addContent) => async (dispatch) => {
    dispatch({
        type: types.ADD_CONTENT_TO_PAGE,
        index:indexContent,
        addContent:addContent
    });
}

export const deleteContentToPage = (indexContent) => async (dispatch) => {
    dispatch({
        type: types.DELETE_CONTENT_TO_PAGE,
        index:indexContent
    });
}

export const changePage = (name) => async (dispatch) => {
    if (name && name.length > 0 && name[0] === '/') {
        name = name.substring(1)
    }
    if (name === "/") {
        name = "general"
    }
    if (name === "") {
        name = "general"
    }
    if (name === undefined) {
        name = "general"
    }


    let pageResponse = await getPage(name)
    if (pageResponse === undefined) {
        pageResponse = {
            name: name,
            nameSearch:"",
            isIndex:false,
            content : []
        }
    }
    if (pageResponse.isIndex === null) {
        pageResponse.isIndex=true
    }
    dispatch({
        type: types.CHANGE_PAGE,
        page:pageResponse
    });
}

export const savePage = (tokenAuth) => (dispatch) => {
    dispatch({
        type: types.SAVE_PAGE,
        tokenAuth: tokenAuth
    });
}

export const deletePage = (name) => async (dispatch) => {
    let deleteResponse = await deletePageByName(name)
    if (deleteResponse != null && (deleteResponse.isDeleted === true)) {
        let page = {
            name: name,
            nameSearch:"",
            content : []
        }
        dispatch({
            type: types.CHANGE_PAGE,
            page:page
        });
    } else {
        alert("Страница не удалена")
    }
}