import * as React from 'react';
import {useEffect, useRef, useState} from 'react';
import Backdrop from '@mui/material/Backdrop';
import Button from "../Button";
import {Box, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Typography} from "@mui/material";
import ButtonClose from "../Button/ButtonClose";
import './style.css'
import ButtonFullScreen from "../Button/ButtonFullScreen";
import PropTypes from 'prop-types';
import {createTheme, ThemeProvider} from "@mui/material/styles";

Modal.propTypes = {
    onSubmit: PropTypes.func,
    startComponent: PropTypes.element
};

export default function Modal(props) {
    const [openModal, setOpenModal] = React.useState(false);
    const {
        startComponentClassName = "",
        startComponent, label,
        isActions = true,
        onSubmit,
        disabledSubmit = false,
        submitLabel = "ОК",
        onCancel,
        cancelLabel = "Отмена",
        isApply = false,
        onApply,
        applyLabel = "Применить",
        fullScreen,
        leftHeaderButtons = [],
        headerButtons = [],
        open = openModal,
        setOpen = setOpenModal,
        theme = "light",
        ...other
    } = props
    const [fullScreenState, setFullScreenState] = useState(fullScreen)
    const submitButtonRef = useRef(null)

    const style = {
        minWidth: 200,

        p: 0,
        ...(fullScreenState === true ? {} : {
            width: {
                xs: "90vw",
                sm: "85vw",
                md: "80vw",
                lg: "70vw",
                xl: "60vw",
            }
        })
    };

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleFullScreen = () => setFullScreenState(!fullScreenState);

    useEffect(() => {

        submitButtonRef.current && submitButtonRef.current.children[0].focus();

    }, [open])

    return (
        <ThemeProvider theme={createTheme({
            palette: {
                mode: theme,
            },
        })}>
            <React.Fragment>
                <Box
                    onClick={handleOpen}
                    sx={{
                        display: "flex",
                    }}
                    className={startComponentClassName}
                >
                    {startComponent ? startComponent : <Button>{label}</Button>}
                </Box>
                {open ? <Dialog
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={open}
                    onClose={handleClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                    sx={{
                        padding: {
                            md: "0.5rem",
                            xl: "1rem"
                        },
                        '.MuiPaper-root': {
                            margin: "0px"
                        }
                    }}
                    className={"element_modal"}
                    fullScreen={fullScreenState}
                >
                    <DialogTitle sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        padding: "0.5rem 1rem"
                    }}>
                        {leftHeaderButtons.length > 0 &&
                        <Box>
                            {leftHeaderButtons.map((component) => {
                                return component
                            })}
                        </Box>
                        }
                        <Typography>
                            {label}
                        </Typography>
                        <Box
                            sx={{
                                display: "flex",
                                gap: "0.25rem"
                            }}
                        >
                            {headerButtons.map((component) => {
                                return component
                            })}
                            <ButtonFullScreen
                                fullScreen={fullScreenState}
                                onClick={handleFullScreen}
                            />
                            <ButtonClose
                                onClick={handleClose}
                            />
                        </Box>
                    </DialogTitle>
                    <Divider/>


                    {typeof props.children === "function" ?
                        <DialogContent sx={style}>
                                <Box sx={{
                                    p: "32px",
                                    display: "flex",
                                    flexDirection: "column",
                                }}
                                     {...other}
                                >
                                    {
                                        props.children({
                                            handleClose: handleClose,
                                        })
                                    }
                                </Box>
                        </DialogContent>
                        :
                        <DialogContent sx={style}>
                            <Box sx={{
                                p: "2rem",
                                display: "flex",
                                flexDirection: "column",
                            }}
                                 {...other}
                            >
                                {props.children}
                            </Box>
                        </DialogContent>
                    }







                    {/*<Divider/>*/}
                    {/*<DialogContent sx={style}>*/}
                    {/*    <Box sx={{*/}
                    {/*        p: "1rem"*/}
                    {/*    }}*/}
                    {/*         {...other}*/}
                    {/*    >*/}
                    {/*        {props.children}*/}
                    {/*    </Box>*/}
                    {/*</DialogContent>*/}
                    <Divider/>
                    {isActions ? <DialogActions
                            sx={{
                                display: "flex",
                                justifyContent: "flex-end",
                                gap: "1rem",
                                padding: "0.5rem"
                            }}
                        >
                            <Button onClick={() => {
                                onCancel && onCancel()
                                handleClose()
                            }}>
                                {cancelLabel}
                            </Button>
                            <div ref={submitButtonRef}>
                                <Button
                                    disabled={disabledSubmit}
                                    onClick={() => {
                                        onSubmit && onSubmit()
                                        handleClose()
                                    }}>
                                    {submitLabel}
                                </Button>
                            </div>
                            {
                                isApply ?
                                    <Button onClick={() => {
                                        onApply && onApply()
                                    }}>
                                        {applyLabel}
                                    </Button> : null
                            }
                        </DialogActions> :
                        null}
                </Dialog> : null}
            </React.Fragment>
        </ThemeProvider>
    );
}