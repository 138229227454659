import React from 'react';
import {Box, Button} from "@mui/material";
import Factory from "../../components/Factory";
import ClearIcon from "@mui/icons-material/Clear";
import IconButton from "@mui/material/IconButton";

const ListField = (props) => {
    const {label, value, onChange, component, isDeleteFirst} = props

    const addElement = () => {
        let result = value ? [...value] : []
        switch (component) {
            case "TextField":
                onChange([...result, ""])
                break;
        }
    }

    const remove = (index) => {
        let result = [...value]
        result.splice(index, 1)
        onChange(result)
    }

    return (
        <Box sx={{
            // borderRadius: "0.25rem",
            // border: "1px solid rgba(0,0,0,0.23)",
            // margin: "0.5rem",
            position:"relative",
            minHeight:"60px",
            // maxWidth:"600px",
            display:"flex",
            flexDirection:"column"
        }}>
            {/*{label ? <legend><Box*/}
            {/*    sx={{*/}
            {/*        position:"relative",*/}
            {/*        top:"-8px",*/}
            {/*        backgroundColor:"inherit"*/}
            {/*    }}*/}
            {/*>*/}
            {/*    {label}*/}
            {/*</Box> </legend> : null*/}
            {/*}*/}
            {value && value.map((elem, index) => {
                return <Factory
                    component={component}
                    value={value[index]}
                    onChange={(element) => {
                        let listRes = [...value]
                        switch (component) {
                            case "TextField":
                                listRes[index] = element.target.value
                                break;
                            default:
                                listRes[index] = element
                        }
                        onChange(listRes)
                    }}
                    label={`${label} ${value.length >1 ? index+1 : ""}`}
                    InputProps = {(isDeleteFirst === false && index === 0) ? null : {
                        endAdornment: <IconButton
                            style={{
                            padding:"0"
                            }}
                            onClick={() => remove(index)}
                        >
                            <ClearIcon

                        />
                        </IconButton>
                    }}
                />
            })}
            <Button
                onClick={addElement}
            >
                {`Добавить ${label ? label.toLowerCase() : ""}`}
            </Button>

        </Box>
    );
};

export default ListField;