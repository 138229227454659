import React, {Fragment, useState} from "react";
import {CustomSwitch} from "../../elements/CustomSwitch";
import Fab from "@material-ui/core/Fab";
import EditIcon from "@material-ui/icons/Edit";
import {urlFrontend} from "../../Rest/RestFunction";
import * as ControlActions from "../../store/controlPanel/action";
import * as PageActions from "../../store/page/action";
import * as NotificationsActions from "../../store/notifications/actions";
import {useDispatch, useSelector} from "react-redux";
import {useAccessUser} from "../../service/employees/useAccessUser";
import {UPDATE_CATALOG, UPDATE_PRODUCT, UPDATE_SITE} from "../../constants/accesses";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Accordion from "@material-ui/core/Accordion";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Typography from "@material-ui/core/Typography";
import ModalWindowSizeFree from "../../elements/ModalWindowSizeFree";
import CloseIcon from "@material-ui/icons/Close";
import AddIcon from "@material-ui/icons/Add";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import "./style.css"
import Divider from "@material-ui/core/Divider";

export default function ControlPanel(props) {

    const user = useSelector((state) => state.auth.user);
    const isUpdateSite = useAccessUser(UPDATE_SITE, user);
    const isUpdateProduct = useAccessUser(UPDATE_PRODUCT, user);
    const isUpdateCatalog = useAccessUser(UPDATE_CATALOG, user);

    const controlPanelSite = useSelector((state) => state.controlPanel.site);
    const controlPanelProduct = useSelector((state) => state.controlPanel.product);
    const controlPanelCatalog = useSelector((state) => state.controlPanel.catalog);

    const dispatch = useDispatch()
    const setTextAlert = (text) => {
        dispatch(NotificationsActions.showNotification("info", text))
    }

    const handleUpdate = (event) => {
        controlPanelSite.dragOnDrop = event.target.checked
        dispatch(ControlActions.changeControlPanelSite(controlPanelSite))
    };

    const handleUpdatePanelProduct = (name) => (event) => {
        controlPanelProduct[name] = event.target.checked
        dispatch(ControlActions.changeControlPanelProduct(controlPanelProduct))
    };

    const handleUpdatePanelCatalog = (name) => (event) => {
        controlPanelCatalog[name] = event.target.checked
        dispatch(ControlActions.changeControlPanelCatalog(controlPanelCatalog))
    };

    const handleShowUpdateElements = (event) => {
        controlPanelSite.showUpdateElements = event.target.checked
        dispatch(ControlActions.changeControlPanelSite(controlPanelSite))
    };

    const savePage = () => {
        dispatch(PageActions.savePage(user.tokenAuth))
        setTextAlert("Страница отправлена на обновление")
    };

    const changePage = (name) => () => {
        dispatch(PageActions.changePage(name))
    };

    const style = {
        // panel: {
        //     position: "fixed",
        //     top: "0px",
        //     left: "0px",
        //     zIndex:900
        // },
        item: {
            display: "inline-block",
            textAlign: "center",
            margin:"0 10px 0 10px"

        // item: {
        //     display: "flex",
        //     alignItems: "center",
        //     marginTop: "10px"
        }
    }

    const [expanded, setExpanded] = useState(false)
    return isUpdateSite || isUpdateProduct || isUpdateCatalog ? <div>
        <div style={{
            position: "fixed", top: "2px", right: "15px", color: "yellow"
        }}
        >
            <a href={`${urlFrontend}/admin/catalogs`}>* Каталоги </a>
            <a href={`${urlFrontend}/admin/category`}>* Товары </a>
            <a href={`${urlFrontend}/admin/product/statistics`}>* Статистика </a>
        </div>
        <Box sx={{
            display:"flex",
            justifyContent: "center",
            marginTop:"30px",
            overflow:"auto"
        }}>
                {isUpdateSite ?<div>
                    <div style={{display:"flex"}}>
                    <div style={style.item}>
                        <div>
                        </div>
                        Перетаскивание контента сайта
                        <div>
                        <CustomSwitch
                            onChange={handleUpdate}
                            checked={controlPanelSite.dragOnDrop}
                            color="secondary"
                            inputProps={{'aria-label': 'checkbox with default color'}}
                        />
                        </div>
                    </div>
                    <div style={style.item}>
                        <div>
                            Редактирование контента
                        </div>
                        <div>
                        <CustomSwitch
                            onChange={handleShowUpdateElements}
                            checked={controlPanelSite.showUpdateElements}
                            color="secondary"
                            inputProps={{'aria-label': 'checkbox with default color'}}
                        />
                            </div>
                    </div>
                    <div className = {"buttonControlPanel"}  style={style.item}>
                        <Button color="secondary" aria-label="edit" onClick={savePage}>
                            Сохранить страницу
                        </Button>
                    </div>
                </div>
                </div>: null
                }



                {isUpdateProduct ? <div>
                    <div style={style.item}>
                        <div>
                            Редактирование товаров
                        </div>
                        <div>
                        <CustomSwitch
                            onChange={handleUpdatePanelProduct("showUpdateElements")}
                            checked={controlPanelProduct.showUpdateElements}
                            color="secondary"
                            inputProps={{'aria-label': 'checkbox with default color'}}
                        />
                            </div>
                    </div>
                </div> : null
                }

                {isUpdateCatalog ? <div>
                    <div style={style.item}>
                        <div>
                            Редактирование каталогов
                        </div>
                        <div>
                        <CustomSwitch
                            onChange={handleUpdatePanelCatalog("showUpdateElements")}
                            checked={controlPanelCatalog.showUpdateElements}
                            color="secondary"
                            inputProps={{'aria-label': 'checkbox with default color'}}
                        />
                            </div>
                    </div>
                </div> : null
                }
        </Box>
    <Divider style={{background:"steelblue"}}/>

    </div>: null
}

//.....................................................................................................
//     return isUpdateSite || isUpdateProduct || isUpdateCatalog ? <Box
//         className={`control_panel ${expanded ? 'background_black' : 'background_invisible'}`}
//         sx={{
//         ...style.panel,
//     }}>
//         <Accordion
//             expanded={expanded}
//             onChange={(event, newExpanded) => {
//                 setExpanded(!expanded);
//             }}
//         >
//             <AccordionSummary
//                 expandIcon={<ExpandMoreIcon/>}
//                 aria-controls="panel1a-content"
//                 id="panel1a-header"
//             >
//                 <Typography>
//                     Управление
//                 </Typography>
//             </AccordionSummary>
//
//             <AccordionDetails style={{flexDirection: "column"}}>
//                     {isUpdateSite ? <div>
//                         <div style={style.item}>
//                             <Typography>
//                                 Перетаскивание контента сайта
//                             </Typography>
//                             <CustomSwitch
//                                 onChange={handleUpdate}
//                                 checked={controlPanelSite.dragOnDrop}
//                                 color="secondary"
//                                 inputProps={{'aria-label': 'checkbox with default color'}}
//                             />
//                         </div>
//                         <div style={style.item}>
//                             <Typography>
//                                 Редактирование контента
//                             </Typography>
//                             <CustomSwitch
//                                 onChange={handleShowUpdateElements}
//                                 checked={controlPanelSite.showUpdateElements}
//                                 color="secondary"
//                                 inputProps={{'aria-label': 'checkbox with default color'}}
//                             />
//                         </div>
//                         <div style={style.item}>
//                             <Button color="secondary" aria-label="edit" onClick={savePage}>
//                                 Сохранить страницу
//                             </Button>
//                         </div>
//                     </div> : null
//                     }
//
//                     <div style={{
//                         position: "fixed", top: "2px", right: "15px", color: "yellow"
//                     }}
//                          className={"admin_panel"}
//                     >
//                         <a href={`${urlFrontend}/admin/catalogs`}>* Каталоги </a>
//                         <a href={`${urlFrontend}/admin/category`}>* Товары </a>
//                         <a href={`${urlFrontend}/admin/product/statistics`}>* Статистика </a>
//                     </div>
//
//                     {isUpdateProduct ? <div>
//                         <div style={style.item}>
//                             <Typography>
//                                 Редактирование товаров
//                             </Typography>
//                             <CustomSwitch
//                                 onChange={handleUpdatePanelProduct("showUpdateElements")}
//                                 checked={controlPanelProduct.showUpdateElements}
//                                 color="secondary"
//                                 inputProps={{'aria-label': 'checkbox with default color'}}
//                             />
//                         </div>
//                     </div> : null
//                     }
//
//                 {isUpdateCatalog ? <div>
//                     <div style={style.item}>
//                         <Typography>
//                             Редактирование каталогов
//                         </Typography>
//                         <CustomSwitch
//                             onChange={handleUpdatePanelCatalog("showUpdateElements")}
//                             checked={controlPanelCatalog.showUpdateElements}
//                             color="secondary"
//                             inputProps={{'aria-label': 'checkbox with default color'}}
//                         />
//                     </div>
//                 </div> : null
//                 }
//             </AccordionDetails>
//         </Accordion>
//     </Box> : null
// }