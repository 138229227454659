import React, {Fragment, useEffect, useState} from 'react';
import './style.css'

import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import _ from 'lodash'
import Button from "@material-ui/core/Button";
import ProductCard from "./ProductCardForOffice";
import Box from "@mui/material/Box";
import Divider from "@material-ui/core/Divider";


export default function ProductViewCard(props) {

    const {products, category, changeSort, sorts} = props

    const [renderColumn, setRenderColumn] = useState({})

    useEffect(() => {
        let products = props.products
        let newRenderColumn = {}
        products.forEach(product => {
            for (let nameParam in product) {
                let param = product[nameParam]
                if (!_.isEmpty(param)) {
                    if (_.isObject(param)) {
                        newRenderColumn[nameParam] = {}
                        for (let objectParam in param) {
                            let dopParam = param[objectParam]
                            if (!_.isEmpty(dopParam)) {
                                newRenderColumn[nameParam][objectParam] = true
                            }
                        }
                    } else {
                        newRenderColumn[nameParam] = true
                    }
                }
            }
        })
        setRenderColumn(newRenderColumn)
    }, [props.products])

    const checkSort = (name) => {
        return sorts && sorts.map((sort, index) => {
            if (sort.name === name) {
                if (sort.typeSort === "ASC") {
                    return (<Fragment>
                        <ArrowUpwardIcon/>
                        {sorts.length > 1 && index + 1}
                    </Fragment>)
                } else {
                    if (sort.typeSort === "DESC")
                        return (<Fragment>
                            <ArrowDownwardIcon/>
                            {sorts.length > 1 && index + 1}
                        </Fragment>)
                }
            }
        })
    }

    const checkRenderDopParam = (param) => {
        return param.showTable && renderColumn["dopParams"] && renderColumn["dopParams"][param.name]
    }

    const renderProduct = (product, index) => {
        return <ProductCard
            product={product}
            renderColumn={renderColumn}
        />

    }

    return (
        <Box>
            <Divider/>
            <Box sx={{
                display:"flex",
                flexWrap:"wrap",
                justifyContent:"space-around"
            }}>

                {props.products && props.products.map((product, index) => {
                        return renderProduct(product, index)
                    }
                )}

            </Box>
            {props.hasMore === true ? <Button
                variant="contained"
                style={{
                    width: "min(100%, 300px)",
                    alignSelf: "center",
                    margin: "5px 0px",
                    border: "2px solid white"
                }}
                onClick={props.nextPage}
            >
                Показать еще
            </Button> : null}
            <Divider/>
        </Box>
    );
}