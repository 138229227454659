import React, {useEffect, useState} from 'react';
import {getClassTransportAll} from "../../../Rest/RestFunction";
import Box from "../../../element/Box";
import ClassTransportView from "./ClassTransportView";
import Modal from "../../../element/Modal";
import ClassTransportRedactor from "./ClassTransportRedactor";
import {useSelector} from "react-redux";
import Typography from "../../../element/Typography";
import GroupTransportView from "./GroupTransportView";
import useSignal from "../../../hooks/useSignal";

const CatalogClassTransport = (props) => {
    const user = useSelector((state) => state.auth.user);
    const [classTransportList, setClassTransportList] = useState([])
    const [classTransportListGroup, setClassTransportListGroup] = useState([])


    useEffect(() => {
        initClassTransportList()
    }, [])

    const initClassTransportList = async () => {
        let response = await getClassTransportAll({
            tokenAuth: user.tokenAuth,
        })
        setClassTransportList(response.list)

        const groupedMap = {}
        response.list.forEach(classTransport => {
            if (!groupedMap[classTransport.group]) {
                groupedMap[classTransport.group] = []
            }
            groupedMap[classTransport.group].push(classTransport)
        })
        setClassTransportListGroup(groupedMap)
    }
    useSignal("classTransport.listAll.update", initClassTransportList)

    const renderGropedClassTransport = (groupedList) => {
        let arrayNameGroup = []
        for (let nameGroup in groupedList) {
            arrayNameGroup.push(nameGroup)
        }
        return arrayNameGroup.map(group => {
            return (
                <GroupTransportView groupName={group} classTransportList={groupedList[group]}/>
            )
        })
    }

    return (
        <Box>
            {renderGropedClassTransport(classTransportListGroup)}
            {/*{classTransportList && Array.isArray(classTransportList) && classTransportList.map(classTransport => {*/}
            {/*    return <ClassTransportView classTransport={classTransport} />*/}
            {/*})}*/}

                <ClassTransportRedactor/>
        </Box>
    );
};

export default CatalogClassTransport;