import * as React from 'react';
import './style.css';
import Box from "@material-ui/core/Box";
import {useSelector} from "react-redux";
import ElemMenu from "../../../ElemMenu";
import Typography from "@mui/material/Typography";
import ControlPanel from "../../../PrivateOffice/ControlPanel";



export default function TopMenu(props) {
    const user = useSelector((state) => state.auth.user);

    return (
        <Box
            sx={{
                width: "min(100%, 1800px)",
                margin: "0 auto",
                display:"flex",
                justifyContent:"space-between"
            }}
        >
            <Box
                sx={{
                    display:"flex",
                    margin:"10px",
                    fontSize:"16px",
                }}
            >
                Пользователь: {user.login}

            </Box>
            {/*<Box*/}
            {/*    sx={{*/}
            {/*        display:"flex",*/}
            {/*        margin:"10px",*/}
            {/*        fontSize:"16px",*/}
            {/*    }}*/}
            {/*>*/}
            {/*    <Typography>*/}
            {/*        Сервис*/}
            {/*    </Typography>*/}
            {/*</Box>*/}
            {/*<Box*/}
            {/*    sx={{*/}
            {/*        display:"flex",*/}
            {/*        margin:"10px",*/}
            {/*        fontSize:"16px",*/}
            {/*    }}*/}
            {/*>*/}
            {/*    <Typography>*/}
            {/*        Шинка*/}
            {/*    </Typography>*/}
            {/*</Box>*/}
            {/*<Box*/}
            {/*    sx={{*/}
            {/*        display:"flex",*/}
            {/*        margin:"10px",*/}
            {/*        fontSize:"16px",*/}
            {/*    }}*/}
            {/*>*/}
            {/*    <Typography>*/}
            {/*        Прочее*/}
            {/*    </Typography>*/}
            {/*</Box>*/}
            {user && user.id ?
                <ElemMenu
                    name="Вернуться на сайт"
                    link="/"
                /> : null
            }
        </Box>
    );
}
