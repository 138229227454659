import React from 'react';
import PropTypes from 'prop-types';
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import ru from "date-fns/locale/ru";
import {LocalizationProvider} from "@mui/x-date-pickers";
import TextField from "@mui/material/TextField";
import {DesktopDatePicker} from "@mui/x-date-pickers/DesktopDatePicker";

const DateField = props => {
    const {label = "", name="", inputFormat= "dd/MM/yyyy", renderInput = (params) => <TextField {...params}/>, value, onChange, ...other} = props
    return (
        <div>
            <LocalizationProvider
                dateAdapter={AdapterDateFns}
                adapterLocale={ru}
            >
                <DesktopDatePicker
                    label={label}
                    name={name}
                    inputFormat={inputFormat}
                    value={value}
                    onChange={onChange}
                    renderInput={renderInput}
                    {...other}
                />
            </LocalizationProvider>
        </div>
    );
};

DateField.propTypes = {

};

export default DateField;