import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import * as StompJs from "@stomp/stompjs";
import * as AuthActions from "../../store/auth/action"

export default function Subscriber(props) {
    const dispatch = useDispatch()
    const user = useSelector((state) => state.auth.user);
    const [client, setClient] = useState(null)
    const [subscribers, setSubscribers] = useState([]);

    const subscribeUserTopic = (timeout) => {
        setTimeout(() => {
            if (user && user.id) {
                try {
                    let s = client.subscribe("/topic/employee/" + user.id, (message) => {
                        let body = JSON.parse(message.body)
                        switch (body.type) {
                            case "Employee":
                                dispatch(AuthActions.updateToken)
                                break;
                        }
                    },)

                    setClient(client)
                    subscribers.push(s)
                } catch (e) {
                    subscribeUserTopic(10000)
                }
            } else {
                subscribeUserTopic(1000)
            }
        }, timeout)
    }

    useEffect(() => {
        let client = new StompJs.Client({
            brokerURL: 'ws://stof1.ru/activemq',
            //brokerURL: 'ws://localhost:61614',
            debug: function (str) {
                //console.log(str);
            },
            reconnectDelay: 5000,
            heartbeatIncoming: 4000,
            heartbeatOutgoing: 4000,
        });
        client.onConnect(frame => {
            //console.log(frame)
        })
        client.activate()
        setClient(client)
    }, [])

    useEffect(() => {
        if (user && user.id) {
            let size = subscribers.length
            for (let i = 0; i< size; i++) {
                let sub = subscribers.pop()
                sub.unsubscribe()
            }
            subscribeUserTopic(1000)
        }
    }, [user.id])

    return null
}