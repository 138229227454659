import React from 'react';
import IconButton from "@mui/material/IconButton";
import EditIcon from '@mui/icons-material/Edit';

const ButtonEdit = (props) => {
    return (
        <IconButton
            aria-label="delete"
            size="small"
            {...props}
        >
            <EditIcon fontSize="inherit"/>
        </IconButton>
    );
};

export default ButtonEdit;