import React from 'react';
import TextField from "@mui/material/TextField";
import {Button} from "@mui/material";
import Box from "@mui/material/Box";
import {useNavigate} from "react-router-dom";

const ClientOrdersPanel = (props) => {
    const {params, setParams} = props
    const navigate = useNavigate()

    const changeParam = (name, value) => {
        setParams({
            ...params,
            [name]:value
        })
    }

    return (
        <Box
            sx={{
                display:"flex",
                flexDirection:{xs:"column", md:"row"},
                justifyContent:{xs:"", md:"space-between"},
                alignItems:{xs:"", md:"center"},
                padding:"16px"
            }}
        >
            <TextField
                label='Поиск'
                value={params.search}
                onChange={(e) => changeParam('search', e.target.value)}
            />
            <Button
                onClick={() => {
                    navigate("/privateOffice/clientOrders/add")
                }}
            >
                Добавить заказ
            </Button>

        </Box>
    );
};

export default ClientOrdersPanel;