import React from 'react';
import Cell from "../Cell";
import Box from "@mui/material/Box";

const TableRow = (props) => {
    const {header, index, row, onChange} = props
    return (
        <Box sx={{
            display:"flex",
            width:"100%",
            gap:"10px"
        }}>
            {header && header.cells && header.cells.map(cell => {
                return <Cell
                    {...cell}
                    indexRow={index}
                    row={row}
                    onChange={onChange}
                />
            })}
        </Box>
    );
};

export default TableRow;