import React from 'react';
import TypographyMaterial from '@mui/material/Typography';

const Typography = (props) => {
    return (
        <TypographyMaterial
            {...props}
            children={undefined}
        >
            {props.children}
        </TypographyMaterial>
    );
};

export default Typography;