import React from 'react';
import {useDispatch} from "react-redux";
import * as PageActions from "../../store/page/action";
import {NavLink} from "react-router-dom";
import Box from "@mui/material/Box";

const MenuButton = (props) => {
    const {
        link,
        name
    } = props

    const dispatch = useDispatch()

    const changePage = (pageName) => () => {
        dispatch(PageActions.changePage(pageName))
    }


    return (
        <NavLink to={link} onClick={() => {
            changePage(link)
        }}>
            {({isActive}) => (
                <Box
                    className={"pointerForLeftMenu"}
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "97%",
                        minHeight: "48px",
                        background: `${isActive ? "rgba(149,184,238,0.2)" : "none"}`,
                        borderRadius:"0 20px 20px 0",

                    }}
                >
                    {name}
                </Box>
            )}
        </NavLink>
    );
};

export default MenuButton;