import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import Box from "../../../../element/Box";
import ProductCountInfo from "./ProductCountInfo";
import Button from "../../../../element/Button";

const ProductPriceHistory = props => {
    const {productCountInfoList = []} = props

    const [productCountInfoListMod, setProductCountInfoListMod] = useState([])
    const [size, setSize] = useState(5)

    useEffect(() => {
        setProductCountInfoListMod([...productCountInfoList].reverse())
    }, productCountInfoList)

    return (
        <Box>
            <Box>
                {productCountInfoListMod && productCountInfoListMod.slice(0, size).map(productCountInfo => {
                    return <ProductCountInfo productCountInfo={productCountInfo}/>
                })}
                {size < productCountInfoListMod.length ? <Button onClick={() => setSize(prevState => prevState+5)}>Еще</Button> : null}
            </Box>
        </Box>
    );
};

ProductPriceHistory.propTypes = {
    productCountInfoList: PropTypes.array
};

export default ProductPriceHistory;