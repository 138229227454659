import * as React from 'react';
import SwitchMaterial from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import Box from "../Box";

export default function Switch(props) {
    const {label, value, onChange, ...other} = props
    return (
        <Box>
            <FormControlLabel
                checked={(value === true) || (value === "true")}
                control={<SwitchMaterial size={"medium"} color="primary"/>}
                label={label}
                onChange={onChange}
                {...other}
            />
        </Box>
    );
}