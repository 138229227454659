import * as React from 'react';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Box from "../Box";
import {useEffect, useState} from "react";
import _ from "lodash"

function not(a, b) {
    return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
    return a.filter((value) => b.indexOf(value) !== -1);
}

export default function TransferList(props) {
    const {
        leftList = [],
        setLeftList,
        rightList = [],
        setRightList,
        renderElement = (value, labelId) => {
            return (<ListItemText id={labelId} primary={`${value}`}/>)
        },
        renderChildrenElement,
        namePropertyChildrenList,
        namePropertyFromId = "id",

        autoSortedRightList = false,
        namePropertySortRightList
    } = props

    const [checked, setChecked] = React.useState([]);
    const [leftListFilter, setLeftListFilter] = useState([])
    const [rightListState, setRightListState] = useState([])

    useEffect(() => {
        setLeftListFilter(leftList.filter(elem => {
            return !(rightListState.findIndex(rightElem => rightElem[namePropertyFromId] === elem[namePropertyFromId]) > -1)
        }))
    }, [leftList, rightListState])

    useEffect(() => {
        if (rightList) {
            if (autoSortedRightList === true && namePropertySortRightList) {
                setRightListState(rightList.sort(function (a, b) {
                    let strA = _.get(a, namePropertySortRightList);
                    let strB = _.get(b, namePropertySortRightList)
                    if (strA > strB) {
                        return 1;
                    }
                    if (strA < strB) {
                        return -1;
                    }
                    // a должно быть равным b
                    return 0;
                }))
            } else {
                setRightListState(rightList)
            }
        } else {
            setRightListState([])
        }
    }, [rightList])

    const leftChecked = intersection(checked, leftListFilter);
    const rightChecked = intersection(checked, rightListState);

    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const handleAllRight = () => {
        setRightList(rightListState.concat(leftListFilter));
        //setLeftList([]);
    };

    const handleCheckedRight = () => {
        setRightList(rightListState.concat(leftChecked));
        //setLeftList(not(leftListFilter, leftChecked));
        setChecked(not(checked, leftChecked));
    };

    const handleCheckedLeft = () => {
        //setLeftList(leftListFilter.concat(rightChecked));
        setRightList(not(rightListState, rightChecked));
        setChecked(not(checked, rightChecked));
    };

    const handleAllLeft = () => {
        //setLeftList(leftListFilter.concat(rightListState));
        setRightList([]);
    };

    // const renderChildrenElementWithCheckbox = (value) => {
    //     if (namePropertyChildrenList && Array.isArray(value[namePropertyChildrenList]) && value[namePropertyChildrenList].length > 0) {
    //         return <List dense component="div" role="list">
    //             sx={{
    //             display:"flex",
    //             flexDirection:"column",
    //             marginLeft:"1rem",
    //             gap:"0.25rem",
    //         }}>
    //             {value[namePropertyChildrenList].map(childrenValue => {
    //                 return
    //             })}
    //         </List>
    //     }
    // }


    const customList = (items) => (
        <Paper sx={{height: 430, overflow: 'auto'}}>
            <List dense component="div" role="list">
                {items.map((value) => {
                    const labelId = `transfer-list-item-${value[namePropertyFromId]}-label`;
                    return <ListItem
                        key={value[namePropertyFromId]}
                        role="listitem"
                        button
                        onClick={handleToggle(value)}
                        style={{
                            padding:0
                        }}
                    >
                        <ListItemIcon>
                            <Checkbox
                                checked={checked.indexOf(value) !== -1}
                                tabIndex={-1}
                                disableRipple
                                inputProps={{
                                    'aria-labelledby': labelId,
                                }}
                            />
                        </ListItemIcon>
                        {renderElement(value, labelId)}
                        {/*{renderChildrenElementWithCheckbox(value)}*/}
                    </ListItem>
                })}
                <ListItem/>
            </List>
        </Paper>
    );

    return (
        <Box sx={{
            display: "flex",
            gap: "1rem"
        }}>
            <Box sx={{
                width: "10px",
                flexGrow: "1"
            }}>
                {customList(leftListFilter)}
            </Box>
            <Box sx={{
                display:"flex",
                alignItems:"center",
                justifyContent:"center"
            }}>
                <Box sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "0.5rem"
                }}>
                    <Button
                        sx={{my: 0.5}}
                        variant="outlined"
                        size="small"
                        onClick={handleAllRight}
                        disabled={leftListFilter.length === 0}
                        aria-label="move all right"
                    >
                        ≫
                    </Button>
                    <Button
                        sx={{my: 0.5}}
                        variant="outlined"
                        size="small"
                        onClick={handleCheckedRight}
                        disabled={leftChecked.length === 0}
                        aria-label="move selected right"
                    >
                        &gt;
                    </Button>
                    <Button
                        sx={{my: 0.5}}
                        variant="outlined"
                        size="small"
                        onClick={handleCheckedLeft}
                        disabled={rightChecked.length === 0}
                        aria-label="move selected left"
                    >
                        &lt;
                    </Button>
                    <Button
                        sx={{my: 0.5}}
                        variant="outlined"
                        size="small"
                        onClick={handleAllLeft}
                        disabled={rightListState.length === 0}
                        aria-label="move all left"
                    >
                        ≪
                    </Button>
                </Box>
            </Box>
            <Box
                sx={{
                    width: "10px",
                    flexGrow: "1"
                }}>
                {customList(rightListState)}
            </Box>
        </Box>
    );
}
