import React from 'react';
import BoxMaterial from '@mui/material/Box';

const Box = (props) => {
    const {children, ...other} = props
    return (
        <BoxMaterial
            {...other}
        >
            {children}
        </BoxMaterial>
    );
};

export default Box;