import * as types from './actionTypes';
import * as notificationSelectors from './reducer';

/**
 * Отображение уведомления с указанным сообщением
 * @param {String} type - тип сообщения
 * @param {String} message - текст сообщения
 */
export const showNotification = (type, message) => (dispatch, getState) => {
    const data = notificationSelectors.getNotificationData(getState());
    dispatch({
        type: types.SHOW_NOTIFICATION,
        data: [...data, {
            id: new Date().getMilliseconds() + Math.random(),
            message,
            type: type,
            isOpen: true
        }]
    })
}

/**
 * Скрытие указанного уведомления
 * @param {Number} id - идентификатор уведомления
 */
export const hideNotification = (id) => (dispatch, getState) => {
    const data = notificationSelectors.getNotificationData(getState());
    const newData = data.map(item => {
        if (item.id === id) {
            return {
                ...item,
                isOpen: false
            }
        }
        return item;
    })

    dispatch({
        type: types.HIDE_NOTIFICATION,
        data: newData
    })
}
