import React from 'react';
import IconButton from "@mui/material/IconButton";
import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap';
import ZoomInMapIcon from '@mui/icons-material/ZoomInMap';

const ButtonFullScreen = (props) => {
    const {fullScreen, ...other} = props
    return (
        <IconButton
            aria-label="delete"
            size="small"
            {...other}
        >
            {fullScreen === true ? <ZoomInMapIcon fontSize="inherit"/> : <ZoomOutMapIcon fontSize="inherit"/>}
        </IconButton>
    );
};

export default ButtonFullScreen;