import Tooltip from "@material-ui/core/Tooltip";
import withStyles from "@material-ui/core/styles/withStyles";

export const LightTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: 'rgba(80, 80, 80, 0.5)!important',
        color: 'rgba(255, 255, 255, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 13,
    },
}))(Tooltip);