import * as types from "./actionTypes";


const initState = {
    root:[

    ],
    catalogs:[

    ],
    currentCatalog:{

    }
};

export default function reduce(state = initState, action = {}) {
    switch (action.type) {
        case types.INIT_ROOT_CATALOGS:
                return {
                    ...state,
                    root: action.root
                }
        case types.CHANGE_ROOT_CATALOGS:
            return {
                ...state,
                root: action.root
            };
        case types.CHANGE_CURRENT_CATALOG:
            return {
                ...state,
                currentCatalog: action.currentCatalog
            };
        case types.CHANGE_CATALOG:
            let updatedCatalogs = state.catalogs.map((catalog) => {
                if (catalog.name === action.catalog.name) {
                    return action.catalog
                } else {
                    return catalog
                }
            })
            return {
                ...state,
                catalogs: updatedCatalogs
            };
        case types.ADD_CATALOG:
            if (!(action.catalog === null || action.catalog === undefined)) {
                let isExist = false;
                state.catalogs.forEach((catalog) => {
                    if (catalog.name === action.catalog.name) {
                        isExist = true
                    }
                })
                if (isExist === true) {
                    let updatedCatalogs = state.catalogs.map((catalog) => {
                        if (catalog.name === action.catalog.name) {
                            return action.catalog
                        } else {
                            return catalog
                        }
                    })
                    return {
                        ...state,
                        catalogs: updatedCatalogs
                    };
                } else {
                    let updatedCatalogs = [...state.catalogs]
                    updatedCatalogs.push(action.catalog)
                    return {
                        ...state,
                        catalogs: updatedCatalogs
                    }
                }
            }
        default:
            return state;
    }
}
