import React from 'react';
import Comment from "../CommentList/Comment";

const CommentChip = (props) => {
    const {comments} = props
    return (
        <div>
        {comments && comments.length >= 1 && <Comment comment={comments[comments.length-1]} />}
        </div>
    );
};

export default CommentChip;