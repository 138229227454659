import * as types from "./actionTypes";


const initState = {
    root:[

    ],
    category:[

    ],
    currentCategory:{

    }
};

export default function reduce(state = initState, action = {}) {
    switch (action.type) {
        case types.INIT_ROOT_CATEGORY:
                return {
                    ...state,
                    root: action.root,
                    category: {
                        name:"Каталоги",
                        children:action.root
                    }
                }
        case types.CHANGE_ROOT_CATEGORY:
            return {
                ...state,
                root: action.root
            };
        case types.CHANGE_CURRENT_CATEGORY:
            return {
                ...state,
                currentCategory: action.currentCategory
            };
        case types.CHANGE_CATEGORY:
            return {
                ...state,
                category: action.category
            };
        case types.ADD_CATEGORY:
            if (!(action.category === null || action.category === undefined)) {
                let isExist = false;
                state.category.forEach((category) => {
                    if (category.name === action.category.name) {
                        isExist = true
                    }
                })
                if (isExist === true) {
                    let updatedCategory = state.category.map((category) => {
                        if (category.name === action.category.name) {
                            return action.category
                        } else {
                            return category
                        }
                    })
                    return {
                        ...state,
                        category: updatedCategory
                    };
                } else {
                    let updatedCategory = [...state.category]
                    updatedCategory.push(action.category)
                    return {
                        ...state,
                        category: updatedCategory
                    }
                }
            }
        default:
            return state;
    }
}
