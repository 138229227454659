import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {getClientById, searchTransport} from "../../../../Rest/RestFunction";
import {getFilterObjectIdIs} from "../../../../helpers/FilterHelper";
import ViewTransport from "../../Transport/ViewTransport";
import * as NotificationAction from "../../../../store/notifications/actions";
import {useNavigate} from "react-router-dom";
import ButtonLaunch from "../../../../element/Button/ButtonLaunch";
import ButtonEdit from "../../../../element/Button/ButtonEdit";
import Typography from "../../../../element/Typography";
import Box from "../../../../element/Box";

const Client = (props) => {
    const {id, isLoad, isFullScreen = true} = props
    const [load, setLoad] = useState(false)
    const [client, setClient] = useState({})
    const [transport, setTransport] = useState([])
    const user = useSelector((state) => state.auth.user);
    const dispatch = useDispatch()
    const navigate = useNavigate()

    useEffect(() => {
        setLoad(isLoad)
    }, [isLoad])

    useEffect(() => {
        if (load === true) {
            setLoad(false)
            getClient()
            getTransportByClient()
        }
    }, [load])

    const getClient = async () => {
        let response = await getClientById({
            id: id,
            tokenAuth: user.tokenAuth
        })
        setClient(response.client)
    }

    const getTransportByClient = async () => {
        let response = await searchTransport({
            tokenAuth: user.tokenAuth,
            searchParams: {
                filters: [
                    getFilterObjectIdIs("clientId", id)
                ]
            }
        })
        if (response) {
            if (response.page) {
                setTransport(response.page.content)
            } else {
                if (response.message) {
                    dispatch(NotificationAction.showNotification("error", response.message))
                }
            }
        }
    }

    const goToRedactor = () => {
        navigate(`/privateOffice/client/redactor/${client.id}`)
    }

    const goToFullScreen = () => {
        navigate(`/privateOffice/client/${client.id}`)
    }

    return (
        <div>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between"
                }}
            >
                <div>
                    <Typography variant={"h5"}>

                    </Typography>
                </div>
                <div>
                    {!isFullScreen ? <ButtonLaunch
                        color={"primary"}
                        size={"medium"}
                        onClick={goToFullScreen}
                    /> : null}
                    <ButtonEdit
                        color={"primary"}
                        size={"medium"}
                        onClick={goToRedactor}
                    />
                </div>
            </Box>

            <ViewTransport client={client} data={transport}/>
        </div>
    );
};

export default Client;