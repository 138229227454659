import React, {useEffect, useRef, useState} from 'react';
import {
    getClassTransportById,
    searchTransportCatalog,
    updateClassTransport
} from "../../../../Rest/RestFunction";
import TextField from "../../../../element/TextField";
import Box from "../../../../element/Box";
import Modal from "../../../../element/Modal";
import {useSelector} from "react-redux";
import _ from 'lodash'
import TransferList from "../../../../element/TransferList";
import useDebounce from "../../../../hooks/useDebounce";
import ListItemText from "@mui/material/ListItemText";
import useDebounceState from "../../../../hooks/useDebounceState";
import useSignal from "../../../../hooks/useSignal";

const ClassTransportRedactor = (props) => {
    const {
        id,
        startComponent,
        label = "Добавить новый класс транспорта"
    } = props
    const user = useSelector((state) => state.auth.user);
    const [classTransport, setClassTransport] = useState({})
    const [searchTransport, setSearchTransport, debounceSearchTransport] = useDebounceState("", 500)
    const [transportCatalog, setTransportCatalog] = useState([])
    const [signalGetAllClassTransport, sendSignalGetAllClassTransport] = useSignal("classTransport.listAll.update")

    useEffect(() => {
        initTransportSearch(debounceSearchTransport)
    }, [debounceSearchTransport]);

    const initTransportSearch = async (search) => {
        let response = await searchTransportCatalog({
            tokenAuth: user.tokenAuth,
            searchParams: {
                search: search,
                page: 0,
                size: 100,
            }
        })
        setTransportCatalog(response.page.content)
    }

    const defaultClassTransport = {
        name: "",
        nameByClient: "",
        transportList: []
    }

    useEffect(() => {
        init()
        initTransportSearch("")
    }, [])

    const init = async () => {
        if (id) {
            let response = await getClassTransportById({id: id})
            setClassTransport(response.classTransport)
        } else {
            setClassTransport(_.clone(defaultClassTransport))
        }
    }

    const onChange = (nameProperty, newValue) => {
        setClassTransport({
            ...classTransport,
            [nameProperty]: newValue
        })
    }

    const saveClassTransport = async () => {
        let response = await updateClassTransport({
            tokenAuth: user.tokenAuth,
            classTransport: classTransport
        })
        if (!id) {
            setClassTransport(_.clone(defaultClassTransport))
        }
        sendSignalGetAllClassTransport()
    }

    return (
        <Modal
            startComponent={startComponent}
            label={label}
            onSubmit={saveClassTransport}
        >
            <Box sx={{
                display: "flex",
                flexDirection: "column",
                gap: "0.5rem"
            }}>
                <TextField
                    value={classTransport.name}
                    label={"Название класса"}
                    onChange={(e) => {
                        onChange("name", e.target.value)
                    }}
                />
                <TextField
                    value={classTransport.nameByClient}
                    label={"Название класса для клиентов на сайте"}
                    onChange={(e) => {
                        onChange("nameByClient", e.target.value)
                    }}
                />
                <TextField
                    value={classTransport.group}
                    label={"Группа"}
                    onChange={(e) => {
                        onChange("group", e.target.value)
                    }}
                />
                <TextField
                    label={"Поиск транспорта"}
                    value={searchTransport}
                    onChange={(e) => {
                        setSearchTransport(e.target.value)
                    }}
                />
                <TransferList
                    leftList={transportCatalog}
                    setLeftList={setTransportCatalog}
                    renderElement={(value, labelId) => {
                        return (<ListItemText id={labelId} primary={`${value.brand} ${value.model}`}/>)
                    }}

                    rightList={classTransport.transportList ? classTransport.transportList.map(elem => {
                        return {
                            ...elem,
                            sortedName: `${elem.brand} ${elem.model}`
                        }
                    }) : []}
                    autoSortedRightList={true}
                    namePropertySortRightList={"sortedName"}
                    setRightList={(newList) => onChange("transportList", newList)}
                />
            </Box>
        </Modal>
    );
};

export default ClassTransportRedactor;