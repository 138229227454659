import React, {useState} from 'react';
import {Box} from "@mui/material";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Switch from "@mui/material/Switch";
import Button from "@mui/material/Button";
import {useSelector} from "react-redux";
import {saveCurrency} from "../../../../Rest/RestFunction";
import TypographyDate from "../../../../elements/TypographyDate";

const Currency = (props) => {
    const user = useSelector((state) => state.auth.user);
    const [currency, setCurrency] = useState(props.currency)

    const change = (name, value) => {
        const currencyNew = {
            ...currency,
            [name]:value
        }
        setCurrency(currencyNew)
    }

    const save = async () => {
        let currencyResponse = await saveCurrency({
            tokenAuth:user.tokenAuth,
            currency:currency
        })
        props.updateAll()
    }


    return (
        <Box sx={{
            padding:"10px",
            display:"flex",
            gap:"16px",
            alignItems: {
                sm:"center"
            },
            flexDirection:{
                xs:"column",
                sm:"row",
            },
            border:"1px solid rgba(100,100,100,0.5)",
            borderRadius:"10px",
            margin:"10px",
        }}>
            <Typography>
                {currency.name}
            </Typography>
            <Box sx={{
                display:"flex",
                alignItems:"center"
            }}>
                <Typography>
                    Обновлять автоматически
                </Typography>
                <Switch
                    onChange={(e) => change("isAutomatically", e.target.checked)}
                    checked={currency.isAutomatically}
                    color="primary"
                />
            </Box>
            <TextField
                name="value"
                variant="outlined"
                label="Курс"
                disabled={currency.isAutomatically}
                value={currency.value}
                onChange={(e) => change("value", e.target.value)}
            />
            <Button
                onClick={save}
            >
                Сохранить
            </Button>
            <TypographyDate label={"Курс на"}
                            value={currency.date}
                            format={"DD.MM.yyyy"}
            />
        </Box>
    );
};

export default Currency;