import IconButton from "@material-ui/core/IconButton";
import {downloadFile, getFile} from "../Rest/RestFunction";
import React from "react";
import Word from "../static/typeFiles/Word.png";
import Excel from "../static/typeFiles/Excel.jpg";
import JPG from "../static/typeFiles/JPG.jpg";
import PDF from "../static/typeFiles/PDF.jpg";
import ClearIcon from '@material-ui/icons/Clear';
import Tooltip from "@material-ui/core/Tooltip";
import {withStyles} from '@material-ui/core/styles';

const getIconByType = (type) => {
    switch (type) {
        case "application/vnd.openxmlformats-officedocument.wordprocessingml.document": return Word
        case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" : return Excel
        case "image/png" : return JPG
        case "application/pdf" : return PDF

    }
    return JPG
}
const LightTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 12,
    },
}))(Tooltip);

export const renderFiles = (files) => {
    return <div style={{display:"flex",justifyContent:"flex-end",minWidth:"40px", flexWrap:"wrap"}}>
        {files && files.map((file)=>{
            return <IconButton aria-label="download" style={{padding:"0 6px 0 6px"}} onClick={downloadFile(file.guid, file.name)} >
                <LightTooltip title={file.name}>
                    <img src={getIconByType(file.type)} style={{height:"32px"}} />
                </LightTooltip>
            </IconButton>
        })}
    </div>
}

const renderShortName = (name) => {
    if (name != null) {
        let indexDot = name.lastIndexOf(".")
        if (indexDot > 0) {
            name = name.substring(0, indexDot)
        }
        if (name.length > 15) {
            name = name.substring(0, 12) + "..."
        }
    }
    return name
}


export const renderFilesListWithName = (files, deleteFunction) => {
    return <div style={{display:"flex", flexWrap:"wrap"}}>
        {files && files.map((file, index)=>{
            return <div key={index} style={{ width:"120px", display:"flex", flexDirection:"column", alignItems:"center", margin:"6px 4px 6px 4px"}}>
                <div style={{position:"relative", height:"32px"}}>
                    <IconButton aria-label="delete" style={{position:"absolute", top:"-10px", right:"-10px", padding:"0"}} onClick={deleteFunction(index)}>
                        <ClearIcon fontSize="small" />
                    </IconButton>
                    { file.guid != null ?
                        <IconButton aria-label="download" style={{margin:"0 12px 0 0", padding:"0"}} onClick={downloadFile(file.guid, file.name)} >
                            <img src={getIconByType(file.type)} style={{height:"32px", margin:"0"}} />
                        </IconButton> :
                    <img src={getIconByType(file.type)} style={{height:"32px", margin:"0 12px 0 0"}} />}
                </div>
                {renderShortName(file.name)}
            </div>
        })}
    </div>
}

export const calcCoefficient = (numberArray) => {
    let sum = 0
    numberArray && numberArray.forEach(col => {
        sum+=col;
    })
    return 100.0/sum;
}

export const getMoneyString = (value) => {
    value = String(value)
    let dot = ".";
    let comma = ",";
    let positionDot = value.indexOf(dot, 0);
    let positionComma = value.indexOf(comma, 0);
    let i = value.length;

    if (positionDot !== -1) {
        i = positionDot;
    }
    if (positionComma !== -1) {
        i = positionComma;
    }
    for (let j = i - 3; j > 0; j -= 3) {
        value = value.substring(0, j) + "'" + value.substring(j)
    }

    return value + " ₽"
}

export const getImage = (guid) => {
    return getFile(guid);
}