import React from 'react';
import ClientOrderRedactor from "../index";
import {useParams} from "react-router-dom"

const ClientOrderRedactorWithPathId = () => {
    const { id } = useParams()
    return (
        <ClientOrderRedactor id={id}/>
    );
};

export default ClientOrderRedactorWithPathId;