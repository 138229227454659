import * as types from "./actionTypes";

const initState = {
    user:{
        auth:null,
        tokenAuth:null,
        role:{
            name:""
        }
    }
};

export default function reduce(state = initState, action = {}) {

    switch (action.type) {
        case types.CHANGE_USER:
            return {
                ...state,
                user: {
                    ...action.user
                }
            };

        default:
            return state;
    }
}

const getUser = (state) =>{
    return state.auth.user;
}



export {
    getUser
};