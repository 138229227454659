import React, {Fragment, lazy, Suspense} from "react";
import {ConnectedRouter} from "connected-react-router";
import NavMenu from "../NavMenu";
import {Route, Routes} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {useAccessUser} from "../../service/employees/useAccessUser";
import {GET_STATISTICS, UPDATE_CATALOG, UPDATE_CATEGORY} from "../../constants/accesses";
import CircularProgress from "@material-ui/core/CircularProgress";
import ControlPanel from "../ControlPanel";
import {createTheme, ThemeProvider} from '@mui/material/styles';

const Page = lazy(() => import("../Page"));
const LoginPassword = lazy(() => import("../LoginPassword"));
const Catalogs = lazy(() => import("../Catalogs"));
const Product = lazy(() => import("../Product"));
const Statistics = lazy(() => import("../Statistics"));
const PrivateOffice = lazy(() => import("../PrivateOffice"))

export default function WithBackground(props) {
    const dispatch = useDispatch()
    const user = useSelector((state) => state.auth.user);
    const isGetStatistics = useAccessUser(GET_STATISTICS, user)
    const isUpdateCategory = useAccessUser(UPDATE_CATEGORY, user)
    const isUpdateCatalog = useAccessUser(UPDATE_CATALOG, user)


    return <Fragment>
        {user && (isGetStatistics || isUpdateCategory || isUpdateCatalog ?
            <div style={{marginBottom: "30px", color: "white"}}><ControlPanel/></div> : null)}
        <div className='fon animate'></div>
        <div className='black-fon'></div>
        <main className='main-wrapper'>
                <NavMenu/>
                {/*<NavigationMenu>*/}
                <div className="main-page">
                    <Suspense fallback={<div style={{margin: "100px auto 100px auto"}}><CircularProgress/></div>}>
                        <Routes>
                            <Route path="/auth" element={<LoginPassword/>}/>
                            {isUpdateCatalog ? <Route exact path="/admin/catalogs" element={<Catalogs/>}/> : null}
                            {isGetStatistics ?
                                <Route exact path="/admin/product/statistics" element={<Statistics/>}/> : null}
                            {isUpdateCategory ? <Route exact path="/admin/category" element={<Product/>}/> : null}
                            <Route path="/:id" element={<Page/>}/>
                            <Route path="/" element={<Page/>}/>
                        </Routes>
                    </Suspense>
                </div>
                {/*</NavigationMenu>*/}
                {/*            <ControlPanel/>*/}
        </main>

    </Fragment>

}