import React, {Fragment, useEffect, useRef, useState} from 'react';
import Autocomplete from "../../element/Autocomplete";
import {postRequest} from "../../Rest/RestFunction";
import {useSelector} from "react-redux";
import useDebounceState from "../../hooks/useDebounceState";

const SearchTextField = (props) => {
    const {onChangeOptions, value, onChange, onChangeValueTextfield, urlGetOptions, searchParams, setOptions, options, ...other} = props
    const user = useSelector((state) => state.auth.user);

    const [searchOptions, setSearchOptions] = useState([])
    const [valueTextField, setValueTextField, debounceValueTextField] = useDebounceState("", 300)

    const getOptions = async (search) => {
        let response = await postRequest(urlGetOptions, {
            searchParams: {
                search: search,
                page: 0,
                size: 50,
                ...searchParams
            },
            tokenAuth: user.tokenAuth
        })
        setOptions && setOptions(response.page)
        onChangeOptions && onChangeOptions(response)
        setSearchOptions(response.page.content)
    }

    useEffect(() => {
        !options && getOptions(debounceValueTextField)
    }, [debounceValueTextField])

    const onChangeSearch = (e, value) => {
        onChange && onChange(value)
    }

    const onChangeTextField = (e, newValue) => {
        onChangeValueTextfield && onChangeValueTextfield(newValue)
        setValueTextField(newValue)
    }

    return (
        <Fragment>
        <Autocomplete
            freeSolo
            valueTextField={valueTextField}
            onChangeTextField={onChangeTextField}

            options={options ? options : [...searchOptions]}
            filterOptions={(x) => x}
            value={value}
            onChange={onChangeSearch}
            {...other}
        />
        </Fragment>
    );
};

export default SearchTextField;