import React from 'react';
import ClientRedactor from "../index";
import {useParams} from "react-router-dom"

const ClientRedactorWithPathId = () => {
    const { id } = useParams()
    return (
        <ClientRedactor id={id}/>
    );
};

export default ClientRedactorWithPathId;