import * as types from './actionTypes';

const initialState = {
    commonError: '',
};

export default function reduce(state = initialState, action = {}) {
    switch (action.type) {
        case types.SET_ERROR_COMMON:
            return {
                ...state,
                commonError: action.message
            };
        default:
            return state;
    }
}

const getErrorCommon = (state) => {
    return state.errors.commonError;
};

export {
    getErrorCommon,
};