
import Chip from "@mui/material/Chip";
import TextField from "@mui/material/TextField";
import React from "react";
import Autocomplete from "@mui/material/Autocomplete";


export default function SelectAutocomplete(props) {
    const {className, variant = "outlined", label, ...other} = props
    const [value, setValue] = React.useState(null);
    const [inputValue, setInputValue] = React.useState('');
    const loaded = React.useRef(false);

    return <Autocomplete
            className={`white_svg ${className}`}
            getOptionLabel={(option) => (typeof option === 'string' ? option : option.name)}
            options={props.options} //Строки
            renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                    <Chip style={{color: "white"}} variant="outlined"
                          label={option} {...getTagProps({index})} />
                ))
            }
            variant={"outlined"}
            renderInput={(params) => (
                <TextField {...params} variant={variant} label={label} placeholder={props.placeholder}/>
            )}
            {...other}
            onChange={(event, newValue) => {
                props.onChange && props.onChange({...props.onChangeParam, value: newValue})
                setValue(newValue);
            }}
            onInputChange={(event, newInputValue) => {
                props.onInputChange && props.onInputChange({...props.onInputChangeParam, value:newInputValue})
                setInputValue(newInputValue);
            }}
        />
}