import React, {Fragment, useState} from 'react';
import AppBar from '@material-ui/core/AppBar';
import {makeStyles} from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import {alpha} from '@material-ui/core/styles';
import InputBase from "@material-ui/core/InputBase";
import ListMenu from "../ListMenu";
import ElemMenu from "../ElemMenu";
import photoFlag from '../../static/flag.png'
import {searchPage} from "../../Rest/RestFunction";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import "./style.css"
import Divider from "@material-ui/core/Divider";
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import * as PageActions from "../../store/page/action";
import 'bootstrap/dist/css/bootstrap.min.css';
import {useAccessUser} from "../../service/employees/useAccessUser";
import {GET_STATISTICS} from "../../constants/accesses";
import {Box} from "@mui/material";
import Typography from "@mui/material/Typography";
import Toolbar from "@mui/material/Toolbar";


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        top: '0px',
        width: '100%',
        backgroundColor: 'rgba(0,0,0,0.95) !important',
        paddingBottom: '10px',
        zIndex: 900
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    toolbar: {
        minHeight: 98,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: 'flex-start',
        marginTop: '20px',
        marginBottom: "40px",
    },
    title: {
        flexGrow: 1,
        alignSelf: 'flex-end',

    },
    search: {
        display: "flex",
        alignItems: "center",
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: `${alpha(theme.palette.common.white, 0.95)}!important`,
        '&:hover': {
            backgroundColor: `${alpha(theme.palette.common.white, 1)}!important`,
        },
        marginLeft: 0,
        width: '100%',
        height: '80%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(1),
            width: 'auto',
        },
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        color: 'black!important',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'black!important',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        color: "black !important",
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(10)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '15ch',
            '&:focus': {
                width: '20ch',
            },
        },
    },
    image: {
        backgroundImage: `url(${photoFlag})`,
    }
}));

export default function NavMenu() {
    const classes = useStyles();
    const dispatch = useDispatch()
    const user = useSelector((state) => state.auth.user);

    const changePage = (pageName) => () => {
        dispatch(PageActions.changePage(pageName))
    }


    const [searchPageResponse, setSearchPageResponse] = useState();

    const [open, setOpen] = React.useState(false);
    let inSearch

    const handleClick = (event) => {
        setOpen(true);
    };

    const handleClose = () => {
        handleOverInSearch()
        setTimeout(() => {
            if (inSearch === false) {
                setOpen(false)
            } else handleClose()
        }, 1000)
    };

    const handleInSearch = () => {
        inSearch = true
    }

    const handleOverInSearch = () => {
        inSearch = false
    }

    const changeSearch = async (e) => {
        let search = e.target.value
        setOpen(true)
        setSearchPageResponse(await searchPage(search))
    }
    return (
        <Fragment>
            <Box className={`${classes.root} Menu_Fon`} sx={{
                display:{
                    xs:"none",
                    md:"flex"
                }
            }}>

                <AppBar position="static">
                    <Toolbar className={classes.toolbar}>
                        <img src={photoFlag}
                             style={{position: "absolute", top: "-55px", left: "20px", height: "76px"}}/>
                        <div className="flex"
                             style={{alignItems: "center", justifyContent: "space-between", width: "100%"}}>

                            <div style={{
                                display: "flex",
                                width: "90%",
                                flexWrap: "wrap",
                                alignItems: "flex-end",
                                justifyContent: "flex-end"
                            }}>
                                <Typography className={classes.title}
                                            style={{flexGrow: "0,5", marginLeft: "60px", fontStyle: "oblique"}}
                                            variant="h4">
                                    STO - F1.ru
                                </Typography>

                                <Typography style={{flexGrow: "20", textAlign: "center", fontStyle: "oblique"}}
                                            align="center" noWrap={true}
                                            variant="h6">
                                    + 7 (967) 156-68-17
                                </Typography>

                            </div>
                            <Typography
                                style={{flexGrow: "0.5", textAlign: "center", flexWrap: "noWrap", fontSize: "12px"}}
                                align="center" noWrap={false} variant="inherit">
                                Москва, посёлок Толстопальцево, ул. Советская, д.1, стр. 16
                            </Typography>

                            <div style={{
                                display: "flex",
                                "width": "50%",
                                justifyContent: "flex-end",
                                position: "relative"
                            }}
                                 onMouseLeave={handleClose}
                                 onMouseMove={handleInSearch}
                            >
                                <div className={classes.search}>
                                    <div className={classes.searchIcon}>
                                        <SearchIcon/>
                                    </div>
                                    <InputBase
                                        placeholder="Поиск по сайту"
                                        classes={{
                                            root: classes.inputRoot,
                                            input: classes.inputInput,
                                        }}
                                        inputProps={{'aria-label': 'search'}}
                                        onClick={handleClick}
                                        onChange={changeSearch}
                                    />
                                </div>
                                {open && searchPageResponse && searchPageResponse.length > 0 && open === true &&
                                <div className="searchResponse" onMouseMove={handleInSearch}
                                     onMouseLeave={handleOverInSearch}>
                                    <List component="nav">
                                        {
                                            searchPageResponse && searchPageResponse.map(page => {
                                                return (<Fragment>
                                                    <Link to={`/${page.name}`} onClick={changePage(page.name)}><ListItem
                                                        onClick={handleClose}> {page.nameSearch != null ? page.nameSearch : page.name} </ListItem></Link>
                                                    <Divider/>
                                                </Fragment>)
                                            })
                                        }
                                    </List>
                                </div>
                                }
                            </div>


                        </div>

                        <div className="flex Menu_Fon_Button" style={{
                            alignSelf: "center",
                            justifyContent: "space-between",
                            flexWrap: "wrap",
                            width: "100%",

                        }}>
                            <ElemMenu
                                name="Главная"
                                link="/"
                            />
                            <ListMenu
                                title={"Автомойка"}
                                elements={[
                                    {
                                        link: "PriceMoika",
                                        name: "Прайс на автомойку"
                                    },
                                    {
                                        link: "PriceXimka",
                                        name: "Прайс на химчистку"
                                    },

                                ]}
                            />
                            <ListMenu
                                title={"Автосервис"}
                                elements={[
                                    {
                                        link: "/PriceMechanics",
                                        name: "Слесарные работы"
                                    },
                                    {
                                        link: "/PriceShinoMontag",
                                        name: "Шиномонтажные работы"
                                    },
                                    {
                                        link: "/PriceRemontDisks",
                                        name: "Правка дисков"
                                    },
                                    {
                                        link: "/PriceSxodRazval",
                                        name: "Сход-развал"
                                    },
                                    {
                                        link: "/PriceCondition",
                                        name: "Заправка кондиционеров"
                                    },
                                    {
                                        link: "/PriceRemontDVS",
                                        name: "Ремонт ДВС"
                                    }
                                ]}
                            />
                            <ListMenu
                                title={"Покрасочный цех"}
                                elements={[
                                    {
                                        link: "/PricePokras",
                                        name: "Покрасочные работы"
                                    },
                                    {
                                        link: "/PricePolirovka",
                                        name: "Полировка"
                                    },

                                ]}
                            />
                            <ElemMenu
                                name="Автозапчасти"
                                link="/AutoParts"
                            />
                            <ElemMenu
                                name="Новости"
                                link="/News"
                            />
                            <ElemMenu
                                name="О нас"
                                link="/AboutUS"
                            />
                            <ElemMenu
                                name="Контакты"
                                link="/contacts"
                            />
                            {user && user.id ?
                                <ElemMenu
                                    name="Личный кабинет сотрудника"
                                    link="/privateOffice/clientOrders"
                                /> : null
                            }


                        </div>


                    </Toolbar>
                </AppBar>
            </Box>
            <Box sx={{
                display: {
                    xs: "block",
                    md: "none"
                },
                flexGrow: 1,
                top: '0px',
                width: '100%',
                backgroundColor: 'rgba(0,0,0,0.95) !important',
                paddingBottom: '10px',
                zIndex: 900
            }}>
                <AppBar position="static">
                    <Toolbar sx={{
                        minHeight: 98,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        alignItems: 'flex-start',
                    }}>

                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                width: "100%"
                            }}>

                            <Box sx={{
                                display: "flex",
                                alignItems: "center", justifyContent: "space-between", width: "100%"
                            }}>
                                <div style={{
                                    display: "flex",
                                    width: "90%",
                                    flexWrap: "wrap",
                                    alignItems: "flex-end",
                                    justifyContent: "flex-end"
                                }}>
                                    <Typography sx={{
                                        flexGrow: 0.5,
                                        alignSelf: 'flex-end',
                                        fontStyle: "oblique",
                                        fontSize: {
                                            xs: "1rem",
                                            md: "2rem"
                                        }
                                    }}
                                                variant="h4">
                                        STO - F1.ru
                                    </Typography>

                                    <Typography
                                        sx={{
                                            flexGrow: "20",
                                            textAlign: "center",
                                            fontStyle: "oblique",
                                            fontSize: {
                                                xs: "1rem",
                                                md: "2rem"
                                            }
                                        }}
                                        align="center" noWrap={true}
                                        variant="h5">
                                        + 7 (967) 156-68-17
                                    </Typography>

                                </div>
                                <Typography sx={{
                                    padding: "0 0.5rem",
                                    flexGrow: "0.5",
                                    textAlign: "center",
                                    flexWrap: "noWrap",
                                    fontSize: "12px"
                                }}
                                            align="center" noWrap={false} variant="h12">
                                    Москва, посёлок Толстопальцево, ул. Советская, д.1, стр. 16
                                </Typography>
                            </Box>
                            <Box sx={{
                                padding:"0.5rem",
                                display: "flex",
                                alignSelf:"flex-end",
                                width: "100%",
                                justifyContent: "flex-end",
                                position: "relative"
                            }}
                                 onMouseLeave={handleClose}
                                 onMouseMove={handleInSearch}
                            >
                                <div className={classes.search}>
                                    <div className={classes.searchIcon}>
                                        <SearchIcon/>
                                    </div>
                                    <InputBase
                                        placeholder="Поиск по сайту"
                                        classes={{
                                            root: classes.inputRoot,
                                            input: classes.inputInput,
                                        }}
                                        inputProps={{'aria-label': 'search'}}
                                        onClick={handleClick}
                                        onChange={changeSearch}
                                    />
                                </div>
                                {open && searchPageResponse && searchPageResponse.length > 0 && open === true &&
                                <div className="searchResponse" onMouseMove={handleInSearch}
                                     onMouseLeave={handleOverInSearch}>
                                    <List component="nav">
                                        {
                                            searchPageResponse && searchPageResponse.map(page => {
                                                return (<Fragment>
                                                    <Link to={`/${page.name}`} onClick={changePage(page.name)}><ListItem
                                                        onClick={handleClose}> {page.nameSearch != null ? page.nameSearch : page.name} </ListItem></Link>
                                                    <Divider/>
                                                </Fragment>)
                                            })
                                        }
                                    </List>
                                </div>
                                }
                            </Box>


                        </Box>

                        <Box sx={{
                            display: "flex",
                            alignSelf: "center",
                            justifyContent: "space-between",
                            flexWrap: "wrap",
                            width: "100%",
                            fontSize: "1rem"
                        }}>
                            <ElemMenu
                                name="Главная"
                                link="/"
                            />
                            <ListMenu
                                title={"Автомойка"}
                                elements={[
                                    {
                                        link: "PriceMoika",
                                        name: "Прайс на автомойку"
                                    },
                                    {
                                        link: "PriceXimka",
                                        name: "Прайс на химчистку"
                                    },

                                ]}
                            />
                            <ListMenu
                                className={"MenuListNavMenu"}
                                title={"Автосервис"}
                                elements={[
                                    {
                                        link: "/PriceMechanics",
                                        name: "Слесарные работы"
                                    },
                                    {
                                        link: "/PriceShinoMontag",
                                        name: "Шиномонтажные работы"
                                    },
                                    {
                                        link: "/PriceRemontDisks",
                                        name: "Правка дисков"
                                    },
                                    {
                                        link: "/PriceSxodRazval",
                                        name: "Сход-развал"
                                    },
                                    {
                                        link: "/PriceCondition",
                                        name: "Заправка кондиционеров"
                                    },
                                    {
                                        link: "/PriceRemontDVS",
                                        name: "Ремонт ДВС"
                                    },
                                    {
                                        link: "/PricePokras",
                                        name: "Покрасочные работы"
                                    },
                                    {
                                        link: "/PricePolirovka",
                                        name: "Полировка"
                                    },
                                ]}
                            />
                            <ElemMenu
                                name="Автозапчасти"
                                link="/AutoParts"
                            />
                            <ElemMenu
                                name="Новости"
                                link="/News"
                            />
                            <ListMenu
                                title={"Контакты"}
                                elements={[
                                    {
                                        link: "/contacts",
                                        name: "Контакты"
                                    },
                                    {
                                        link: "/AboutUS",
                                        name: "О нас"
                                    },
                                ]}
                            />

                        </Box>


                    </Toolbar>
                </AppBar>
            </Box>
        </Fragment>

        // <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
        //     <Navbar.Brand href="#home">React-Bootstrap</Navbar.Brand>
        //     <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        //     <Navbar.Collapse id="responsive-navbar-nav">
        //         <Nav className="mr-auto">
        //             <Nav.Link href="#features">Features</Nav.Link>
        //             <Nav.Link href="#pricing">Pricing</Nav.Link>
        //             <NavDropdown title="Dropdown" id="collasible-nav-dropdown">
        //                 <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
        //                 <NavDropdown.Item href="#action/3.2">Another action</NavDropdown.Item>
        //                 <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
        //                 <NavDropdown.Divider />
        //                 <NavDropdown.Item href="#action/3.4">Separated link</NavDropdown.Item>
        //             </NavDropdown>
        //         </Nav>
        //         <Nav>
        //             <Nav.Link href="#deets">More deets</Nav.Link>
        //             <Nav.Link eventKey={2} href="#memes">
        //                 Dank memes
        //             </Nav.Link>
        //         </Nav>
        //     </Navbar.Collapse>
        // </Navbar>
    );
}