import React, {Fragment} from 'react';
import './style.css'
import {getFile} from "../../../../Rest/RestFunction";
import Typography from "@material-ui/core/Typography";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Accordion from "@material-ui/core/Accordion";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Divider from "@material-ui/core/Divider";
import Modal from "../../../../element/Modal";
import ButtonEdit from "../../../../element/Button/ButtonEdit";
import ButtonPlus from "../../../../element/Button/ButtonPlus";
import ButtonDelete from "../../../../element/Button/ButtonDelete";


export default function CategoryView(props){
    const {onClickSaveCategory} = props
    const category = props.category

    const handleChange = (event, newExpanded) => {
        props.setCategoryAccordionOpen(newExpanded ? true : false);
    };
    //***** Accordion

    const getImage = (guid) => {
        return getFile(guid);
    }

    const renderParent = (cat, index) => {
        return (<Fragment>
            {index === 1 ?
                <FormControlLabel
                    aria-label="Acknowledge"
                    control={
                        <Fragment>
                            <Typography style={{margin: "auto 10px"}}><h6 style={{margin: "auto 0"}}> > </h6></Typography>
                            <Typography style={{margin: "auto 0"}}>

                                    <div className={"flex"}>
                                        {cat.image && cat.image.url &&
                                        <img src={getImage(cat.image.url)}
                                             style={{
                                                 width: "30px",
                                                 height: "30px",
                                                 borderRadius: "50%",
                                                 margin: "-3px 10px"
                                             }}/>
                                        }
                                        <h6 style={{margin: "auto 0"}} className=" pointerForCategoryOffice"> {cat.name} </h6>
                                    </div>

                            </Typography>
                        </Fragment>
                    }
                /> :
                <FormControlLabel
                    aria-label="Acknowledge"
                    onClick={(event) => event.stopPropagation()}
                    onFocus={(event) => event.stopPropagation()}
                    control={
                        <Fragment>
                            <Typography style={{margin: "auto 10px"}}><h6 style={{margin: "auto 0"}} className=""> > </h6></Typography>
                            <Typography style={{margin: "auto 0"}} onClick={props.onChangeCategory(cat.id)}>
                                    <div className={"flex"}>
                                        {cat.image && cat.image.url &&
                                        <img src={getImage(cat.image.url)}
                                             style={{
                                                 width: "30px",
                                                 height: "30px",
                                                 borderRadius: "50%",
                                                 margin: "-3px 10px"
                                             }}/>
                                        }<h6 style={{margin: "auto 0"}} className=" pointerForCategoryOffice"> {cat.name} </h6>
                                    </div>
                            </Typography>

                        </Fragment>
                    }
                />
            }

            {cat.parent && renderParent(cat.parent, index + 1)}
        </Fragment>)
    }

    return (
        <div className={`category_view_For_Office ${props.categoryAccordionOpen === false ? "category_view_For_Office_close" : ""}`}>
            {props.showRedactor !== true && (category && category.children && category.children.length ===0) && props.setCategoryAccordionOpen(false)}
            <Accordion expanded={props.categoryAccordionOpen} onChange={handleChange}>
                <AccordionSummary
                    className={`
                        ${props.categoryAccordionOpen === false ? "category_view_For_Office_close" : ""}
                        ${category && category.children && category.children.length ===0 ? "cursor_auto": ""}`}
                    expandIcon={category && category.children && category.children.length !== 0 ? <ExpandMoreIcon style={{
                        color: props.categoryAccordionOpen === true ? "blue" : "gray"
                    }}/> : null}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <div className="category_view_For_Office_header">

                        {
                            category && category.id && renderParent(category, 1)
                        }
                        {category && category.id ?
                            <FormControlLabel
                                aria-label="Acknowledge"
                                onClick={(event) => event.stopPropagation()}
                                onFocus={(event) => event.stopPropagation()}
                                control={
                                    <h5
                                        style={{margin: "auto 0"}}>
                                    <Typography
                                                className=""
                                                onClick={props.onChangeCategory("root")}>
                                        Каталоги
                                    </Typography>
                                    </h5>
                                }
                            /> :
                            <FormControlLabel
                                aria-label="Acknowledge"
                                control={<h5
                                    style={{margin: "auto 0"}}
                                >
                                    <Typography
                                        className="">
                                            Каталоги
                                    </Typography>
                                </h5>
                                }
                            />
                        }
                    </div>

                </AccordionSummary>
                <Divider/>

                <AccordionDetails style={{flexDirection: "column"}}>
                    <div className="category_view_For_Office_children">
                        {category && category.children && category.children.map((child, index) => {
                                return (
                                    <div key={index} className={`category_view_For_Office_child ${props.showRedactor !== true ? "pointerForCategoryOffice" : ""}`}
                                         onClick={props.showRedactor !== true ? props.onChangeCategory(child.id) : null}>
                                        {/*иконки для категории*/}
                                            <div >
                                                {child.image && child.image.url ?
                                                    <img src={getImage(child.image.url)} style={{
                                                        width: "30px",
                                                        height: "30px",
                                                        borderRadius: "50%",
                                                        margin: "10px"
                                                    }}/> :
                                                    <div style={{
                                                        width: "30px",
                                                        height: "30px",
                                                        borderRadius: "50%",
                                                        margin: "10px"
                                                    }}>

                                                    </div>
                                                }
                                            </div>
                                        {/*название категории*/}
                                        <Typography
                                                    style={{margin: "auto 0"}}
                                            className={`${props.showRedactor === true ? "pointerForCategoryOffice" : ""}`}
                                            onClick={props.showRedactor === true ? props.onChangeCategory(child.id) : null}
                                            >
                                            {child.name}
                                        </Typography>
                                        {props.showRedactor === true &&
                                        <Modal
                                            label={"Редактирование категории"}
                                            startComponent={
                                                <ButtonEdit color="secondary" aria-label="edit"
                                                     onClick={props.setCategoryToChange(child)} />
                                            }
                                            onSubmit={onClickSaveCategory}
                                            submitLabel={"Сохранить"}
                                        >
                                            {props.modalAdd}
                                        </Modal>
                                        }
                                        {props.showRedactor === true &&
                                            <Modal
                                                label={"Удаление категории"}
                                                onSubmit={props.onDeleteCategory(child.id)}
                                                startComponent={<ButtonDelete />}
                                            >
                                                <Typography>
                                                    Вы уверены, что хотите удалить категорию {child.name}?
                                                </Typography>
                                            </Modal>
                                        }
                                    </div>)
                            })
                        }

                        {props.showRedactor === true &&
                        <div>
                            <Modal
                                label={"Добавить новую категорию"}
                                startComponent={
                                    <ButtonPlus size={"large"} onClick={props.setCategoryToChange({name: ""})}/>}
                                onSubmit={onClickSaveCategory}
                                submitLabel={"Сохранить"}
                            >
                                {props.modalAdd}
                            </Modal>

                        </div>
                        }

                    </div>
                </AccordionDetails>
            </Accordion>

        </div>
    );
}