import React from "react";
import Switch from "@material-ui/core/Switch";
import withStyles from "@material-ui/core/styles/withStyles";
import purple from "@material-ui/core/colors/purple";

export const CustomSwitch = withStyles({
    switchBase: {
        color: "rgba(0,51,255,0.5)",
        '&$checked': {
            color: "rgba(0,51,255,0.9)",
        },
        '&$checked + $track': {
            backgroundColor: "rgba(153,255,255,0.5)",
        },
    },

    checked: {},
    track: {
        borderRadius: 26 / 2,
        backgroundColor: "rgba(153,255,255,1)",
        opacity: 1
    },
})(Switch);