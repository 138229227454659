import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";
import {addSignal} from "../store/signal/action";

const useSignal = (key, func) => {
    const value = useSelector(state => {
        return state.signal.signalMap[key]
    })

    const dispatch = useDispatch();

    useEffect(() => {
        if (value) {
            func && typeof(func) === 'function' && func(value)
        }
    }, [value])

    const sendSignal = (k = key) => {
        dispatch(addSignal(k))
    }

    return [value, sendSignal];
};

export default useSignal;