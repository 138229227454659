import React from 'react';

const TransportListChipMarka = (props) => {
    const {transportList} = props
return(<div>
    {transportList ? transportList.map(transport => {
                return <div>
                        {transport.marka}
                    </div>}):
             <div/>}
    </div>
)};

export default TransportListChipMarka;