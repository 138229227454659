import React from 'react';

/** Material **/
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";

/** styles **/
import './style.css';

/** Компонент для отображения уведомления **/
const Notification = (props) => {
    const {isOpen, message, type, handleClose} = props;
    return <Snackbar
        className='notification'
        open={isOpen}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        autoHideDuration={50000}
        onClose={handleClose}
    >
        <Alert elevation={6} variant="filled" severity={type.toLowerCase()} onClose={handleClose}>
            {message}
        </Alert>
    </Snackbar>;
}


export default Notification;