import React from 'react';
import PropTypes from 'prop-types';
import Modal from "../../../../element/Modal";
import ListItem from "../../../../element/List/ListItem";
import ProductTableView from "../../../Product/ProductTableView";

const ShoppingCartViewCategory = props => {
    const {renderItem = (category) => {
        return (<ListItem>{category.name}</ListItem>)
    },
        category, transportId, onChangeShopData, clientOrderActionList} = props
    return (
        <Modal
            startComponent={renderItem(category)}
        >
            <ProductTableView categoryId={category.id}
                              byClient={false}
                              byShop={true}
                              transportId={transportId}
                              onChangeShopData={(shopData, cell, newCount) => {
                                  onChangeShopData({
                                      ...shopData,
                                      categoryId: category.id,
                                      type: category.type === "service" ? category.name :
                                          category.type === "merchandise" ? "Товары" :
                                              "Иное",
                                      price:cell.valueCell
                                  }, cell, newCount)
                              }}
                              clientOrderActionList={clientOrderActionList}/>
        </Modal>
    );
};

ShoppingCartViewCategory.propTypes = {
    category:PropTypes.object,
};

export default ShoppingCartViewCategory;