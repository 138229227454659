import React, {Fragment, useEffect, useState} from 'react';
import './style.css'
import {getFile} from "../../../../../Rest/RestFunction";
import Box from "@material-ui/core/Box";
import {height} from "@mui/system";
import {getImage, getMoneyString} from "../../../../../utils/commonUtils";
import {useNavigate} from "react-router-dom"


export default function ProductCard(props) {

    const navigate = useNavigate()
    const {product} = props

    return (
        <Box
            sx={{
                width:{xs:"40vw",sm:"300px"},
                maxWidth:{xs:"60vw", sm: "400px"},
                flexGrow:1,
                margin:{xs:"3px", sm:"10px"},
                borderRadius:"10px"
            }}
            onClick={() => {navigate(`/product/${product.id}`)}}
            className={"light-pointer"}
        >
            <Box sx={{
                height:{xs:"50vw", sm:"350px"},
                width:"100%",
                overflow:"hidden",
                borderRadius:"10px"
            }}>
            <img style={{height:"100%", width:"100%", objectFit:"cover"}} src={Array.isArray(product.images) && product.images.length >= 1 ? getImage(product.images[0].url) : null}/>
            </Box>
            <Box sx={{
                display:"flex",
                flexDirection:"column",
                alignItems:"center",
                marginTop:"10px"
            }}>
                <article style={{textAlign:"center"}}>
                {product.name}
                </article>
                <article style={{textAlign:"center"}}>
                {getMoneyString(product.price)}
                </article>
            </Box>

        </Box>
    );
}