import React from 'react';
import Box from "../../../element/Box";
import Product from "../ProductForOffice";
import ControlPanel from "../ControlPanel";

const CatalogMerchandise = () => {
    return (
        <Box>
            <Product type={"merchandise"}/>
        </Box>
    );
};

export default CatalogMerchandise;