import React, {Fragment} from 'react';
import Typography from "@mui/material/Typography";
import {Box} from "@mui/material";
import TypographyDate from "../../../TypographyDate";
import ClientChip from "../../../../components/PrivateOffice/Clients/ClientChip";
import TransportListChip from "../../../../components/PrivateOffice/Transport/TransportListChip";
import _ from 'lodash'
import CommentChip from "../../../../components/PrivateOffice/Comments/CommentChip";
import TransportListChipMarka from "../../../../components/PrivateOffice/Transport/TransportListChipMarka";
import TextField from "../../../../element/TextField";
import ShopComponent from "../ShopComponent";
import CategoryChip from "../../../../components/PrivateOffice/CategoryForOffice/CategoryChip";
import ChangeDateChip from "../../../../element/Chip/ChangeDateChip";
import TextFieldPercent from "../../../../element/TextField/TextFieldPercent";

const Cell = (props) => {
    const {width, flexGrow, value, name, type, row, format, step, indexRow, onChange, byShop = false, onChangeShopData} = props
    const emptyCell = "- -"

    const renderText = (value) => {
        if (!value && value !== 0) {
            return emptyCell
        }
        let result = ""
        if (Array.isArray(value)) {
            result = value.join(", ")
        } else {
            result = value
        }
        if (format) {
            return format.replace("${value}", result)
        }
        return result
    }

    const renderCell = (type, value, indexRow, name) => {
        switch (type) {
            case "TextField":
                return <TextField
                    value={value ? value : ""}
                    onChange={(e) => {
                        onChange && onChange(indexRow, name, e.target.value)
                    }}
                />
                break;
            case "Percent":
                return <TextFieldPercent
                    value={value ? value : 0.0}
                    onChange={(newValue) => {
                        onChange && onChange(indexRow, name, newValue)
                    }}
                    step={step}
                />
                break;
            case "column":
                return <div>
                    {(value && Array.isArray(value) && value.length > 0) ? value.map(element => {
                        return <div>{renderText(element)}</div>
                    }) : emptyCell}
                </div>
            case "date":
                return <TypographyDate value={value} format={format}/>
            case "ClientChip":
                if (value) {
                    return <ClientChip client={value}/>
                } else {
                    return emptyCell
                }
            case "CategoryChip":
                if (value) {
                    return <CategoryChip categoryList={value}/>
                } else {
                    return emptyCell
                }
            case "ChangeDateChip":
                if (value) {
                    return <ChangeDateChip {...value} format={format}/>
                } else {
                    return emptyCell
                }
            // case "TransportListChip":
            //     if (value) {
            //         console.log(value, '= valueTransportStart')
            //         return  <TransportListChip transportList={value}/>
            //     }
            //     else {
            //         return emptyCell
            //     },
            case "TransportListChipMarka":
                if (value) {
                    return <TransportListChipMarka transportList={value}/>
                } else {
                    return emptyCell
                }
            case "CommentChip":
                if (value) {
                    return <CommentChip comments={value}/>
                } else {
                    return emptyCell
                }
            default:
                return <Typography>
                    {renderText(value)}
                </Typography>

        }
    }


    return (
        <Box sx={{
            width: width ? width : "",
            flexGrow: flexGrow ? flexGrow : "1",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
        }}>
            {renderCell(type, name === "" ? row : _.get(row, name), indexRow, name)}
            {byShop === true ? <ShopComponent
                shopData={_.get(row, name.indexOf(".") > 0 ? name.substring(0, name.lastIndexOf(".")) + ".shopData" : "shopData", {})}
                onChangeShopData={onChangeShopData} cell={{
                row: row,
                valueCell: _.get(row, name)
            }}/> : null}
        </Box>
    );
};

export default Cell;