import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import ClientOrderActionByOrderAndType from "./ClientOrderActionByOrderAndType";
import Box from "../../../../element/Box";
import ClientOrderTotalMoney from "../ClientOrderTotalMoney";

const ClientOrderActionByOrder = props => {
    const {sx = {}, actions, onChangeAction} = props
    const [enrichActions, setEnrichActions]= useState()

    const [actionByType, setActionByType] = useState([]) //enriched

    useEffect(()=> {
        let result = [...actions]
        result.map(action => {
            action.sum = action.finalPrice * action.count
            return action
        })
        setEnrichActions(result)
        //Группировка
        let mapTypeIndex = {}
        let arrayType = []
        result.forEach(action => {
            if (mapTypeIndex[action.type] === undefined) {
                mapTypeIndex[action.type] = arrayType.length
                arrayType.push([])
            }
            let findIndex = mapTypeIndex[action.type]
            arrayType[findIndex].push(action)
        })
        setActionByType(arrayType)
    }, [actions])

    return (
        <Box sx={{
            ...sx
        }}>
            {enrichActions ? <ClientOrderTotalMoney actions={enrichActions}/> : null}
            {actionByType && actionByType.map(arrayAction => {
                return (<ClientOrderActionByOrderAndType actions={arrayAction} onChange={onChangeAction}/>)
            })}
        </Box>
    );
};

ClientOrderActionByOrder.propTypes = {
    actions : PropTypes.array
};

export default ClientOrderActionByOrder;