import React from 'react';
import PropTypes from 'prop-types';
import ButtonSub from "../../Button/ButtonSub";
import TextField from "../index";
import ButtonPlus from "../../Button/ButtonPlus";
import Box from "../../Box";

const TextFieldNumber = props => {
    const {
        min = 0,
        sx = {},
        value,
        onChange,
        isRenderButtonSub = (value) => true,
        isRenderTextField = (value) => true,
        isRenderButtonPlus = (value) => true,
        step = 1,
        ...other
    } = props
    return (
        <Box
            sx={{
                display: "flex",
                alignItems: "center",
            }}
        >
            {isRenderButtonSub(value) ? <ButtonSub
                size={"medium"}
                onClick={(e) => {
                    let res = Number.parseInt(value) - 1
                    if (res >= min) {
                        onChange(Number.parseInt(value) - 1)
                    }
                }}
            /> : null}
            {isRenderTextField(value) ? <TextField
                value={value}
                onChange={(e) => {
                    let res = Number.parseInt(e.target.value)
                    if (Number.isNaN(res)) {
                        res = 0
                    }
                    if (res >= min) {
                        onChange && onChange(res)
                    }
                }}
                sx={{
                    width: "100px",
                    ...sx
                }}
                {...other}
            /> : null
            }
            {isRenderButtonPlus(value) ? <ButtonPlus
                size={"medium"}
                onClick={(e) => onChange(Number.parseInt(value) + 1)}
            /> : null}
        </Box>
    );
};

TextFieldNumber.propTypes = {
    min: PropTypes.number,
    value: PropTypes.object,
    onChange: PropTypes.func
};

export default TextFieldNumber;