import {useState, useEffect, useRef} from 'react';
import _ from 'lodash';

export default function useDebounceState(startValue, delay = 1000) {
    const [value, setValue] = useState(startValue);
    const [debouncedValue, setDebouncedValue] = useState(startValue);
    const [countChange, setCountChange] = useState(0)

    useEffect(() => {
        const handler = setTimeout(() => {
            if (!_.isEqual(value, debouncedValue)) {
                setDebouncedValue(_.cloneDeep(value));
                setCountChange(countChange + 1)
            }
        }, delay);
        return () => {
            clearTimeout(handler);
        };
    }, [value]);

    const setState = (newValue) => {
        setValue(newValue)
    }

    return [value, setState, debouncedValue, countChange];
}
