import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {
    addClientWithTransport,
    getTransportById,
    searchTransportCatalog,
    updateTransport
} from "../../../../Rest/RestFunction";
import * as NotificationAction from "../../../../store/notifications/actions";
import {Box, Button} from "@mui/material";
import TextField from "../../../../elements/TextField";
import Autocomplete from "../../../../element/Autocomplete";
import useDebounceState from "../../../../hooks/useDebounceState";
import _ from 'lodash'

const TransportRedactor = (props) => {
    const {id,
        reload,
        clientId,
        client, // Если isAddTransportWithClient === true, то передать надо, иначе пустой будет.
        setClient,
        isAddTransportWithClient //Добавить транспорт сразу с клиентом,
        // так как клиент еще не существует в базе, поэтому дернуть рест, в котором клиент будет вместе с его транспортом
    } = props
    const user = useSelector((state) => state.auth.user);
    const [transport, setTransport] = useState({})
    const [markaSearch, setMarkaSearch, debounceMarkaSearch] = useDebounceState("")
    const [markaSearchResult, setMarkaSearchResult] = useState()
    const dispatch = useDispatch()

    useEffect(() => {
        loadTransport(id)
    }, [])

    useEffect(() => {
        if (debounceMarkaSearch !== 0) {
            searchMarka(debounceMarkaSearch)
        }
    }, [debounceMarkaSearch])

    const searchMarka = async (search) => {
        let result = await searchTransportCatalog({
            tokenAuth: user.tokenAuth,
            searchParams: {
                search:search,
                page:0,
                size:100
            }
        })

        setMarkaSearchResult(result.page.content)
    }

    const loadTransport = async (id) => {
        if (id) {
            let response = await getTransportById({
                tokenAuth: user.tokenAuth,
                id: id
            })
            if (response) {
                if (response.transport) {
                    setTransport(response.transport)
                } else {
                    if (response.message) {
                        dispatch(NotificationAction.showNotification("error", response.message))
                    }
                }
            }
        }
    }

    const changeTransport = (nameParam, value) => {
        setTransport({
            ...transport,
            [nameParam]: value
        })
    }

    const update = async () => {
        if (isAddTransportWithClient === true) {
            let response = await addClientWithTransport({
                tokenAuth: user.tokenAuth,
                client : {
                    ...client,
                    transportList: [{...transport}]
                }
            })
            setTransport(response.client.transportList[0])
            setClient && setClient(response.client)
        } else {
            let response = await updateTransport({
                tokenAuth: user.tokenAuth,
                transport: {
                    ...transport,
                    clientId: transport.clientId ? transport.clientId : clientId
                }
            })
            setTransport(response.transport)
        }
        reload && reload()
    }

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column"
            }}
        >
            <TextField
                label="Номер транспорта"
                value={transport.number}
                onChange={(e) => changeTransport("number", e.target.value)}
            />
            <Autocomplete
                label="Марка/Модель"
                isOptionEqualToValue={(option, value) => _.isEqual(option.id, value.id)}
                value={transport.transportCatalog}
                onChangeTextField={(e) => setMarkaSearch(e.target.value)}
                options={markaSearchResult}
                getOptionLabel={(option) => `${option.brand} ${option.model}`}
                renderOption={(props, option) => (
                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                        {`${option.brand} ${option.model}`}
                    </Box>
                )}
                onChange={(event, newValue) =>
                    changeTransport("transportCatalog", newValue)}
            />
            {/*<Autocomplete*/}
            {/*    label="Модификация"*/}
            {/*    isOptionEqualToValue={(option, value) => _.isEqual(option.id, value.id)}*/}
            {/*    value={transport.transportCatalog}*/}
            {/*    onChangeTextField={(e) => setMarkaSearch(e.target.value)}*/}
            {/*    options={markaSearchResult}*/}
            {/*    getOptionLabel={(option) => `${option.brand} ${option.model}`}*/}
            {/*    renderOption={(props, option) => (*/}
            {/*        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>*/}
            {/*            {`${option.brand} ${option.model}`}*/}
            {/*        </Box>*/}
            {/*    )}*/}
            {/*    onChange={(event, newValue) =>*/}
            {/*        changeTransport("transportCatalog", newValue)}*/}
            {/*/>*/}
            <TextField
                label="Тип двигателя"
                value={transport.typeEngine}
                onChange={(e) => changeTransport("typeEngine", e.target.value)}
            />
            <Button
                onClick={update}
            >
                Сохранить
            </Button>
        </Box>
    );
};

export default TransportRedactor;