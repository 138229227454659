import React, {Fragment, useEffect, useState} from 'react';
import {getClientById, updateClient} from "../../../../Rest/RestFunction";
import {useDispatch, useSelector} from "react-redux";
import {Box, Button} from "@mui/material";
import * as NotificationAction from "../../../../store/notifications/actions";
import {WhiteTextField} from "../../../../elements/WhiteTextField";
import TextField from "../../../../elements/TextField";
import ListField from "../../../../elements/ListField";
import Tabs from "../../../../element/Tabs";
import Tab from "../../../../element/Tabs/Tab";
import TransportClientRedactor from "../../Transport/TransportClientRedactor";
import TabsOffice from "../../../../element/Tabs/TabsOffice";

const ClientRedactor = (props) => {
    const {
        id,
        indexTab = 1
    } = props
    const user = useSelector((state) => state.auth.user);
    const [client, setClient] = useState({
        mobile: [""]
    })
    const dispatch = useDispatch()

    useEffect(() => {
        loadClient(id)
    }, [])

    const loadClient = async (id) => {
        if (id) {
            let response = await getClientById({
                tokenAuth: user.tokenAuth,
                id: id
            })
            if (response) {
                if (response.client) {
                    setClient(response.client)
                } else {
                    if (response.message) {
                        dispatch(NotificationAction.showNotification("error", response.message))
                    }
                }
            }
        }
    }

    const changeClient = (nameParam, value) => {
        setClient({
            ...client,
            [nameParam]: value
        })
    }

    const update = async () => {
        let response = await updateClient({
            tokenAuth: user.tokenAuth,
            client: client
        })
        setClient(response.client)
    }

    const [valueTab, setValueTab] = React.useState(indexTab);

    return (
        <TabsOffice
            value={valueTab}
            setValue={setValueTab}
            header={[
                {
                    label: "Информация о клиенте"
                },
                {
                    label: "Информация о транспорте"
                }
            ]}
        >
            <Tab index={0} value={valueTab}>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column"
                    }}
                >
                    <TextField
                        label="ФИО"
                        value={client.name}
                        onChange={(e) => changeClient("name", e.target.value)}
                    />
                    <TextField
                        label="Email"
                        value={client.email}
                        onChange={(e) => changeClient("email", e.target.value)}
                    />
                    <ListField
                        component={"TextField"}
                        label={"Мобильный номер"}
                        value={client.mobile}
                        onChange={(list) => changeClient("mobile", list)}
                        isDeleteFirst={false}
                    />
                    <TextField
                        label="Дополнительная информация"
                        value={client.description}
                        onChange={(e) => changeClient("description", e.target.value)}
                    />
                    <Button
                        onClick={update}
                    >
                        Сохранить
                    </Button>
                </Box>
            </Tab>
            <Tab index={1} value={valueTab}>
                {client.id ? <TransportClientRedactor clientId={client.id}/> :
                    <TransportClientRedactor client={client} setClient={setClient} isAddTransportWithClient={true}/>
                }
            </Tab>
        </TabsOffice>
    );
};

export default ClientRedactor;