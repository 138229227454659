import React, {Fragment, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {WhiteTextField} from "../../../elements/WhiteTextField";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import {
    addFiles,
    updateCategory,
    getCategoryById,
    deleteCategoryById,
    updateProduct,
    deleteProductById,
    searchProduct,
    getProductFilters,
    getAllRootCategory
} from "../../../Rest/RestFunction";

import AddFiles from "../../AddFiles";
import ProductViewTable from "./ProductViewTableForOffice";
import FileListWithDeletedButton from "../../../elements/FileListWithDeletedButton";
import ModalWindowSizeFree from "../../../elements/ModalWindowSizeFree";
import CategoryView from "../CategoryForOffice/CategoryViewForOffice";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import Typography from "@mui/material/Typography";
import SearchIcon from '@mui/icons-material/Search';
import ProductViewCard from "./ProductViewCardForOffice";
import Box from "@material-ui/core/Box";
import ProductCompositionRedactor from "./ProductCompositionRedactorForOffice";
import ProductFilters from "./ProductFiltersForOffice";
import {useAccessUser} from "../../../service/employees/useAccessUser";
import {UPDATE_PRODUCT} from "../../../constants/accesses";
import TextField from "../../../element/TextField";
import CategoryForOfficeRedactor from "../CategoryForOffice/CategoryForOfficeRedactor";
import ButtonPlus from "../../../element/Button/ButtonPlus";
import ButtonDelete from "../../../element/Button/ButtonDelete";
import './style_ProductForOffice.css'
import _ from "lodash"


export default function Product(props) {
    const sizeElementOfPage = 10
    const {showCatalogs, showSearch, startState, showFilters, type = "merchandise"} = props
    const [componentViewProduct, setComponentViewProduct] = useState(startState && startState.componentViewProduct ? startState.componentViewProduct : "table")

    const [categoryId, setCategoryId] = useState(props.categoryId ? props.categoryId : (startState && startState.categoryId ? startState.categoryId : null))
    const [category, setCategory] = useState({id: categoryId})
    //Управление категорией продукта который меняем
    const [categoryNewProduct, setCategoryNewProduct] = useState({})
    const [productFiltersAccordionOpen, setProductFiltersAccordionOpen] = useState(true)

    const [sorts, setSorts] = useState([])
    const [sort, setSort] = useState({})

    const page = useSelector((state) => state.page.page);

    const [productSearch, setProductSearch] = useState({});

    const user = useSelector((state) => state.auth.user);
    const isUpdateProduct = useAccessUser(UPDATE_PRODUCT, user)
    const controlPanelProduct = useSelector((state) => state.controlPanel.product);

    const location = useSelector((state) => state.router.location.pathname);

    const [newProduct, setNewProduct] = useState({})
    const [filters, setFilters] = useState([]) //Возможные фильтры
    const [search, setSearch] = useState({
        search: startState && startState.search ? startState.search : "",
        filters: startState && startState.selectedFilters ? startState.selectedFilters : [] //выбранные фильтры
    })

    const [currentPage, setCurrentPage] = useState(1)

    //Категории

    // props.showProduct для покзаа продуктов
    const [categoryAccordionOpen, setCategoryAccordionOpen] = useState(true)
    // props.showUpdate для показа элементов редактивания

    const [newCategory, setNewCategory] = useState({name: "", image: {url: ""}})

    const [newFilesCategory, setNewFilesCategory] = useState()

    const [products, setProducts] = useState([])

    const initCategoryNewProduct = async (categoryId) => {
        console.log(type)
        if (categoryId) {
            let category = await getCategoryById(categoryId)
            setCategoryNewProduct(category)
        } else {
            let root = await getAllRootCategory(type)
            let category = {
                name: "Каталоги",
                children: root
            }
            setCategoryNewProduct(category)
        }
    }
    const init = async (page, filters, searchElastic, currentCategoryId, isDownloadProduct) => {
        setCurrentPage(1)
        if (isDownloadProduct === undefined) {
            isDownloadProduct = true
        }

        if (!page) {
            page = currentPage
        }

        if (!filters) {
            filters = search.filters
        }
        if (!searchElastic && searchElastic !== "") {
            searchElastic = search.search
        }
        //Смотрим есть ли в переданных переменных
        if (!currentCategoryId) {
            //Если нет, то смотрим инициализирована ли категория
            if (category && category.id) {
                currentCategoryId = category.id
            } else {
                // Если не инициализирована, то возьмем переданную в пропсах
                currentCategoryId = categoryId
            }
        }

        if (currentCategoryId === "root") {
            currentCategoryId = null
            setFilters(null)
        }

        let currentCategory
        if (currentCategoryId != null) {
            setCategoryId(currentCategoryId)
            currentCategory = await getCategoryById(currentCategoryId)
        } else {
            setCategoryId(null)
            let root = await getAllRootCategory(type)
            currentCategory = {
                name: "Каталоги",
                children: root
            }
            console.log(root, "root")
        }
        setCategory(currentCategory)


        if (showFilters !== false) {
            if (currentCategoryId || searchElastic) {
                let filtersResponse = await getProductFilters({
                    categoryId: currentCategoryId,
                    search: searchElastic,
                    isPresence: controlPanelProduct && controlPanelProduct.showUpdateElements === true ? null : true
                })
                let categoryFilter = []
                if (currentCategory && currentCategory.dopParams) {
                    currentCategory.dopParams.forEach(dopParam => {
                        filtersResponse.filters.filter(filter => filter.name === dopParam.name).forEach(filter => {
                            filter.nameDisplay = dopParam.displayName;
                            categoryFilter.push(filter)
                        })
                    })
                }
                filtersResponse && filtersResponse.filters && filtersResponse.filters
                    .filter(filter => !filter.nameProperty.includes("dopParams"))
                    .forEach(filter => {
                    categoryFilter.push(filter)
                })
                setFilters(categoryFilter)
            } else {
                setFilters(null)
            }
        }

        if (searchElastic) {
            setCategoryId(null)
        }

        if (isDownloadProduct) {

            if (searchElastic || (currentCategoryId !== null)) {
                let productSearch = await searchProduct({
                    sorts: sorts,
                    categoryId: searchElastic ? null : currentCategoryId,
                    page: page,
                    size: sizeElementOfPage,
                    isPresence: controlPanelProduct && controlPanelProduct.showUpdateElements === true ? null : true,
                    search: searchElastic,
                    filters: filters
                })

                setProductSearch(productSearch)
                productSearch.content.forEach(product => {
                    if (product.id === newProduct.id) {
                        setProductToChange(product)
                    }
                })
                setProducts(productSearch.content)
            } else {
                setProductSearch({})
            }
        }
        //dispatch(ProductActions.productSearch({categoryId: props.categoryId, page: page, size: 10}))
    }

    React.useEffect(() => {
        console.log(type, "type")

        init(currentPage, undefined, undefined, undefined, true)
        initEmptyProduct(true)
    }, []);

    const getNextPage = async () => {
        const nextPageIndex = currentPage + 1
        setCurrentPage(nextPageIndex)
        const productSearch = await searchProduct({
            sorts: sorts,
            categoryId: search.search ? null : category.id,
            page: nextPageIndex,
            size: sizeElementOfPage,
            isPresence: controlPanelProduct && controlPanelProduct.showUpdateElements === true ? null : true,
            search: search.search,
            filters: search.filters
        })
        setProductSearch(productSearch)
        setProducts([...products, ...productSearch.content])
    }


    const [newFiles, setNewFiles] = useState()

    const handleChangeFiles = (files) => {
        setNewFiles(files)
    }

    const onChangeNewProduct = (nameProperty) => (e) => {
        let product = {
            ...newProduct,
            [nameProperty]: e.target.value
        }
        setNewProduct(product)
    }

    const onChangeCompositionInNewProduct = (composition) => {
        let product = {
            ...newProduct,
            composition: composition
        }
        setNewProduct(product)
    }

    const onChangeDopParam = (nameProperty) => (e) => {
        let product = {
            ...newProduct
        }
        product.dopParams = {
            ...product.dopParams,
            [nameProperty]: e.target.value
        }
        setNewProduct(product)
    }

    const saveFilesToServer = async () => {
        let quid_files = [];
        if (newFiles.length > 0) {
            quid_files = await addFiles(newFiles)

            let images = quid_files && quid_files.map(quid_file => {
                return {
                    url: quid_file
                }
            })

            let product = {...newProduct}
            await product.images.push(...images)
        }
        setNewFiles([])
    }

    const deleteImageInProduct = (index) => () => {
        let product = {...newProduct}
        product.images.splice(index, 1)
        setNewProduct(product)
    }

    const onClickSaveProduct = async () => {
        let productFromSave = newProduct
        await saveFilesToServer()
        if (productFromSave.categoryId === null) {

        }
        productFromSave.type=type
        await updateProduct(productFromSave, user.tokenAuth)
        init(currentPage)
        initEmptyProduct(true)
    }

    const changePage = (event, value) => {
        setCurrentPage(value)
        init(value)
    }

    const onClickSetProductToChange = (product) => () => {
        //setNewProduct(product)
        setProductToChange(product)
    }

    const setProductToChange = async (product) => {
        //setNewProduct(product)
        let category;
        if (product.categoryId === null) {
            let root = await getAllRootCategory()
            category = {
                name: "Каталоги",
                children: root
            }
        } else {
            category = await getCategoryById(product.categoryId)
        }
        setCategoryNewProduct(category)
        let productToNewProduct = {...product}
        productToNewProduct.categoryName = category.name
        setNewProduct(productToNewProduct)
    }


    const initEmptyProduct = (clear) => {
        if ((newProduct.id != null) || (clear === true)) {
            setNewProduct({
                article: "",
                brand: "",
                country: "",
                name: "",
                description: "",
                isNew: true,
                originalArticle: "",
                categoryName: category ? category.name : "Категории",
                categoryId: category ? category.id : null,
                count: 1,
                price: 1000,
                catalogItems: [],
                images: []
            })
        } else {
            setNewProduct({
                ...newProduct,
                categoryName: category ? category.name : "Категории",
                categoryId: category ? category.id : null
            })
        }
        initCategoryNewProduct(category ? category.id : null)
    }

    const handleIsNew = (event) => {
        setNewProduct({
            ...newProduct,
            isNew: event.target.checked
        })
    }

    const onDeleteProduct = (product) => async () => {
        await deleteProductById(product.id, user.tokenAuth)
        init(currentPage)
    }

    const onCheckedCatalog = (catalog) => (event) => {
        console.log(catalog)
    };

    const modalAdd = () => {

        return <div style={{width: "800px", height: "700px"}}>
            <FileListWithDeletedButton deleteFileFunction={deleteImageInProduct} files={newProduct.images}/>
            <AddFiles handleChangeFiles={handleChangeFiles} files={newFiles}
                      saveFilesToServer={saveFilesToServer}/>
            <WhiteTextField className={"field_Text_Param"} style={{width: "205px"}}
                            variant="outlined"
                            multiline
                            label="Артикул"
                            value={newProduct.article ? newProduct.article : ""}
                            onChange={onChangeNewProduct("article")}
            />
            {/*<WhiteTextField className={"field_Text_Param"} style={{width: "205px"}}*/}
            {/*                variant="outlined"*/}
            {/*                multiline*/}
            {/*                label="Артикул оригинала"*/}
            {/*                value={newProduct.originalArticle ? newProduct.originalArticle : ""}*/}
            {/*                onChange={onChangeNewProduct("originalArticle")}*/}
            {/*/>*/}
            {/*<WhiteTextField className={"field_Text_Param"} style={{width: "205px"}}*/}
            {/*                variant="outlined"*/}
            {/*                multiline*/}
            {/*                label="Производитель"*/}
            {/*                value={newProduct.brand ? newProduct.brand : ""}*/}
            {/*                onChange={onChangeNewProduct("brand")}*/}
            {/*/>*/}
            <WhiteTextField className={"field_Text_Param"} style={{width: "300px"}}
                            variant="outlined"
                            multiline
                            label="Имя товара"
                            value={newProduct.name ? newProduct.name : ""}
                            onChange={onChangeNewProduct("name")}
            />
            <WhiteTextField className={"field_Text_Param"} style={{width: "205px"}}
                            variant="outlined"
                            multiline
                            label="Страна происхождения"
                            value={newProduct.country ? newProduct.country : ""}
                            onChange={onChangeNewProduct("country")}
            />


            <WhiteTextField className={"field_Text_Param"} style={{width: "400px"}}
                            variant="outlined"
                            multiline
                            label="Описание товара"
                            value={newProduct.description ? newProduct.description : ""}
                            onChange={onChangeNewProduct("description")}
            />
            {/*<WhiteTextField className={"field_Text_Param"} style={{width: "205px"}}*/}
            {/*                variant="outlined"*/}
            {/*                multiline*/}
            {/*                label="Состоит из:"*/}
            {/*                value={""}*/}
            {/*                onChange={onChangeNewProduct("country")}*/}
            {/*/>*/}
            {/*<WhiteTextField className={"field_Text_Param"} style={{width: "100px"}}*/}
            {/*                variant="outlined"*/}
            {/*                type="number"*/}
            {/*                label="Количество"*/}
            {/*                disabled={!(newProduct.id === null || newProduct.id === undefined)}*/}
            {/*                value={newProduct.count ? newProduct.count : ""}*/}
            {/*                onChange={onChangeNewProduct("count")}*/}
            {/*/>*/}
            <WhiteTextField className={"field_Text_Param"} style={{width: "150px"}}
                            variant="outlined"
                            type="number"
                            label="Цена"
                            value={newProduct.price ? newProduct.price : ""}
                            onChange={onChangeNewProduct("price")}
            />

            {categoryNewProduct && categoryNewProduct.dopParams && categoryNewProduct.dopParams.map((param) => {
                return <WhiteTextField className={"field_Text_Param"}
                                       variant="outlined"
                                       label={param.displayName ? param.displayName : param.name}
                                       value={newProduct.dopParams && newProduct.dopParams[param.name] ? newProduct.dopParams[param.name] : ""}
                                       onChange={onChangeDopParam(param.name)}
                />
            })}

            {/*<div className={"flex"}>*/}
            {/*    <Typography style={{margin: "auto 0"}}*/}
            {/*                className={`${newProduct.isNew === false ? 'selected' : ''}`}>Б/У</Typography>*/}
            {/*    <CustomSwitch*/}
            {/*        onChange={handleIsNew}*/}
            {/*        checked={newProduct.isNew}*/}
            {/*        color="secondary"*/}
            {/*        inputProps={{'aria-label': 'checkbox with default color'}}*/}
            {/*    />*/}
            {/*    <Typography style={{margin: "auto 0"}}*/}
            {/*                className={`${newProduct.isNew === true ? 'selected' : ''}`}>Новый</Typography>*/}
            {/*</div>*/}

            <Typography>Выбранная категория: {newProduct.categoryName}</Typography>

            <Box>
                <div>
                    Состав:
                </div>
                <ProductCompositionRedactor
                    composition={newProduct.composition}
                    changeComposition={(composition) => onChangeCompositionInNewProduct(composition)}
                    type={type}
                />
            </Box>

            <div className="flex">
                <ModalWindowSizeFree
                    startComponent={<Button className="button_Select_Text_Product" style={{padding: "6px 0px"}}>Выбрать
                        категорию</Button>}
                >
                    <CategoryView
                        delete
                        onChangeCategory={onChangeProductCategory}
                        category={categoryNewProduct}

                        // onChangeCategory={onChangeCategory}
                        //category={category}

                        showRedactor={false}
                        categoryAccordionOpen={true}
                        setCategoryAccordionOpen={setCategoryAccordionOpen}
                    >
                    </CategoryView>
                </ModalWindowSizeFree>

                {/*<ModalWindowSizeFree*/}
                {/*    startComponent={<Button className="button_Select_Text_Product"*/}
                {/*                            style={{padding: "6px 0px", marginLeft: "12px"}}>Применимость</Button>}*/}
                {/*>*/}
                {/*    {<Catalogs onCheckedCatalog={onCheckedCatalog}*/}
                {/*               showItems={true}*/}
                {/*               showRedactorItems={false}*/}
                {/*               showRedactor={false}*/}
                {/*               onCheckCatalog={onCheckCatalog}*/}
                {/*               onCheckCatalogItem={onCheckCatalogItem}*/}
                {/*               checkedCatalogs={newProduct.catalogList}*/}
                {/*    />}*/}
                {/*</ModalWindowSizeFree>*/}

                {/*{newProduct.id &&*/}
                {/*<ChangeCountProductModalForOffice productId={newProduct.id} updateState={() => init()}/>*/}
                {/*}*/}
            </div>
        </div>
    }

    const modalAddActions = () => {
        return [
            <Button
                className="buttonBlue_View_Product"
                variant="outlined">
                Отмена
            </Button>,
            <Button
                className="buttonGreen_Product"
                variant="outlined"
                onClick={onClickSaveProduct}
            >
                Сохранить
            </Button>
        ]
    }

    const changeSorts = (nameProperty) => () => {
        let newSorts = []
        let isFind = false;
        let deleteElement = -1

        sorts.forEach((sort, index) => {
            if (sort.name === nameProperty) {
                isFind = true;
                if (sort.typeSort === "ASC") {
                    sort.typeSort = "DESC"
                } else {
                    if (sort.typeSort === "DESC") {
                        deleteElement = index
                    }
                }
            }
        })

        if (isFind === false) {
            sorts.push({name: nameProperty, typeSort: "ASC"})
        } else {
            if (deleteElement !== -1) {
                sorts.splice(deleteElement, 1)
            }
        }


        setCurrentPage(1)
        init(1)
    }

    const changeSort = (nameProperty) => () => {
        let newSort = {...sort};
        if (sort.name === nameProperty) {
            if (sort.typeSort === "ASC") {
                newSort.typeSort = "DESC"
                setSort(newSort)
                sorts[0] = newSort
                setSorts([newSort])
            } else {
                if (sort.typeSort === "DESC") {
                    setSort({})
                    sorts.splice(0, 1)
                    setSorts([])
                }
            }
        } else {
            setSort({name: nameProperty, typeSort: "ASC"})
            sorts[0] = {name: nameProperty, typeSort: "ASC"}
            setSorts([{name: nameProperty, typeSort: "ASC"}])
        }
        setCurrentPage(1)
        init(1)
    }

    const onChangeSelectedFilters = (newFilters) => {
        setSearch({...search, filters: newFilters})
        init(page, newFilters)
    }

    const onChangeSearch = (nameProperty) => (event) => {
        let newSearch = {
            ...search,
            [nameProperty]: event.target.value
        }
        setSearch(newSearch)
        //props.setSearchProduct(newSearch) // доп 19.08
    }

    const onClickDeleteFilter = (nameProperty, value) => (event) => {
        let newFilters = search.filters.map(filter => {
            if (filter.nameProperty === nameProperty) {
                filter.values.splice(filter.values.indexOf(value), 1)
            }
            if (filter.values.length > 0) {
                return filter
            }
        }).filter(filter => filter != null)
        setSearch({...search, filters: newFilters})
        init(page, newFilters)
    }
    const onClickDeleteAllFilters = () => {
        setSearch({...search, filters: []})
        init(page, [])
    }

    const enterUp = (e) => {
        if (e.keyCode === 13) {
            setSearch({
                ...search,
                filters: []
            })
            setCategoryAccordionOpen(false)
            init(page, [], search.search, "root")
        }
    }

    const onCheckCatalogNotZamok = (product, catalog, checked) => {
        if (checked === false) {
            let indexDelete = null
            product.catalogList.forEach((cat, index) => {
                if (cat.id === catalog.id) {
                    indexDelete = index
                }
            })
            if (indexDelete !== null) {
                product.catalogList.splice(indexDelete, 1)
                if (catalog.children) {
                    catalog.children.forEach(child => {
                        onCheckCatalogNotZamok(product, child, checked)
                    })
                }
            }
        }
        if (checked === true) {
            if (catalog.children !== null) {
                catalog.children.forEach(child => {
                    onCheckCatalogNotZamok(product, child, checked)
                })
            }
            let find = false
            product.catalogList.forEach((cat, index) => {
                if (cat.id === catalog.id) {
                    find = true
                }
            })
            if (!find) {
                product.catalogList.push({
                    id: catalog.id,
                    items: catalog.items && catalog.items.map(item => {
                        return {id: item.id}
                    })
                })
            }
        }

    }

    const handleChangeFilesCategory = (files) => {
        setNewFilesCategory(files)
    }

    const onChangeNewCategory = (key, value) => {
        let category = {...newCategory}
        _.set(category, key, value)
        setNewCategory(category)
    }

    const saveFilesCategoryToServer = async () => {
        let quid_files = [];
        if (newFilesCategory.length > 0) {
            quid_files = await addFiles(newFilesCategory)

            quid_files && quid_files.forEach(quid_file => {
                let category = newCategory
                category.image = {
                    url: quid_file
                }
                setNewCategory(category)
            })
        }
        setNewFilesCategory([])
    }

    const onClickSaveCategory = async () => {
        await saveFilesCategoryToServer()
        let categoryFromSave = newCategory
        categoryFromSave.parentId = categoryId
        categoryFromSave.type= type
        await updateCategory(categoryFromSave, user.tokenAuth)
        init()
    }

    const onChangeCategory = (newCategoryId) => async () => {

        setCategoryId(newCategoryId)
        setSearch({...search, filters: [], search: ""})
        await init(page, [], "", newCategoryId)
        if (newCategoryId === "root") {
            setCategoryAccordionOpen(true)
        }
    }

    const onChangeProductCategory = (newCategoryId) => async () => {
        let currentCategory
        if (newCategoryId === "root") {
            newCategoryId = null
        }
        if (newCategoryId !== null) {
            currentCategory = await getCategoryById(newCategoryId)
        } else {
            let root = await getAllRootCategory()
            currentCategory = {
                name: "Каталоги",
                children: root
            }
        }
        setCategoryNewProduct(currentCategory)
        let newProd = {
            ...newProduct,
            categoryId: newCategoryId,
            categoryName: currentCategory.name
        }
        setNewProduct(newProd)
    }

    const setCategoryToChange = (category) => async () => {
        let categoryWithDopParam = await getCategoryById(category.id)
        setNewCategory(categoryWithDopParam)
    }

    const onDeleteImage = () => {
        let cat = newCategory
        cat.image = {}
        setNewCategory(cat)
    }

    const onDeleteCategory = (deleteId) => async () => {
        await deleteCategoryById(deleteId, user.tokenAuth)
        init(page, search.filters, search.search)
    }

    const onChangeDopParamCategory = (index, nameParam) => (event) => {
        let category = {...newCategory}
        category.dopParams[index] = {
            ...newCategory.dopParams[index],
            [nameParam]: event.target.value
        };
        setNewCategory(category)
    }
    const onChangeDopParamBooleanCategory = (index, nameParam) => (event) => {
        let category = {...newCategory}
        category.dopParams[index] = {
            ...newCategory.dopParams[index],
            [nameParam]: event.target.checked
        };
        setNewCategory(category)
    }

    const onChangeAddDopParamCategory = (index) => () => {
        let dopParams = newCategory.dopParams
        let category = {...newCategory}
        if ((dopParams == null) || (dopParams.length === 0)) {
            category.dopParams = [{}]
        } else {
            let firstDopParams = dopParams.slice(0, index + 1)
            let secondDopParams = dopParams.slice(index + 1, dopParams.length)
            category.dopParams = [...firstDopParams, {}, ...secondDopParams]
        }
        setNewCategory(category)
    }

    const onChangeDeleteDopParamCategory = (index) => () => {
        let dopParams = newCategory.dopParams
        dopParams.splice(index, 1)
        let category = {...newCategory}
        category.dopParams = dopParams
        setNewCategory(category)
    }

    const isCurrentCategory = (category, dopParam) => {
        return (!dopParam.categoryId) || (dopParam.categoryId === category.id)
    }

    const renderDopParams = (category) => {
        return category.dopParams && category.dopParams.length > 0 ? category.dopParams.map((dopParam, index) => {
                return <div style={{
                    display:"flex",
                    gap:"0.5rem",
                    margin: "0.5rem",
                    alignItems:"center",
                }}>
                    <TextField
                                    label="Название переменной на английском языке"
                                    disabled={!isCurrentCategory(category, dopParam)}
                                    value={dopParam.name ? dopParam.name : ""}
                                    onChange={onChangeDopParamCategory(index, 'name')}
                    />
                    <TextField
                                    label="Название для пользователя"
                                    disabled={!isCurrentCategory(category, dopParam)}
                                    value={dopParam.displayName ? dopParam.displayName : ""}
                                    onChange={onChangeDopParamCategory(index, 'displayName')}
                    />
                    <Checkbox
                        disabled={!isCurrentCategory(category, dopParam)}
                        checked={dopParam.showTable}
                        onChange={onChangeDopParamBooleanCategory(index, 'showTable')}
                    />
                    {isCurrentCategory(category, dopParam) &&
                    <Fragment>
                        <ButtonPlus onClick={onChangeAddDopParamCategory(index)}/>
                        <ButtonDelete onClick={onChangeDeleteDopParamCategory(index)}/>
                    </Fragment>
                    }
                </div>
            }) :
            <Fab size="small" onClick={onChangeAddDopParamCategory(0)}>
                <AddIcon/>
            </Fab>
    }

    return (
        <Fragment>
            <div className={""}>
                {showSearch !== false ?
                    <div className={"flex product_search_office"} style={{marginTop: "14px"}}>
                        <TextField
                            variant="outlined"
                            label={type? (type ==="service"? "Поиск услуг": type === "merchandise"?"Поиск товаров": "Поиск"):"Поиск"}
                            value={search.search ? search.search : ""}
                            onChange={onChangeSearch("search")}
                            onKeyUp={enterUp}
                        />
                        <button className={"buttonSearchProduct_office"}
                                onClick={(() => {
                                    setSearch({...search, filters: []})
                                    setCategoryAccordionOpen(false)
                                    init(page, [], search.search, "root")
                                })}>
                            <SearchIcon/>
                        </button>
                    </div>
                    : null
                }

                {showCatalogs !== false ?
                    <CategoryView
                        delete
                        onChangeCategory={onChangeCategory}
                        category={category}
                        setCategoryToChange={setCategoryToChange}
                        showRedactor={props.showUpdate === false ? false : controlPanelProduct.showUpdateElements}
                        onDeleteCategory={onDeleteCategory}
                        categoryAccordionOpen={categoryAccordionOpen}
                        setCategoryAccordionOpen={setCategoryAccordionOpen}
                        modalAdd={
                            <CategoryForOfficeRedactor
                                handleChangeFilesCategory={handleChangeFilesCategory}
                                newFiles={newFiles}
                                saveFilesCategoryToServer={saveFilesCategoryToServer}
                                newCategory={newCategory}
                                onDeleteImage={onDeleteImage}
                                onChangeNewCategory={onChangeNewCategory}
                                renderDopParams={renderDopParams}
                            />}
                        onClickSaveCategory={onClickSaveCategory}
                        modalActions={[
                            <Button
                                variant="outlined">
                                Отмена
                            </Button>,
                            <Button
                                variant="outlined"
                                onClick={onClickSaveCategory}
                            >
                                Сохранить
                            </Button>
                        ]}
                    >
                    </CategoryView>
                    : null
                }
                <div className={"flex"}>
                    <ProductFilters showFilters={showFilters}
                                    filters={filters}
                                    selectedFilters={search.filters}
                                    productFiltersAccordionOpen={productFiltersAccordionOpen}
                                    setProductFiltersAccordionOpen={setProductFiltersAccordionOpen}
                                    onChangeSelectedFilters={onChangeSelectedFilters}
                                    direction="column"
                    />
                    <article style={{flexGrow: 10000}}>
                        {productSearch.content && productSearch.content.length > 0 ?

                            (componentViewProduct === "table" ?
                                    <ProductViewTable
                                        products={products}
                                        nextPage={getNextPage}
                                        hasMore={!productSearch.last}
                                        category={category}
                                        sorts={sorts}
                                        changeSort={changeSorts}
                                        showRedactor={controlPanelProduct.showUpdateElements}
                                        setProductToChange={onClickSetProductToChange}
                                        onDeleteProduct={onDeleteProduct}
                                        modalAdd={modalAdd()}
                                        modalActions={modalAddActions()}
                                    /> : (componentViewProduct === "card" ?
                                            <ProductViewCard
                                                products={products}
                                                nextPage={getNextPage}
                                                hasMore={!productSearch.last}
                                                category={category}
                                                sorts={sorts}
                                                changeSort={changeSorts}
                                                showRedactor={controlPanelProduct.showUpdateElements}
                                                setProductToChange={onClickSetProductToChange}
                                                onDeleteProduct={onDeleteProduct}
                                                modalAdd={modalAdd()}
                                                modalActions={modalAddActions()}
                                            /> : null
                                    )
                            )
                            : null
                        }
                        {controlPanelProduct.showUpdateElements === true &&
                        <div>
                            <ModalWindowSizeFree
                                startComponent={
                                    <Button onClick={initEmptyProduct}>
                                        {type === "merchandise" ? "Добавить товар" : "Добавить услугу"}
                                    </Button>}
                                actions={modalAddActions()}
                            >
                                {modalAdd()}
                            </ModalWindowSizeFree>
                        </div>
                        }
                    </article>
                </div>
            </div>

        </Fragment>
    );
}