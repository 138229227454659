
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import {Link} from "react-router-dom";
import './style.css';
import {useDispatch} from "react-redux";
import * as PageActions from "../../store/page/action";


const StyledMenu = withStyles({
    paper: {
        backgroundColor: 'rgba(9,9,9, 0.9) !important',
        border: '1px solid #d3d4d5',
        color:'white'
    },
})((props) => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
        {...props}
    />
));

const StyledMenuItem = withStyles((theme) => ({
    root: {
        '&:focus': {
            // backgroundColor: theme.palette.primary.main,
            '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
                color: theme.palette.common.white,
            },
        },
    },
}))(MenuItem);

export default function ListMenu(props) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const dispatch = useDispatch()

    const changePage = (pageName)  => {
        dispatch(PageActions.changePage(pageName))
    }

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose2  = () => {
        setAnchorEl(null);
    };

    const handleClose = (link) => () => {
        setAnchorEl(null);
        changePage(link)
    };

    return (
        // <div style={{position: " absolute"}}>
        <div>
            <Button className={props.mobile?"styleButtonListMenuMobile":"styleButtonListMenu"}
                    aria-controls="customized-menu"
                    aria-haspopup="true"
                    variant="contained"
                    onClick={handleClick}
                    style={{padding: "8px"}}
            >
                {props.title}   
            </Button>
            {/*<div style={{position: "relative"}}>*/}
            <StyledMenu
                id="customized-menu"
                style={{position: "absolute"}}
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose2}
            >
                {props.elements.map((elem, index) => (
                    <StyledMenuItem key={index}><Link to={elem.link} onClick={handleClose(elem.link)}>{elem.name}</Link></StyledMenuItem>
                ))
                }
            </StyledMenu>
            {/*</div>*/}
        </div>
    );
}