import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

/** actions **/
import { hideNotification } from '../../store/notifications/actions';

/** components **/
import Notification from '../Notification';

/** styles **/
import './style.css';

/** Контейнер для отображения стека уведомлений **/
const Notifier = () => {
    
    const { data } = useSelector(state => state.notifications);
    const dispatch = useDispatch();

    const handleClose = (id) => (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        dispatch(hideNotification(id))
    };

    const renderNotifications = () => {
        return data.map((item, index) => {
            return <Notification
                key={index}
                id={item.id}
                message={item.message}
                type={item.type}
                isOpen={item.isOpen}
                handleClose={handleClose(item.id)}
            />
        })
    }

    return (
        <div className='notifier'>
            {
                renderNotifications()
            }
        </div>
    )
}

export default Notifier;