import React, {useEffect, useState} from 'react';
import AddFiles from "../../../AddFiles";
import TextField from "../../../../element/TextField";
import Button from "../../../../element/Button";
import Box from "../../../../element/Box";
import BoxEasyOpen from "../../../../element/BoxEasyOpen";
import Typography from "../../../../element/Typography";
import ListItemText from "@mui/material/ListItemText";
import TransferList from "../../../../element/TransferList";
import {getClassTransportAll} from "../../../../Rest/RestFunction";
import {useSelector} from "react-redux";

const CategoryForOfficeRedactor = (props) => {
    const {
        handleChangeFilesCategory,
        newFiles,
        saveFilesCategoryToServer,
        onDeleteImage,
        newCategory,
        onChangeNewCategory,
        renderDopParams
    } = props

    const [openRedactorImageCategory, setOpenRedactorImageCategory] = useState(false)
    const [classTransportList, setClassTransportList] = useState()
    const user = useSelector((state) => state.auth.user);

    useEffect(() => {
        initClassTransport()
    }, [])

    const initClassTransport = async () => {
        let response = await getClassTransportAll({
            tokenAuth: user.tokenAuth,
        })
        setClassTransportList(response.list)
    }

    return (
        <div style={{
            display: "flex",
            flexDirection: "column",
            gap: "0.5rem"
        }}>
            <TextField
                multiline
                label="Название категории"
                value={newCategory.name ? newCategory.name : ""}
                onChange={(e) => onChangeNewCategory("name", e.target.value)}
            />
            <Button
                onClick={() => setOpenRedactorImageCategory(!openRedactorImageCategory)}
            >
                Редактировать фотографию категории
            </Button>
            <BoxEasyOpen sx={{
                display: "flex",
                gap: "0.5rem"
            }}
                         open={openRedactorImageCategory}
            >
                <Box sx={{
                    width: "100px",
                    flexGrow: "1",
                }}>
                    <AddFiles handleChangeFiles={handleChangeFilesCategory} files={newFiles}
                              saveFilesToServer={saveFilesCategoryToServer}/>
                </Box>
                <Button
                    variant="outlined"
                    onClick={onDeleteImage}
                >
                    Очистить
                </Button>
            </BoxEasyOpen>

            <div>
                <Typography>
                    Дополнительные параметры для категории
                </Typography>
                {renderDopParams(newCategory)}
            </div>

            <div>
                <div style={{
                    display: "flex",
                    justifyContent: "space-between"
                }}>
                    <Typography>
                        Все классы транспорта в приложении
                    </Typography>
                    <Typography>
                        Классы транспорта в этой категории
                    </Typography>
                </div>
                <TransferList
                    leftList={classTransportList}
                    setLeftList={setClassTransportList}
                    renderElement={(value, labelId) => {
                        return (<ListItemText id={labelId} primary={`${value.group} -> ${value.name}`}/>)
                    }}

                    rightList={newCategory.classTransportList}
                    setRightList={(newList) => onChangeNewCategory("classTransportList", newList)}
                />
            </div>

        </div>
    );
};

export default CategoryForOfficeRedactor;