import React from 'react';
import Box from "../../../../element/Box";
import Comment from "./Comment";
import {useSelector} from "react-redux";

const CommentList = (props) => {
    const {comments} = props
    const user = useSelector((state) => state.auth.user);
    return (
        <Box sx={{
            display:"flex",
            flexDirection:"column",
            gap:"0.25rem"
        }}>
            {comments && comments.map(comment => {
                return <Box sx={{
                    maxWidth:"70%",
                    alignSelf:user.id === comment.employeeId ? "flex-end" : "flex-start"
                }}>
                <Comment comment={comment}/>
                </Box>
            })}
        </Box>
    );
};

export default CommentList;