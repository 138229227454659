import React from 'react';
import moment from "moment";
import Box from "../../element/Box";

const TypographyDate = (props) => {
    const {label="", value, format, sx={}} = props
    return (
        <Box sx={{
            textAlign:"center",
            whiteSpace:"pre",
            ...sx
        }}>
            {
                label ? `${label} ${moment(value).format(format ? format : "DD.MM.yyyy HH:mm")}` :
                    `${moment(value).format(format ? format : "DD.MM.yyyy HH:mm")}`
            }
        </Box>
    );
};

export default TypographyDate;