import * as types from './actionTypes';

const initState = {
    requests: {},
    states: {},
};

export default function reduce(state = initState, action = {}) {
    switch (action.type) {
        case types.UPDATE_REQUEST_CACHE:
            return {
                ...state,
                requests: {
                    ...state.requests,
                    [action.key]:action.body
                },
            }
        case types.UPDATE_STATE_CACHE:
            return {
                ...state,
                states: {
                    ...state.states,
                    [action.key]:action.body
                },
            }
        default:
            return state;
    }
}