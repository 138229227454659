import React, {Fragment, useEffect} from "react";
import { useDispatch, useSelector} from 'react-redux';

import {Route, Routes} from "react-router-dom";

/** store **/
import {history} from "../../store/configureStore";

/** styles **/
import './style.css';


import * as AuthActions from "../../store/auth/action"

import Subscriber from "../../service/Subscriber";
import Notifier from "../Notifier";
import WithBackground from "../WithBackgound";
import PrivateOfficeRouter from "../PrivateOfficeRouter";
import {createTheme} from "@mui/material";
import ThemeProvider from "@mui/material/styles/ThemeProvider";


export default function Main(props) {
    const dispatch = useDispatch()
    const user = useSelector((state) => state.auth.user);

    useEffect(() => {
        dispatch(AuthActions.checkAuth())
    }, [])

    const theme = createTheme({
        breakpoints: {
            values: {
                xs: 0,
                sm: 600,
                sm650:650,
                sm700:700,
                sm750:750,
                sm800:800,
                sm850:850,
                md: 900,
                md950:950,
                md1000:1000,
                md1050:1050,
                md1100:1100,
                md1150:1150,
                lg: 1200,
                lg1250:1250,
                lg1300:1300,
                lg1350:1350,
                lg1400:1400,
                lg1450:1450,
                lg1500:1500,
                xl: 1536,
                xl1550: 1550,
                xl1600: 1600,
                xl1650: 1650,
            },
        },
    });

    return (
        <Fragment>
            <ThemeProvider theme={theme}>
            <Routes>
                {user && user.id && <Route path="/privateOffice/*" element={<PrivateOfficeRouter/>}/>}
                <Route path="*" element={<WithBackground/>}/>
            </Routes>
            <Notifier/>

            {user && user.id ? <Subscriber/> : null}
            </ThemeProvider>
        </Fragment>
    );
}
