/*компонент, реализующий функциональность вертикального DragNDrop списка*/

import React, {useCallback} from 'react';
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import {searchTransportCatalog} from "../../Rest/RestFunction";

/*свойства по умолчанию*/
DragOnDrop.defaultProps = {
    /*классы для стилизации*/
    classes: {},
    /*свойства рендер компонента(к примеру хенлер клика)*/
    renderprops: {},
    /*свойства области перетаскивания*/
    droppableAreaProps: {},
    /** имя поля объекта, которое будет использоваться в качестве draggableId,
     * значение '' означает, что был передан не объект, а строка, которая будет draggableId**/
    keyFieldName: 'dragOnDropIndex',
    /*хендлер события начала перетаскивания элемента*/
    onDragStart: null,
    /*свойство компонента Draggable для отключения блокирования dragNdrop интерактивных элементов*/
    disableInteractiveElementBlocking: false,
    /**
     * Функция отрисовки области по умолчанию (div), в которой происходит перетаскивание элементов
     * @param props - пропсы области, в которой происходит перетаскивание элементов
     * @param content - содержимое области (перетаскиваемые компоненты)
     * @param providedPlaceholder - placeholder для перетаскиваемого элемента (для отображения области, куда хотим поместить
     * перетаскиваемый элемент, но еще не завершили перетаскивание)
     */
    renderDroppableArea: (props, content, providedPlaceholder) => {
        return (
            <div {...props} >
                {content}
                {providedPlaceholder}
            </div>
        )
    }
}

export default function DragOnDrop(props) {
    let {
        dataList,
        renderItem,
        functionSetDataList,


        classes,
        onDragEnd,
        onDragStart,

        renderprops,

        droppableAreaProps,
        keyFieldName,
        disableInteractiveElementBlocking,

        renderDroppableArea,
    } = props;

    if (dataList) {
        if (keyFieldName === 'dragOnDropIndex') {
            keyFieldName = 'dragOnDropIndex'
            dataList = dataList.map((elem, index) => {
                return {
                    ...elem,
                    dragOnDropIndex: index.toString()
                }
            })
        } else {
            dataList = dataList.map((elem, index) => {
                return {
                    ...elem,
                    [keyFieldName]: elem[keyFieldName].toString()
                }
            })
        }
    }

    const reorderListAfterReplacingElement = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        if (startIndex < endIndex) {
            for (let i = endIndex; i > startIndex; i--) {
                let z = result[i][keyFieldName]
                result[i][keyFieldName] = result[i - 1][keyFieldName]
                result[i - 1][keyFieldName] = z
            }
        } else {
            for (let i = endIndex; i < startIndex; i++) {
                let z = result[i][keyFieldName]
                result[i][keyFieldName] = result[i + 1][keyFieldName]
                result[i + 1][keyFieldName] = z
            }
        }
        return result;
    };

    const onDragCompleted = useCallback((result) => {
        if (result.destination) {
            const items = reorderListAfterReplacingElement(dataList, result.source.index, result.destination.index);

            /* Передаем в callback предыдущие значения и новые */
            if (onDragEnd) {
                onDragEnd(dataList, items);
            }

            functionSetDataList(items)
        }
    }, [dataList]);


    const getDraggableId = useCallback((item) => {
        return keyFieldName !== '' ? item[keyFieldName] : item;
    }, [dataList, keyFieldName]);


    const renderDraggableItem = useCallback((item, index) => {
        const draggableId = getDraggableId(item);
        return (
            <Draggable
                disableInteractiveElementBlocking={disableInteractiveElementBlocking}
                key={draggableId}
                draggableId={draggableId}
                index={index}
            >
                {
                    (provided) => {
                        return (
                            <div {...provided.draggableProps}
                                 {...provided.dragHandleProps}
                                 ref={provided.innerRef}
                                 key={draggableId}
                            >
                                {renderItem(item, index)}
                            </div>)
                    }
                }
            </Draggable>
        )
    }, [renderprops, renderItem]);


    const renderItems = useCallback(() => {
        return (
            dataList && dataList.map((item, index) => {
                return (
                    renderDraggableItem(item, index)
                )
            })
        );
    }, [dataList, renderDraggableItem]);

    return (
        <DragDropContext onDragEnd={onDragCompleted} onDragStart={onDragStart}>
            <Droppable droppableId="droppable">
                {(provided) => (
                    renderDroppableArea(
                        {
                            className: classes.droppable,
                            ...provided.droppableProps,
                            ...droppableAreaProps,
                            ref: provided.innerRef,
                        },
                        renderItems(),
                        provided.placeholder
                    )
                )}
            </Droppable>
        </DragDropContext>
    )
}