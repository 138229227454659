import * as React from 'react';
import './style.css'
import TopMenu from "./TopMenu";
import LeftMenu from "./LeftMenu";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from '@material-ui/icons/Menu';
import Drawer from "@mui/material/Drawer";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Box from "../../../element/Box";
import Toolbar from "@mui/material/Toolbar";
import AppBar from "@mui/material/AppBar";
import CssBaseline from "@mui/material/CssBaseline";

const drawerWidth = 250;
const topMenuFirstLine = 64;
const topMenuSecondLine = 0;

export default function NavigationMenu(props) {
    const {window} = props;
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const container = window !== undefined ? () => window().document.body : undefined;

    return (
        <Box sx={{display: 'flex'}}>
            <CssBaseline/>
            <div className={"rose-min"}>
                <AppBar
                    position="fixed"
                    className={"top-menu_fon top-menu_fon__animate  "}
                    sx={{
                        // className:{xs: "top-menu_fon_min",xs800: "top-menu_fon_min",md:"top-menu_fon"},
                        marginTop: {xs: '0px'},
                        height: {xs: `${topMenuFirstLine}px`, md: `${topMenuFirstLine + topMenuSecondLine}px`},

                    }}
                >
                    <Toolbar>
                        <Box sx={{
                            display: {xs: 'block', md: 'none'},
                        }}>
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                edge="start"
                                onClick={handleDrawerToggle}
                                sx={{
                                    mr: 2,
                                    display: {xs: 'block', md: 'none'},
                                    height: {
                                        xs: `${topMenuFirstLine}px`,
                                        md: `${topMenuFirstLine + topMenuSecondLine}px`
                                    }
                                }}
                            >
                                <MenuIcon/>
                            </IconButton>
                        </Box>
                        <TopMenu leftMenu={drawerWidth}
                                 topMenuFirstLine={topMenuFirstLine}
                        />
                    </Toolbar>
                </AppBar>
            </div>
            <Box
                component="nav"
                sx={{
                    width: {md: drawerWidth},
                    flexShrink: {xs: 1, md: 0}
                }}
                aria-label="mailbox folders"
            >
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                <SwipeableDrawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    onOpen={handleDrawerToggle}
                    disableSwipeToOpen={false}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: {xs: 'block', md: 'none'},
                        '& .MuiDrawer-paper': {
                            boxSizing: 'border-box',
                            width: drawerWidth,
                        },

                    }}
                >
                    <LeftMenu></LeftMenu>
                </SwipeableDrawer>
                <Drawer
                    variant="permanent"
                    sx={{
                        display: {xs: 'none', md: 'block'},
                        '& .MuiDrawer-paper': {
                            boxSizing: 'border-box',
                            width: drawerWidth,
                            marginTop: {xs: `${topMenuFirstLine}px`, md: `${topMenuFirstLine + topMenuSecondLine}px`},
                            height: {
                                xs: `calc(100vh - ${topMenuFirstLine}px)`,
                                md: `calc(100vh - ${topMenuFirstLine + topMenuSecondLine}px)`
                            }
                        },
                    }}
                    open
                >
                    <LeftMenu></LeftMenu>
                </Drawer>
            </Box>
            <Box
                component="main"
                sx={{
                    marginTop: {xs: `${topMenuFirstLine}px`, md: `${topMenuFirstLine + topMenuSecondLine}px`},
                    flexGrow: 1,
                    width: {xs: `100%`, md: `calc(100% - ${drawerWidth}px)`},
                    height: {
                        xs: `calc(100vh - ${topMenuFirstLine}px)`,
                        md: `calc(100vh - ${topMenuFirstLine + topMenuSecondLine}px)`
                    }
                }}
            >
                {props.children}
            </Box>
        </Box>
    );
}
