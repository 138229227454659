import React from 'react';
import TextFieldMaterial from "@mui/material/TextField";

const TextField = (props) => {
    return (
        <TextFieldMaterial
            variant={"outlined"}
            sx={{
                maxWidth:"600px",
                margin:"0.5rem"
            }}
            multiline
            {...props}
            value={props.value ? props.value : ""}
        />
    );
};

export default TextField;