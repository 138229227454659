import React from 'react';
import IconButton from "@mui/material/IconButton";
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';

const ButtonDelete = (props) => {
    return (
        <IconButton
            background = "red"
            aria-label="delete"
            size="small"
            sx={{
                color:"rgba(255,140,140,0.95)",
            }}
            {...props}
        >
            <DeleteForeverOutlinedIcon fontSize="inherit"/>
        </IconButton>
    );
};

export default ButtonDelete;