import React from 'react';
import Box from "../../../../../element/Box";
import TypographyDate from "../../../../../elements/TypographyDate";

const Comment = (props) => {
    const {comment} = props
    return (
        <Box sx={{
            border:"1px solid black",
            borderRadius:"10px",
            padding:"0.25rem 0.5rem",
            width:"fit-content",
            display:"flex",
            flexDirection:"column"
        }}>
            <div>
                <span>
                    {comment.name}
                </span>
                {`: `}
                <span>
                {comment.text}
                </span>
            </div>
            <div style={{
                alignSelf:"flex-end"
            }}>
                <TypographyDate value={comment.createDate}/>
            </div>

        </Box>
    );
};

export default Comment;