import React, {useState} from 'react';
import Table from "../../../../elements/Table";

const ViewClient = (props) => {
    const {typeView, data} = props

    const getNameTransport = (transport) => {
        let resultList = [];
        if (transport.transportCatalog) {
            if (transport.transportCatalog.brand) {
                resultList.push(transport.transportCatalog.brand)
            }
            if (transport.transportCatalog.model) {
                resultList.push(transport.transportCatalog.model)
            }
        }
        return resultList.length > 0 ? resultList.join(" ") : null
    }

    const enrichData = (data) => {
        if (!data) return data
        let resultData = [...data]
        if ( data && resultData) {
            resultData.forEach(element => {
                if (element.transportList) {
                    element.transportListMarka = element.transportList.map(transport => {
                        return getNameTransport(transport)
                    })
                    element.transportListNumber = element.transportList.map(transport => {
                        return transport.number
                    })
                }
            })
        }

        return resultData
    }

    switch (typeView) {
        default:
            return (
                <div>
                    <Table
                        header={{
                            cells:[
                                {
                                    flexGrow:"2",
                                    value:"ФИО",
                                    name:"name"
                                },
                                {
                                    flexGrow:"2",
                                    value:"Марка авто",
                                    name:"transportListMarka",
                                    type:"column"
                                },
                                {
                                    flexGrow:"2",
                                    value:"Номер авто",
                                    name:"transportListNumber",
                                    type:"column"
                                },
                                {
                                    flexGrow:"2",
                                    value:"Баланс",
                                    name:"deposit"
                                },
                                // {
                                //     flexGrow:"2",
                                //     value:"Дата создания",
                                //     name:"createDate"
                                // },
                            ]
                        }}
                        data={enrichData(data)}
                        component={"Client"}
                        componentProps={{
                            isFullScreen:false
                        }}
                    />
                </div>
            );
    }

};

export default ViewClient;