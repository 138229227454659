import React from 'react';
import ListItemMaterial from '@mui/material/ListItem';

const ListItem = (props) => {
    return (
        <ListItemMaterial
            {...props}
        >
            {props.children}
        </ListItemMaterial>
    );
};

export default ListItem;