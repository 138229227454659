import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import Box from "../../../../../element/Box";
import Typography from "../../../../../element/Typography";
import TypographyDate from "../../../../../elements/TypographyDate";
import Divider from "@mui/material/Divider";
import ListItem from "../../../../../element/List/ListItem";
import BoxEasyOpen from "../../../../../element/BoxEasyOpen";
import List from "../../../../../element/List";
import Button from "../../../../../element/Button";
import {getMoneyString} from "../../../../../utils/commonUtils";
import Table from "../../../../../elements/Table";

const ProductCountInfo = props => {
    const {productCountInfo} = props

    const [productCountInfoMod, setProductCountInfoMod] = useState([])
    const [size, setSize] = useState(10)

    useEffect(() => {
        setProductCountInfoMod({
                ...productCountInfo,
                priceCountDateList: productCountInfo.priceCountDateList ? productCountInfo.priceCountDateList.reverse() : []
            }
        )
    }, productCountInfo)

    const [open, setOpen] = useState(false)

    return (
        <Box>
            <Box sx={{
                display: "flex",
                gap: "0.5rem",
                padding: "0.5rem"
            }}
                 className={"pointer"} onClick={() => setOpen(prevState => !prevState)}
            >
                {productCountInfoMod.type ? <Typography sx={{
                    flexGrow: "0.8",
                    width: "100px"
                }}>{`${productCountInfoMod.type}`}</Typography> : null}
                <Typography sx={{
                    flexGrow: "1.5",
                    width: "100px"
                }}>
                    {`партия из ${productCountInfoMod.count} шт. `}
                </Typography>
                <Typography sx={{
                    flexGrow: "2",
                    width: "100px"
                }}>{`Остаток в партии: ${productCountInfoMod.endCount} шт. `}</Typography>
                <Typography sx={{
                    flexGrow: "2",
                    width: "100px"
                }}>{`Закупочная цена: ${getMoneyString(productCountInfoMod.priceCurrency)}`}</Typography>
                <TypographyDate sx={{
                    flexGrow: "1",
                    width: "100px"
                }} value={productCountInfoMod.createDate}/>
            </Box>
            {productCountInfoMod &&
            productCountInfoMod.priceCountDateList ?
                <BoxEasyOpen open={open}>
                    <Divider style={{
                        borderColor: "white"
                    }}/>
                    <Table
                        header={{
                            cells: [
                                {
                                    flexGrow: "2",
                                    value: "Событие",
                                    name: "type"
                                },
                                {
                                    flexGrow: "2",
                                    value: "Количество",
                                    name: "count",
                                },
                                {
                                    flexGrow: "2",
                                    value: "Цена",
                                    name: "priceCurrency",
                                },
                                {
                                    flexGrow: "2",
                                    value: "Дата",
                                    name: "createDate",
                                    type: "date",
                                    format: "DD.MM.yyyy[\n]HH:mm:ss"
                                },
                            ]
                        }}
                        data={productCountInfoMod.priceCountDateList.slice(0, size)}
                        componentProps={{
                            isFullScreen: false
                        }}
                    />
                    {size < productCountInfoMod.priceCountDateList.length ?
                        <Button onClick={() => setSize(prevState => prevState + 10)}>Еще</Button> : null}
                </BoxEasyOpen> : null
            }
            <Divider style={{
                borderColor: "white"
            }}/>
        </Box>
    );
};

ProductCountInfo.propTypes = {};

export default ProductCountInfo;