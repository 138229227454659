import React from 'react';
import TextFieldMaterial from '@mui/material/TextField';

const TextField = (props) => {
    const {label, ...other} = props
    return (
        <TextFieldMaterial
            variant="outlined"
            multiline
            label={label ? label : " "}
            {...other}
        />
    );
};

export default TextField;