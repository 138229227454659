import React, {useEffect, useLayoutEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {
    getClientOrderActionByClientOrderId,
    getClientOrderById, searchClientOrderTransaction,
    sendClientOrderTransaction
} from "../../../../Rest/RestFunction";
import * as NotificationAction from "../../../../store/notifications/actions";
import {useDispatch, useSelector} from "react-redux";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import Box from "../../../../element/Box";
import TextFieldNumber from "../../../../element/TextField/TextFieldNumber";
import Button from "../../../../element/Button";
import Modal from "../../../../element/Modal";
import Checkbox from "@mui/material/Checkbox";
import {getFilterObjectIdIs} from "../../../../helpers/FilterHelper";
import useSignal from "../../../../hooks/useSignal";
import ListTransaction from "../../../ListTransaction";
import Typography from "../../../../element/Typography";

const ClientOrderPay = props => {
    const {clientOrderId} = props
    const dispatch = useDispatch()
    const user = useSelector((state) => state.auth.user);
    const [isSend, setIsSend] = useState(false)
    const [payLC, setPayLC] = useState(false)
    const [clientOrder, setClientOrder] = useState()
    const [actionsOrder, setActionsOrder] = useState()
    const [transactionsOrder, setTransactionsOrder] = useState()
    const [transaction, setTransaction] = useState({
        clientOrderId: "",
        typeFrom: "realClient",
        typeTo: "cash",
        money: 0,
    })
    const [backTransaction, setBackTransaction] = useState({
        clientOrderId: "",
        typeFrom: "cash",
        typeTo: "realClient",
        money: 0,
    })

    const [calculation, setCalculation] = useState({})

    useEffect(() => {
        setTransaction((prev) => ({
            ...prev,
            clientOrderId: clientOrderId
        }))
        setBackTransaction((prev) => ({
            ...prev,
            clientOrderId: clientOrderId
        }))
        load(clientOrderId)
    }, [clientOrderId])

    const load = async (clientOrderId) => {
        if (clientOrderId) {
            let response = await getClientOrderById({
                tokenAuth: user.tokenAuth,
                id: clientOrderId
            })
            if (response) {
                if (response.clientOrder) {
                    setClientOrder(response.clientOrder)
                } else {
                    if (response.message) {
                        dispatch(NotificationAction.showNotification("error", response.message))
                    }
                }
            }
            let responseActions = await getClientOrderActionByClientOrderId({
                tokenAuth: user.tokenAuth,
                clientOrderId: clientOrderId
            })
            if (responseActions) {
                if (responseActions.clientOrderActionList) {
                    setActionsOrder(responseActions.clientOrderActionList)
                } else {
                    if (responseActions.message) {
                        dispatch(NotificationAction.showNotification("error", responseActions.message))
                    }
                }
            }

            let responseTransactions = await searchClientOrderTransaction({
                tokenAuth: user.tokenAuth,
                searchParams: {
                    page: 0,
                    size: 100000,
                    filters: [
                        getFilterObjectIdIs("clientOrderId", clientOrderId)
                    ]
                }
            })
            if (responseTransactions) {
                if (responseTransactions.page.content) {
                    setTransactionsOrder(responseTransactions.page.content)
                } else {
                    if (responseTransactions.message) {
                        dispatch(NotificationAction.showNotification("error", responseTransactions.message))
                    }
                }
            }
        }
    }

    const reload = async (clientOrderId) => {
        console.log("Вызвался reload")
        if (clientOrderId) {
            let responseActions = await getClientOrderActionByClientOrderId({
                tokenAuth: user.tokenAuth,
                clientOrderId: clientOrderId
            })
            if (responseActions) {
                if (responseActions.clientOrderActionList) {
                    setActionsOrder(responseActions.clientOrderActionList)
                } else {
                    if (responseActions.message) {
                        dispatch(NotificationAction.showNotification("error", responseActions.message))
                    }
                }
            }

            let responseTransactions = await searchClientOrderTransaction({
                tokenAuth: user.tokenAuth,
                searchParams: {
                    page: 0,
                    size: 100000,
                    filters: [
                        getFilterObjectIdIs("clientOrderId", clientOrderId)
                    ]
                }
            })
            if (responseTransactions) {
                if (responseTransactions.page.content) {
                    setTransactionsOrder(responseTransactions.page.content)
                } else {
                    if (responseTransactions.message) {
                        dispatch(NotificationAction.showNotification("error", responseTransactions.message))
                    }
                }
            }
        }
    }

    useSignal(`clientOrder.shop.${clientOrderId}`, () => reload(clientOrderId))

    useLayoutEffect(() => {
        if (actionsOrder && actionsOrder.length > 0 && transactionsOrder) {
            setCalculation((prev) => {
                let result = {...prev}
                result.total = actionsOrder.reduce((total, current) => {
                    total += current.finalPrice * current.count
                    return total
                }, 0)
                let totalPaid = transactionsOrder.reduce((total, current) => {
                    if (current.typeFrom === "realClient") {
                        total += current.money
                    } else {
                        if (current.typeTo === "realClient") {
                            total -= current.money
                        }
                    }
                    return total
                }, 0)
                result.totalPaid = totalPaid
                result.totalSubPaid = result.total - totalPaid
                return result
            })
        } else {
            setCalculation({})
        }
    }, [actionsOrder, transactionsOrder])

    const changeTransaction = (name, value) => {
        setTransaction((prev) => (
            {
                ...prev,
                [name]: value
            }
        ))
    }

    const changeBackTransaction = (name, value) => {
        setBackTransaction((prev) => (
            {
                ...prev,
                [name]: value
            }
        ))
    }

    const sendTransaction = async () => {
        setIsSend(true)

        if (payLC === false) {
            let transactionResult = {...transaction}
            if (transactionResult.money > calculation.totalSubPaid) {
                transactionResult.money = calculation.totalSubPaid
            }
            let response = await sendClientOrderTransaction({
                tokenAuth: user.tokenAuth,
                transaction: transactionResult
            })
            await reload(clientOrderId)
            setTransaction((prev) => ({
                ...prev,
                money: 0
            }))
            setIsSend(false)
        } else {
            let transactionResult = {...transaction}
            let response = await sendClientOrderTransaction({
                tokenAuth: user.tokenAuth,
                transaction: transactionResult
            })
            await reload(clientOrderId)
            setTransaction((prev) => ({
                ...prev,
                money: 0
            }))
            setIsSend(false)
        }
    }

    const sendBackTransaction = async () => {
        setIsSend(true)

        let transactionResult = {...backTransaction}
        let response = await sendClientOrderTransaction({
            tokenAuth: user.tokenAuth,
            transaction: transactionResult
        })
        await reload(clientOrderId)
        setBackTransaction((prev) => ({
            ...prev,
            money: 0
        }))
        setIsSend(false)
    }

    return (
        <Box>
            {calculation.totalSubPaid && calculation.totalSubPaid > 0 ?
                <Box sx={{
                    display: "flex",
                    gap: "0.5rem",
                    alignItems: "center"
                }}>
                    <Box sx={{
                        display: "flex",
                        flexDirection: "column"
                    }}>
                        <FormControlLabel
                            sx={{
                                marginBottom: "0"
                            }}
                            control={<Radio
                                checked={transaction.typeTo === 'cash'}
                                onChange={(event) => changeTransaction("typeTo", event.target.value)}
                                value="cash"
                                name="radio-buttons"
                                inputProps={{'aria-label': 'cash'}}
                            />}
                            label="Наличные"/>
                        <FormControlLabel
                            sx={{
                                marginBottom: "0"
                            }}
                            control={<Radio
                                checked={transaction.typeTo === 'non-cash'}
                                onChange={(event) => changeTransaction("typeTo", event.target.value)}
                                value="non-cash"
                                name="radio-buttons"
                                inputProps={{'aria-label': 'non-cash'}}
                            />}
                            label="Безналичный"/>
                    </Box>
                    <Box sx={{
                        width: "300px"
                    }}>
                        {calculation.totalPaid ? <div>
                            {`Было оплачено: ${calculation.totalPaid}`}
                        </div> : <div> Оплат не производилось</div>}
                        {calculation.totalSubPaid ?
                            calculation.totalSubPaid > 0 ?
                                <div>
                                    {`К оплате ${calculation.totalSubPaid} рублей`}
                                </div> :
                                <div>
                                    {`Выдать сдачу ${Math.abs(calculation.totalSubPaid)} рублей`}
                                </div>
                            : null}
                        {transaction.money !== 0 && calculation.totalSubPaid - transaction.money > 0 ?
                            <div>
                                {`Требуется к доплате ${calculation.totalSubPaid - transaction.money} рублей`}
                            </div> : null}
                        {transaction.money !== 0 && calculation.totalSubPaid - transaction.money < 0 ?
                            <div>
                                {`Сдача ${transaction.money - calculation.totalSubPaid} рублей`}
                            </div> : null}
                    </Box>
                    {calculation.totalSubPaid ?
                        calculation.totalSubPaid >= 0 ?
                            <TextFieldNumber
                                label={"Оплата"}
                                value={transaction.money}
                                isRenderButtonSub={(value) => false}
                                isRenderButtonPlus={(value) => false}
                                onChange={(newValue) => changeTransaction("money", newValue)}
                            />
                            :
                            <TextFieldNumber
                                label={"Сдача"}
                                value={transaction.money}
                                isRenderButtonSub={(value) => false}
                                isRenderButtonPlus={(value) => false}
                                onChange={(newValue) => changeTransaction("money", newValue)}
                            />
                        :
                        null
                    }
                    <Box>
                        <Button
                            disabled={!(transaction.money > 0 && !isSend)}
                            onClick={sendTransaction}
                        >
                            Оплатить
                        </Button>
                    </Box>
                    {transaction.money > 0 && calculation.totalSubPaid - transaction.money < 0 ?
                        <Box>
                            <FormControlLabel
                                control={<Checkbox
                                    checked={payLC}
                                    onChange={(event => setPayLC(event.target.checked))}
                                />}
                                label="Зачислить сдачу на личный счет"
                            />
                        </Box> : null
                    }
                </Box>
                : <Box sx={{
                    display: "flex",
                    gap: "0.5rem",
                    alignItems: "center"
                }}>
                    <Box sx={{
                        display: "flex",
                        flexDirection: "column"
                    }}>
                        <FormControlLabel
                            sx={{
                                marginBottom: "0"
                            }}
                            key={"backTransaction.from.cash"}
                            control={<Radio
                                checked={backTransaction.typeFrom === 'cash'}
                                onChange={(event) => changeBackTransaction("typeFrom", event.target.value)}
                                value="cash"
                                name="radio-buttons"
                                inputProps={{'aria-label': 'cash'}}
                            />}
                            label="Наличные"/>
                        <FormControlLabel
                            sx={{
                                marginBottom: "0"
                            }}
                            key={"backTransaction.from.not-cash"}
                            control={<Radio
                                checked={backTransaction.typeFrom === 'non-cash'}
                                onChange={(event) => changeBackTransaction("typeFrom", event.target.value)}
                                value="non-cash"
                                name="radio-buttons"
                                inputProps={{'aria-label': 'non-cash'}}
                            />}
                            label="Безналичный"/>
                    </Box>
                    <Box sx={{
                        width: "300px"
                    }}>
                        {calculation.totalPaid ? <div>
                            {`Было оплачено: ${calculation.totalPaid}`}
                        </div> : <div> Оплат не производилось</div>}
                        <div>
                            {`Выдать сдачу ${Math.abs(calculation.totalPaid - calculation.total)} рублей`}
                        </div>
                        {backTransaction.money !== 0 ? (calculation.totalPaid - calculation.total) - backTransaction.money > 0 ?
                            <div>
                                {`Сдача ${(calculation.totalPaid - calculation.total) - backTransaction.money} рублей`}
                            </div> : <div>
                                {`Переплата клиенту ${Math.abs((calculation.totalPaid - calculation.total) - backTransaction.money)} рублей`}
                            </div> : null}
                    </Box>
                    <TextFieldNumber
                        label={"Сдача"}
                        value={backTransaction.money}
                        isRenderButtonSub={(value) => false}
                        isRenderButtonPlus={(value) => false}
                        onChange={(newValue) => changeBackTransaction("money", newValue)}
                    />
                    <Box>
                        <Button
                            disabled={!(backTransaction.money > 0 && !isSend)}
                            onClick={sendBackTransaction}
                        >
                            Выдать сдачу
                        </Button>
                    </Box>
                    {backTransaction.money > 0 && calculation.total - backTransaction.money < 0 ?
                        <Box>
                            <FormControlLabel
                                control={<Checkbox
                                    checked={payLC}
                                    onChange={(event => setPayLC(event.target.checked))}
                                />}
                                label="Зачислить сдачу на личный счет"
                            />
                        </Box> : null
                    }
                </Box>
            }
            {transactionsOrder && transactionsOrder.length > 0 ?
            <Box sx={{
                display:"flex",
                justifyContent:"flex-end"
            }}>
                <Modal
                    label={"История транзакций"}
                    //startComponent={<Typography>История</Typography>}
                >
                    <ListTransaction list={transactionsOrder}/>
                </Modal>
            </Box> : null}
        </Box>
    )
};

ClientOrderPay.propTypes = {
    clientOrderId: PropTypes.string
};

export default ClientOrderPay;