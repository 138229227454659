import React from 'react';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import FilterListIcon from '@mui/icons-material/FilterList';
import VerticalSplitIcon from '@mui/icons-material/VerticalSplit';
import LocalCarWashIcon from '@mui/icons-material/LocalCarWash';
import ConstructionIcon from '@mui/icons-material/Construction';
import Typography from "../../element/Typography";

const FactoryIcon = (props) => {
    const {name, style, ...other} = props
    const getIcon = () => {
        let lowerCase = name.toLowerCase()
        switch (lowerCase) {
            case 'автомойка':
                return <LocalCarWashIcon
                    style={style}
                    color={"primary"}
                    fontSize={"small"}
                    {...other}
                />
            case 'автосервис':
                return <ConstructionIcon
                    style={style}
                    color={"primary"}
                    fontSize={"small"}
                    {...other}
                />
            default:
                return <Typography>
                    {name}
                </Typography>
        }
    }
    return getIcon()
};

export default FactoryIcon;