import React from 'react';
import IconButton from "@mui/material/IconButton";
import LaunchIcon from '@mui/icons-material/Launch';

const ButtonLaunch = (props) => {
    return (
        <IconButton
            aria-label="delete"
            size="small"
            {...props}
        >
            <LaunchIcon fontSize="inherit"/>
        </IconButton>
    );
};

export default ButtonLaunch;