import React, {useEffect, useState} from 'react';
import {
    getClientById,
    getClientOrderActionByClientOrderId,
    getClientOrderById, getTransportById, searchTransportCatalog,
    updateClientOrder, updateClientOrderAction, updateClientOrderActionMass, updateTransport
} from "../../../../Rest/RestFunction";
import {useDispatch, useSelector} from "react-redux";
import * as NotificationAction from "../../../../store/notifications/actions";
import ClientRedactor from "../../Clients/ClientRedactor";
import Modal from "../../../../element/Modal";
import Box from "../../../../element/Box";
import SearchTextField from "../../../../elements/SearchTextField";
import Divider from "@mui/material/Divider";
import Button from "../../../../element/Button";
import ButtonClose from "../../../../element/Button/ButtonClose";
import TransportChip from "../../Transport/TransportChip";
import {getTextTransport} from "../../../../helpers/TransportHelper";
import Comments from "../../Comments";
import ShoppingCart from "../../ShoppingCart";
import _ from 'lodash'
import ClientOrderActionByOrder from "../ClientOrderActionByOrder";
import ListItem from "../../../../element/List/ListItem";
import Autocomplete from "../../../../element/Autocomplete";
import useDebounceState from "../../../../hooks/useDebounceState";
import TextField from "../../../../element/TextField";
import useSignal from "../../../../hooks/useSignal";
import ClientOrderPay from "../ClientOrderPay";

const ClientOrderRedactor = (props) => {
    const {id} = props
    const user = useSelector((state) => state.auth.user);
    const [clientOrder, setClientOrder, debounceClientOrder] = useDebounceState({}, 500)
    const [clientOrderActionList, setClientOrderActionList, debouncedClientOrderActionList] = useDebounceState([], 1000)

    const [client, setClient] = useState()
    const [transport, setTransport] = useState()
    const dispatch = useDispatch()

    const [searchNumber, setSearchNumber] = useState("")
    const [foundNumber, setFoundNumber] = useState(false)
    const [transportAdd, setTransportAdd] = useState()
    const [markaSearch, setMarkaSearch, debounceMarkaSearch] = useDebounceState("")
    const [markaSearchResult, setMarkaSearchResult] = useState()

    const [changeClientOrderSignal, sendChangeClientOrderSignal] = useSignal(`clientOrder.shop.${id}`)

    useEffect(() => {
        if (debounceClientOrder.id) {
            update()
        }
    }, [debounceClientOrder])


    useEffect(() => {
        if (debounceMarkaSearch !== 0) {
            searchMarka(debounceMarkaSearch)
        }
    }, [debounceMarkaSearch])

    useEffect(() => {
        updateClientOrderActionMass({
            tokenAuth: user.tokenAuth,
            clientOrderActionList: debouncedClientOrderActionList
        }).then(result => {
            setClientOrderActionList(result.clientOrderActionList)
            sendChangeClientOrderSignal()
        })
    }, [debouncedClientOrderActionList])

    const onChangeAction = (action) => {
        setClientOrderActionList((prevClientActionList) => {
            let index = prevClientActionList.findIndex(elem => elem.id===action.id)
            prevClientActionList.splice(index, 1, action)
            return [...prevClientActionList]
        })
    }


    const searchMarka = async (search) => {
        let result = await searchTransportCatalog({
            tokenAuth: user.tokenAuth,
            searchParams: {
                search: search,
                page: 0,
                size: 100
            }
        })

        setMarkaSearchResult(result.page.content)
    }

    const reloadClientOrder = () => {
        loadClientOrder(clientOrder.id)
    }

    useSignal(clientOrder.id, reloadClientOrder)

    useEffect(() => {
        loadClientOrder(id)
        loadClientOrderActionList(id)
    }, [])

    useEffect(() => {
        if (client) {
            if (transport) {
                if (transport.clientId !== client.id) {
                    setTransport(null)
                }
            }
        }
    }, [client])

    useEffect(() => {
        if (client) {
            if (transport) {
                if (transport.clientId !== client.id) {
                    setClient(null)
                }
            }
        }
    }, [transport])

    const loadClientOrder = async (id) => {
        if (id) {
            let response = await getClientOrderById({
                tokenAuth: user.tokenAuth,
                id: id
            })
            if (response) {
                if (response.clientOrder) {
                    setClientOrder(response.clientOrder)
                    loadTransport(response.clientOrder.transportId)
                    loadClient(response.clientOrder.clientId)
                } else {
                    if (response.message) {
                        dispatch(NotificationAction.showNotification("error", response.message))
                    }
                }
            }
        }
    }

    const loadTransport = async (id) => {
        if (id) {
            let response = await getTransportById({
                tokenAuth: user.tokenAuth,
                id: id
            })
            if (response) {
                setTransport(response.transport)
            }
        }
    }

    const loadClient = async (id) => {
        if (id) {
            let response = await getClientById({
                tokenAuth: user.tokenAuth,
                id: id
            })
            if (response) {
                setClient(response.client)
            }
        }
    }

    const loadClientOrderActionList = async (id) => {
        if (id) {
            let response = await getClientOrderActionByClientOrderId({
                tokenAuth: user.tokenAuth,
                clientOrderId: id
            })
            if (response) {
                if (response.clientOrderActionList) {
                    setClientOrderActionList(response.clientOrderActionList)
                } else {
                    if (response.message) {
                        dispatch(NotificationAction.showNotification("error", response.message))
                    }
                }
            }
        }
    }

    const update = async () => {
        let set = new Set(clientOrderActionList.filter(elem => elem.count > 0).map(action => {
            return action.categoryId
        }))
        let response = await updateClientOrder({
            tokenAuth: user.tokenAuth,
            clientOrder: {...clientOrder, categoryIdList: [...set]}
        })
        setClientOrder(response.clientOrder)
        return response.clientOrder
    }

    const onChangeClient = (client) => {
        if (client) {
            setClient(client)
            setClientOrder({
                ...clientOrder,
                clientId: client.id
            })
        } else {
            setClient(null)
            setClientOrder({
                ...clientOrder,
                clientId: null
            })
        }
    }

    const saveTransport = async () => {
        let response = await updateTransport({
            tokenAuth: user.tokenAuth,
            transport: {
                ...transport,
            }
        })
        setTransport(response.transport)
        setClientOrder({
            ...clientOrder,
            transportId: response.transport.id
        })
    }

    const changeTransport = (nameParam, value) => {
        setTransport({
            ...transport,
            [nameParam]: value
        })
    }

    const onChangeSearchNumber = (newValue) => {
        setSearchNumber(newValue)
        if (transport && !transport.id) {
            setTransport({
                ...transport,
                number: newValue
            })
        }
    }

    const onChangeSearchNumberOptions = (response) => {
        if (response && response.page) {
            setFoundNumber(response.page.content.some(transport => {
                return transport.number === searchNumber
            }))
        }
    }

    const changeMarkaModelTransport = (value) => {
        setTransport({
            ...transport,
            transportCatalog: value,
            number: searchNumber,
            clientId: client ? client.id : null
        })
    }

    const onChangeTransport = (transport) => {
        if (transport) {
            setTransport(transport)
            setClientOrder({
                ...clientOrder,
                clientId: transport.clientId,
                transportId: transport.id
            })
            loadClient(transport.clientId)
        } else {
            setTransport(null)
            setClientOrder({
                ...clientOrder,
                transportId: null
            })
        }
    }

    const onChangeShopData = async (shopData, cell, newCount) => {
        let result = [...clientOrderActionList]
        let clientOrderObject = clientOrder
        if (!clientOrderObject.id) {
            clientOrderObject = await update()
        }


        let clientOrderAction
        if (shopData.id) {
            clientOrderAction = result.find((clientOrderAction => clientOrderAction.id === shopData.id))
            clientOrderAction.count = newCount
        } else {
            clientOrderAction = {...shopData}
            // clientOrderAction.productId = _.get(cell, "row.id")
            clientOrderAction.count = newCount
            clientOrderAction.creatorEmployeeId = user.id
            clientOrderAction.clientOrderId = clientOrderObject.id
            clientOrderAction.name = _.get(cell, "row.name", "Название отсутствует")
            clientOrderAction.description = _.get(cell, "row.description", "")
            result.push(clientOrderAction)
        }

        let responseSaveClientOrderAction = await updateClientOrderAction({
            tokenAuth: user.tokenAuth,
            clientOrderAction: clientOrderAction
        })
        console.log("send")
        sendChangeClientOrderSignal()
        loadClientOrderActionList(clientOrderObject.id)
    }

    return (
        <Box sx={{
            margin: "0.5rem",
            display: "flex",
            flexDirection: "column",
            gap: "0.5rem"
        }}>
            <Box sx={{
                display: "flex",
                gap: "0.5rem",
            }}>
                <Box sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "0.5rem"
                }}>
                    <SearchTextField
                        label={"Поиск по номеру"}
                        urlGetOptions={"transport/search"}
                        renderOption={(props, option, state) => <div
                            {...props}
                            style={{
                                display: "block"
                            }}
                            key={option.id}
                        >
                            <div><TransportChip transport={option}/></div>
                            <Divider/>
                        </div>}
                        onChange={onChangeTransport}
                        onChangeValueTextfield={onChangeSearchNumber}
                        getOptionLabel={(option) => getTextTransport(option)}
                        options={client ? client.transportList : null}
                        onChangeOptions={onChangeSearchNumberOptions}
                    />
                    {searchNumber && (!transport || !transport.id) ?
                        <Autocomplete
                            label="Марка/Модель"
                            isOptionEqualToValue={(option, value) => _.isEqual(option.id, value.id)}
                            value={transport ? transport.transportCatalog : null}
                            onChangeTextField={(e) => {
                                if (e) {
                                    setMarkaSearch(e.target.value)
                                }
                            }}
                            options={markaSearchResult}
                            getOptionLabel={(option) => `${option.brand} ${option.model}`}
                            renderOption={(props, option) => (
                                <Box component="li" sx={{'& > img': {mr: 2, flexShrink: 0}}} {...props}>
                                    {`${option.brand} ${option.model}`}
                                </Box>
                            )}
                            onChange={(event, newValue) =>
                                changeMarkaModelTransport(newValue)}
                        /> : null}
                </Box>
                <SearchTextField
                    label={"Поиск по клиенту"}
                    urlGetOptions={"client/search"}
                    renderOption={(props, option, state) => <div
                        {...props}
                        style={{
                            display: "block"
                        }}
                        key={option.id}
                    >
                        <div>
                            {option.name}
                        </div>
                        <div>
                            {option.transportList ? option.transportList.map(transport => {
                                return <div><TransportChip transport={transport}/></div>
                            }) : null}
                        </div>
                        <Divider/>
                    </div>}
                    onChange={onChangeClient}
                    value={client}
                />
            </Box>
            <Box sx={{
                display: "flex",
                gap: "1rem"
            }}>
                <Box sx={{
                    display: "flex",
                    alignItems: "center"
                }}>
                    Клиент: {client ? client.name : "Не выбран"}
                </Box>
                {client ? <ButtonClose onClick={() => onChangeClient(null)}/> : null}
                {!client ?
                    <Modal
                        label={"Добавить клиента"}
                        isActions={false}
                    >
                        <ClientRedactor indexTab={0}/>
                    </Modal> : null}
                {client ?
                    <Modal
                        label={"Редактирование клиента"}
                        isActions={false}
                    >
                        <ClientRedactor
                            id={client.id}
                            indexTab={0}
                        />
                    </Modal> : null}
            </Box>

            <Box sx={{
                display: "flex",
                gap: "1rem"
            }}>
                <Box sx={{
                    display: "flex",
                    alignItems: "center"
                }}>
                    Транспорт: {transport ? `${_.get(transport, "transportCatalog.brand")} ${_.get(transport, "transportCatalog.model")} ${transport.number}` : "Не выбран"}
                </Box>
                {transport ? <ButtonClose onClick={() => onChangeTransport(null)}/> : null}
                {transport && !transport.id && <Button onClick={saveTransport}>Транспорт заполнен, сохранить</Button>}
                {!transport ?
                    <Modal
                        label={"Добавить транспорт"}
                        isActions={false}
                    >
                        <ClientRedactor
                            id={client ? client.id : null}
                            indexTab={1}
                        />
                    </Modal> : null}
            </Box>

            <Box sx={{
                display: "flex",
                flexDirection: "column"
            }}>
                <ShoppingCart
                    sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        gap: "0.5rem",
                        margin: "0.5rem",
                    }}
                    renderItem={(category) => {
                        return (<Button>{category.name}</Button>)
                    }}
                    transportId={_.get(transport, "transportCatalogId")}
                    onChangeShopData={onChangeShopData}
                    clientOrderActionList={clientOrderActionList}
                />
                <ClientOrderPay clientOrderId={clientOrder.id}/>
                <ClientOrderActionByOrder
                    sx={{
                        flexGrow: "1",
                    }}
                    actions={clientOrderActionList}
                    onChangeAction={onChangeAction}
                />
            </Box>
            {clientOrder && clientOrder.id ?
                <Box>
                    <Comments comments={clientOrder.comments} id={clientOrder && clientOrder.id} typeEntity={"clientOrder"}/>
                </Box> : null
            }
            {clientOrder && !clientOrder.id ?
                <Box sx={{
                    display: "flex",
                }}>
                    <Button
                        onClick={update}
                        sx={{
                            flexGrow: "1"
                        }}
                    >
                        Сохранить
                    </Button>
                </Box> : null}
        </Box>
    );
};

export default ClientOrderRedactor;