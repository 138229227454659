import React, {useEffect, useState} from 'react';
import {getClientById, getClientOrderById} from "../../../../Rest/RestFunction";
import {useSelector} from "react-redux";
import Typography from "../../../../element/Typography";
import ButtonLaunch from "../../../../element/Button/ButtonLaunch";
import ButtonEdit from "../../../../element/Button/ButtonEdit";
import Box from "../../../../element/Box";
import {useNavigate} from "react-router-dom";
import Comments from "../../Comments";
import useSignal from "../../../../hooks/useSignal";

const ClientOrder = (props) => {
    const {id, isLoad, isFullScreen = true} = props
    const [load, setLoad] = useState(false)
    const [clientOrder, setClientOrder] = useState({})
    const [client, setClient] = useState()
    const user = useSelector((state) => state.auth.user);
    const navigate = useNavigate()

    useEffect(()=>{
        setLoad(isLoad)
    }, [isLoad])

    useEffect(() => {
        if (load === true) {
            setLoad(false)
            getClientOrder()
        }
    }, [load])

    const getClientOrder = async () => {
        let response = await getClientOrderById({
            id: id,
            tokenAuth: user.tokenAuth
        })
        setClientOrder(response.clientOrder)
        getClient(response.clientOrder)
    }

    useSignal(id, getClientOrder)

    const getClient = async (clientOrder) => {
        if (clientOrder.clientId) {
            let response = await getClientById({
                id: clientOrder.clientId,
                tokenAuth: user.tokenAuth
            })
            setClient(response.client)
        }
    }

    const goToRedactor = () => {
        navigate(`/privateOffice/clientOrder/redactor/${clientOrder.id}`)
    }

    const goToFullScreen = () => {
        navigate(`/privateOffice/clientOrder/${clientOrder.id}`)
    }

    return (
        <div>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between"
                }}
            >
                <div>
                    <Typography variant={"h5"}>

                    </Typography>
                </div>
                <div>
                    {!isFullScreen ? <ButtonLaunch
                        color={"primary"}
                        size={"medium"}
                        onClick={goToFullScreen}
                    /> : null}
                    <ButtonEdit
                        color={"primary"}
                        size={"medium"}
                        onClick={goToRedactor}
                    />
                </div>
            </Box>
            {client ?
                <Box>
                    Клиент
                    <Box>
                        {client.name}
                    </Box>
                    <Box>
                        <Comments id={clientOrder.id} typeEntity={"clientOrder"} comments={clientOrder.comments}/>
                    </Box>
                </Box> : null
            }
        </div>
    );
};

export default ClientOrder;