import Chip from "@mui/material/Chip";
import React from "react";
import SelectAutocomplete from "../SelectAutocomplete";
import {searchProduct} from "../../Rest/RestFunction";


export default function AutocompleteSearchProduct(props) {
    const {className, style, ...other} = props
    const [dataOptions, setDataOptions] = React.useState([]);
    const [inputValue, setInputValue] = React.useState('');

    const changeInputValue = async ({value}) => {
        setInputValue(value)
        let dataSearchProductPage = await searchProduct({page:0, size:100, search:value})
        setDataOptions(dataSearchProductPage.content)
    }

    return <SelectAutocomplete
        className={className}
        multiple
        style={style}
        label={"Товары"}
        options={dataOptions}
        onInputChange={changeInputValue}
        getOptionLabel={(option) => (typeof option === 'string' ? option : `${option.article} - ${option.name}`)}
        renderTags={(value, getTagProps) =>
            value.map((option, index) => (
                <Chip style={{color: "white"}} variant="outlined"
                      label={option.name} {...getTagProps({index})} />
            ))
        }
        filterOptions = {(options, state) => options}
        {...other}
    />
}