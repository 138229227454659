import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {getAllAccess, getAllAccessName} from "../../../../Rest/RestFunction";
import {useSelector} from "react-redux";
import Box from "../../../../element/Box";
import Switch from "../../../../element/Switch";

const AccessRedactor = props => {
    const {accessEmployee = [], onChange} = props

    const user = useSelector((state) => state.auth.user)
    const [allAccessNames, setAllAccessNames] = useState([])

    useEffect(() => {
        findAllAccess()
    }, [])

    const findAllAccess = async () => {
        let responseName = await getAllAccessName({
            tokenAuth: user.tokenAuth
        })
        console.log(responseName)
        if (responseName) {
            let  namesAccess = [];
            for (let el in responseName) {
                namesAccess.push({value: el, name:responseName[el]})
            }
            setAllAccessNames(namesAccess)
        }
    }

    const onChangeSwitch = (accessFind) => {
        let index = accessEmployee.findIndex((accessElem) => accessElem === accessFind)
        if (index > -1) {
            let result = [...accessEmployee]
            result.splice(index, 1)
            onChange(result)
        } else {
            onChange([...accessEmployee, accessFind])
        }
    }

    return (
        <Box>
            {allAccessNames && allAccessNames.map((access) => {
                return <Switch label={access.name}
                               value={accessEmployee.findIndex((accessElem) => accessElem === access.value) > -1}
                               onChange={(e) => onChangeSwitch(access.value)}/>
            })}
        </Box>
    );
};

AccessRedactor.propTypes = {};

export default AccessRedactor;