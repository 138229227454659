import React from 'react';
import Box from "@mui/material/Box";
import HeaderCell from "./HeaderCell";
import "./style.css"

const Header = (props) => {
    const {header} = props
    return (
        <Box sx={{
            display:"flex",
            padding:"16px 40px 16px 16px",
            gap:"10px"
        }}>
            {header && header.cells && header.cells.map(cell => {
                return <HeaderCell
                    {...cell}
                />
            })}
        </Box>
    );
};

export default Header;